// src/organisme/CreateUserInCentre.js
import React, { useState, useEffect } from 'react';
import { Box, Button, Input, Heading, Select, Text, Flex } from '@chakra-ui/react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, setDoc, doc, getDocs } from 'firebase/firestore'; // setDoc et doc pour définir un document avec un UID personnalisé
import { auth, db } from '../firebase'; // Importer Firebase Auth et Firestore

const CreateUserInCentre = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [centres, setCentres] = useState([]);
  const [poste, setPoste] = useState('');
  const [selectedCentre, setSelectedCentre] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchCentres = async () => {
      const centreCollection = await getDocs(collection(db, 'centres'));
      setCentres(centreCollection.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchCentres();
  }, []);

  const handleCreateUser = async () => {
    if (!email || !password || !selectedCentre || !poste) {
      setErrorMessage('Tous les champs sont obligatoires.');
      return;
    }

    try {
      // Créer un utilisateur avec Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user; // Obtenir l'utilisateur créé avec son UID

      // Enregistrer l'utilisateur dans Firestore avec le rôle 'centre', le poste renseigné et l'UID correct
      await setDoc(doc(db, 'users', user.uid), { // Utiliser setDoc avec l'UID comme identifiant du document
        uid: user.uid, // Utiliser l'UID généré par Firebase
        email: email,
        role: 'centre', // Attribuer automatiquement le rôle 'centre'
        centreId: selectedCentre,
        poste: poste, // Ajouter le poste renseigné
        createdAt: new Date(),
      });

      setSuccessMessage('Utilisateur créé avec succès !');
      setErrorMessage('');
      setEmail('');
      setPassword('');
      setSelectedCentre('');
      setPoste('');
    } catch (error) {
      setErrorMessage('Erreur lors de la création de l\'utilisateur : ' + error.message);
      setSuccessMessage('');
    }
  };

  return (
    <Flex direction="column" align="center" justify="center" minHeight="100vh" bg="gray.50">
      <Box p={8} bg="white" borderRadius="lg" shadow="lg" width="400px">
        <Heading as="h2" mb={6} textAlign="center" color="gray.700">
          Créer un utilisateur dans un centre
        </Heading>

        {errorMessage && (
          <Text color="red.500" mb={4} textAlign="center">
            {errorMessage}
          </Text>
        )}
        
        {successMessage && (
          <Text color="green.500" mb={4} textAlign="center">
            {successMessage}
          </Text>
        )}

        <Input
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          mb={4}
          focusBorderColor="blue.500"
        />
        <Input
          placeholder="Mot de passe"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          mb={4}
          focusBorderColor="blue.500"
        />

        <Select
          placeholder="Sélectionner un centre"
          value={selectedCentre}
          onChange={(e) => setSelectedCentre(e.target.value)}
          mb={4}
        >
          {centres.map(centre => (
            <option key={centre.id} value={centre.id}>
              {centre.centreName}
            </option>
          ))}
        </Select>

        <Input
          placeholder="Renseigner le poste"
          value={poste}
          onChange={(e) => setPoste(e.target.value)}
          mb={4}
          focusBorderColor="blue.500"
        />

        <Button onClick={handleCreateUser} colorScheme="blue" size="lg" width="100%">
          Créer l'utilisateur
        </Button>
      </Box>
    </Flex>
  );
};

export default CreateUserInCentre;
