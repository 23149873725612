// src/OrganismeSignup.js
import React, { useState } from 'react';
import { Button, Input, Box, Heading, Text, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from './firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';

const OrganismeSignup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [organismeName, setOrganismeName] = useState('');
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSignup = async () => {
    if (!email || !password || !organismeName) {
      setErrorMessage('Tous les champs sont obligatoires.');
      return;
    }

    if (!validateEmail(email)) {
      setErrorMessage('Veuillez entrer une adresse email valide.');
      return;
    }

    if (password.length < 6) {
      setErrorMessage('Le mot de passe doit contenir au moins 6 caractères.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        role: 'organisme',
        createdAt: new Date(),
      });

      await setDoc(doc(db, 'organismes', user.uid), { // Utilisation de user.uid comme ID
        name: organismeName,
        email: user.email,
        createdBy: user.uid,
        createdAt: new Date(),
      });

      setIsAccountCreated(true);
      setErrorMessage('');
    } catch (error) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          setErrorMessage('Cet email est déjà utilisé.');
          break;
        case 'auth/invalid-email':
          setErrorMessage('Adresse email invalide.');
          break;
        case 'auth/weak-password':
          setErrorMessage('Le mot de passe est trop faible.');
          break;
        default:
          setErrorMessage('Erreur lors de la création du compte : ' + error.message);
      }
    }
  };

  return (
    <Flex direction="column" align="center" justify="center" height="100vh" bgGradient="linear(to-r, gray.800, gray.600)">
      <Box p={8} bg="white" borderRadius="lg" shadow="lg" width="400px">
        <Heading as="h2" mb={6} textAlign="center" color="gray.700">
          Créer un compte Organisme
        </Heading>

        {errorMessage && (
          <Text color="red.500" mb={4} textAlign="center">
            {errorMessage}
          </Text>
        )}

        {isAccountCreated ? (
          <>
            <Text fontSize="lg" color="green.500" mb={4} textAlign="center">
              Compte créé avec succès !
            </Text>
            <Button onClick={() => navigate('/auth-organisme')} colorScheme="blue" size="lg" width="100%">
              Continuer vers la connexion
            </Button>
          </>
        ) : (
          <>
            <Input
              placeholder="Nom de l'organisme"
              value={organismeName}
              onChange={(e) => setOrganismeName(e.target.value)}
              mb={4}
              focusBorderColor="blue.500"
            />
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              mb={4}
              focusBorderColor="blue.500"
            />
            <Input
              placeholder="Mot de passe"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              mb={4}
              focusBorderColor="blue.500"
            />
            <Button onClick={handleSignup} colorScheme="blue" size="lg" width="100%">
              Créer un compte
            </Button>
          </>
        )}
      </Box>
    </Flex>
  );
};

export default OrganismeSignup;
