import React, { useState, useEffect } from 'react';
import { Box, Heading, Button, Input, VStack, Text, Flex } from '@chakra-ui/react';
import { collection, getDocs, deleteDoc, doc, updateDoc, query, where, setDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

const ManageCentres = () => {
  const [centres, setCentres] = useState([]);
  const [centreName, setCentreName] = useState('');
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [region, setRegion] = useState('');
  const [department, setDepartment] = useState('');
  const [city, setCity] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [currentCentreId, setCurrentCentreId] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [organismeId, setOrganismeId] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setOrganismeId(user.uid);
        fetchCentres(user.uid);
      }
    });
    return unsubscribe;
  }, []);

  const fetchCentres = async (organismeId) => {
    try {
      const centresQuery = query(collection(db, 'centres'), where('createdBy', '==', organismeId));
      const centresCollection = await getDocs(centresQuery);
      setCentres(centresCollection.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      setErrorMessage('Erreur lors du chargement des centres : ' + error.message);
    }
  };

  const handleAddCentre = async () => {
    if (!centreName || !address || !postalCode || !region || !department || !city || !phoneNumber || !email) {
      setErrorMessage('Tous les champs sont obligatoires.');
      return;
    }

    try {
      if (currentCentreId) {
        // Mise à jour du centre existant
        await updateDoc(doc(db, 'centres', currentCentreId), {
          centreName,
          address,
          postalCode,
          region,
          department,
          city,
          phoneNumber,
          email,
          updatedAt: new Date(),
        });
        setSuccessMessage('Centre mis à jour avec succès !');
      } else {
        // Ajout d'un nouveau centre
        const docName = `${organismeId}_${centreName.replace(/\s+/g, '_')}`;
        await setDoc(doc(db, 'centres', docName), {
          centreName,
          address,
          postalCode,
          region,
          department,
          city,
          phoneNumber,
          email,
          createdBy: organismeId,
          createdAt: new Date(),
        });
        setSuccessMessage('Centre ajouté avec succès !');
      }

      // Réinitialiser le formulaire et les messages
      setCentreName('');
      setAddress('');
      setPostalCode('');
      setRegion('');
      setDepartment('');
      setCity('');
      setPhoneNumber('');
      setEmail('');
      setCurrentCentreId(null);

      // Recharger les centres
      fetchCentres(organismeId);
    } catch (error) {
      setErrorMessage('Erreur lors de l’opération : ' + error.message);
    }
  };

  const handleDeleteCentre = async (id) => {
    try {
      await deleteDoc(doc(db, 'centres', id));
      setSuccessMessage('Centre supprimé avec succès !');
      // Recharger les centres
      fetchCentres(organismeId);
    } catch (error) {
      setErrorMessage('Erreur lors de la suppression du centre : ' + error.message);
    }
  };

  const handleEditCentre = (centre) => {
    setCurrentCentreId(centre.id);
    setCentreName(centre.centreName);
    setAddress(centre.address);
    setPostalCode(centre.postalCode);
    setRegion(centre.region);
    setDepartment(centre.department);
    setCity(centre.city);
    setPhoneNumber(centre.phoneNumber);
    setEmail(centre.email);
  };

  return (
    <Flex direction="column" align="center" justify="center" padding={4} bgGradient="linear(to-r, gray.100, gray.300)">
      <Box
        p={8}
        bg="white"
        borderRadius="lg"
        shadow="lg"
        width={['90%', '80%', '60%']}
      >
        <Heading as="h2" mb={6} textAlign="center" color="gray.700">
          Gestion des Centres
        </Heading>

        {errorMessage && (
          <Text color="red.500" mb={4} textAlign="center">
            {errorMessage}
          </Text>
        )}

        {successMessage && (
          <Text color="green.500" mb={4} textAlign="center">
            {successMessage}
          </Text>
        )}

        <VStack spacing={4} align="stretch">
          <Input
            placeholder="Nom du centre"
            value={centreName}
            onChange={(e) => setCentreName(e.target.value)}
            focusBorderColor="blue.500"
          />
          <Input
            placeholder="Adresse"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            focusBorderColor="blue.500"
          />
          <Input
            placeholder="Code Postal"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            focusBorderColor="blue.500"
          />
          <Input
            placeholder="Région"
            value={region}
            onChange={(e) => setRegion(e.target.value)}
            focusBorderColor="blue.500"
          />
          <Input
            placeholder="Département"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            focusBorderColor="blue.500"
          />
          <Input
            placeholder="Ville"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            focusBorderColor="blue.500"
          />
          <Input
            placeholder="Numéro de téléphone"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            focusBorderColor="blue.500"
          />
          <Input
            placeholder="Adresse e-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            focusBorderColor="blue.500"
          />
          <Button colorScheme="purple" onClick={handleAddCentre}>
            {currentCentreId ? 'Mettre à jour le Centre' : 'Ajouter le Centre'}
          </Button>
        </VStack>

        <Box mt={8}>
          <Heading as="h3" size="md" mb={4}>
            Liste des Centres
          </Heading>
          {centres.length > 0 ? (
            <VStack spacing={4} align="stretch">
              {centres.map(centre => (
                <Flex key={centre.id} justify="space-between" align="center" p={4} bg="gray.50" borderRadius="md">
                  <Text>{centre.centreName}</Text>
                  <Flex>
                    <Button colorScheme="blue" size="sm" mr={2} onClick={() => handleEditCentre(centre)}>
                      Modifier
                    </Button>
                    <Button colorScheme="red" size="sm" onClick={() => handleDeleteCentre(centre.id)}>
                      Supprimer
                    </Button>
                  </Flex>
                </Flex>
              ))}
            </VStack>
          ) : (
            <Text>Aucun centre trouvé.</Text>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default ManageCentres;