import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Heading,
  Flex,
  Icon,
  Button,
  VStack,
  HStack,
  Input,
  FormControl,
  FormLabel,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useToast,
  Spinner,
  Divider,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { FiAlertTriangle, FiDownload } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { collection, getDocs, addDoc, query } from 'firebase/firestore';
import { db } from '../firebase';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';

const Avertissements = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();
  const bg = useColorModeValue('gray.50', 'gray.800');
  const sidebarBg = useColorModeValue('white', 'gray.900');
  const sidebarColor = useColorModeValue('black', 'white');
  const eleveBg = useColorModeValue('white', 'gray.700');

  const [elevesParClasse, setElevesParClasse] = useState({});
  const [filteredElevesParClasse, setFilteredElevesParClasse] = useState({});
  const [classes, setClasses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedEleve, setSelectedEleve] = useState(null);
  const [warningMessage, setWarningMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fonction pour formater la date en JJ/MM/AAAA
  const formatDate = (date) => {
    if (!date) return 'N/A';
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Fetch les élèves depuis Firestore et les regrouper par classe
  const fetchEleves = useCallback(async () => {
    setIsLoading(true);
    try {
      const q = query(collection(db, 'eleves'));
      const querySnapshot = await getDocs(q);
      const fetchedEleves = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Grouper les élèves par classeId
      const groupedEleves = {};
      fetchedEleves.forEach((eleve) => {
        const classeId = eleve.classeId || 'sansClasse';
        if (!groupedEleves[classeId]) {
          groupedEleves[classeId] = [];
        }
        groupedEleves[classeId].push(eleve);
      });

      setElevesParClasse(groupedEleves);
      setFilteredElevesParClasse(groupedEleves);
      setIsLoading(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des élèves :', error);
      toast({
        title: 'Erreur',
        description: "Impossible de récupérer les élèves.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  }, [toast]);

  // Fetch les classes depuis Firestore
  const fetchClasses = useCallback(async () => {
    try {
      const classesQuery = query(collection(db, 'classes'));
      const classesSnapshot = await getDocs(classesQuery);
      const fetchedClasses = classesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClasses(fetchedClasses);
    } catch (error) {
      console.error('Erreur lors de la récupération des classes :', error);
      toast({
        title: 'Erreur',
        description: "Impossible de récupérer les classes.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchEleves();
    fetchClasses();
  }, [fetchEleves, fetchClasses]);

  // Gestion de la recherche
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    // Filtrer les élèves dans chaque classe
    const newElevesParClasse = {};

    Object.keys(elevesParClasse).forEach((classeId) => {
      const eleves = elevesParClasse[classeId].filter((eleve) =>
        `${eleve.lastName} ${eleve.firstName}`.toLowerCase().includes(query)
      );
      if (eleves.length > 0) {
        newElevesParClasse[classeId] = eleves;
      }
    });

    setFilteredElevesParClasse(newElevesParClasse);
  };

  // Fonction pour ouvrir le modal
  const openModal = (eleve) => {
    setSelectedEleve(eleve);
    setWarningMessage('');
    setIsModalOpen(true);
  };

  // Fonction pour fermer le modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEleve(null);
    setWarningMessage('');
  };

  // Fonction pour créer un avertissement
  const handleCreateWarning = async () => {
    if (!warningMessage.trim()) {
      toast({
        title: 'Erreur',
        description: "Le message de l'avertissement ne peut pas être vide.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsGenerating(true);

    try {
      // 1. Sauvegarder l'avertissement dans Firestore
      const warningData = {
        eleveId: selectedEleve.id,
        message: warningMessage,
        createdAt: new Date(),
      };
      await addDoc(collection(db, 'warnings'), warningData);

      // 2. Charger le modèle de document Word depuis public/Avertissement.docx
      const response = await fetch('/Avertissement.docx');
      if (!response.ok) {
        throw new Error('Impossible de charger le modèle de document.');
      }
      const arrayBuffer = await response.arrayBuffer();
      const zip = new PizZip(arrayBuffer);

      let doc;
      try {
        doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          delimiters: { start: '{', end: '}' },
        });
      } catch (error) {
        console.error(
          "Erreur lors de l'initialisation de Docxtemplater :",
          error
        );
        throw new Error("Erreur lors de l'initialisation du modèle de document.");
      }

      // 3. Définir les données pour remplacer les placeholders
      doc.setData({
        firstName: selectedEleve.firstName,
        lastName: selectedEleve.lastName,
        createdAt: formatDate(warningData.createdAt),
        message: warningData.message,
      });

      try {
        // 4. Rendre le document avec les données
        doc.render();
      } catch (error) {
        // Gestion détaillée des erreurs de template
        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map((err) => err.properties.explanation)
            .join('\n');
          console.error('Erreurs de template Docxtemplater :', errorMessages);
          toast({
            title: 'Erreur de Template',
            description: `Problème avec les placeholders du document :\n${errorMessages}`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        } else {
          console.error('Erreur lors du rendu du document :', error);
          toast({
            title: 'Erreur',
            description: "Impossible de générer le document Word.",
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
        setIsGenerating(false);
        return;
      }

      const generatedDoc = doc.getZip().generate({
        type: 'blob',
        mimeType:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      // 5. Télécharger le document généré
      saveAs(
        generatedDoc,
        `Avertissement_${selectedEleve.lastName}_${selectedEleve.firstName}.docx`
      );

      toast({
        title: 'Succès',
        description: "Avertissement créé et document Word généré avec succès.",
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      // Fermer le modal
      closeModal();
    } catch (error) {
      console.error("Erreur lors de la création de l'avertissement :", error);
      toast({
        title: 'Erreur',
        description: error.message || "Impossible de créer l'avertissement.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Flex minHeight="100vh" bg={bg}>
      {/* Barre latérale */}
      <Box
        width={['100%', '250px']}
        bg={sidebarBg}
        color={sidebarColor}
        p={6}
        boxShadow="md"
      >
        <Heading as="h3" size="md" mb={6} textAlign="center" color="blue.500">
          Gestion des Avertissements
        </Heading>
        <VStack spacing={4} align="stretch">
          <Button
            variant="ghost"
            leftIcon={<FiDownload />}
            onClick={() => navigate('/centre/avertissements')}
          >
            Avertissements
          </Button>
          {/* Ajoutez d'autres boutons de navigation si nécessaire */}
        </VStack>
        <Divider my={6} />
        <Button variant="ghost" onClick={toggleColorMode} width="100%">
          {colorMode === 'light' ? '🌙 Mode Sombre' : '☀️ Mode Clair'}
        </Button>
      </Box>

      {/* Contenu principal */}
      <Box flex="1" p={6}>
        <Heading as="h2" size="lg" mb={6} textAlign="center" color="blue.500">
          Créer un Avertissement
        </Heading>

        {/* Barre de recherche */}
        <Flex mb={4} align="center" justify="space-between" flexWrap="wrap">
          <HStack spacing={2}>
            <Input
              placeholder="Rechercher un élève..."
              value={searchQuery}
              onChange={handleSearch}
              maxW="300px"
              variant="filled"
            />
            {/* Vous pouvez ajouter un bouton de recherche si nécessaire */}
          </HStack>
        </Flex>

        {/* Liste des élèves par classes */}
        {isLoading ? (
          <Flex justifyContent="center" alignItems="center" height="50vh">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <VStack spacing={4} align="stretch">
            {classes.map(
              (classe) =>
                filteredElevesParClasse[classe.id] && (
                  <Box key={classe.id} bg={eleveBg} p={4} borderRadius="md">
                    <Heading as="h3" size="md" mb={4}>
                      {classe.className}
                    </Heading>
                    {filteredElevesParClasse[classe.id].map((eleve) => (
                      <Box
                        key={eleve.id}
                        p={4}
                        bg={eleveBg}
                        borderRadius="md"
                        boxShadow="sm"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mt={2}
                      >
                        <HStack spacing={4}>
                          <Icon
                            as={FiAlertTriangle}
                            w={6}
                            h={6}
                            color="yellow.500"
                          />
                          <VStack align="start" spacing={1}>
                            <Text fontWeight="bold">
                              {`${eleve.firstName} ${eleve.lastName}`}
                            </Text>
                            <Text fontSize="sm" color="gray.500">
                              {eleve.email}
                            </Text>
                          </VStack>
                        </HStack>
                        <Button
                          colorScheme="blue"
                          size="sm"
                          onClick={() => openModal(eleve)}
                        >
                          Créer Avertissement
                        </Button>
                      </Box>
                    ))}
                  </Box>
                )
            )}
            {/* Afficher les élèves sans classe */}
            {filteredElevesParClasse['sansClasse'] && (
              <Box bg={eleveBg} p={4} borderRadius="md">
                <Heading as="h3" size="md" mb={4}>
                  Élèves sans classe
                </Heading>
                {filteredElevesParClasse['sansClasse'].map((eleve) => (
                  <Box
                    key={eleve.id}
                    p={4}
                    bg={eleveBg}
                    borderRadius="md"
                    boxShadow="sm"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={2}
                  >
                    <HStack spacing={4}>
                      <Icon
                        as={FiAlertTriangle}
                        w={6}
                        h={6}
                        color="yellow.500"
                      />
                      <VStack align="start" spacing={1}>
                        <Text fontWeight="bold">
                          {`${eleve.firstName} ${eleve.lastName}`}
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                          {eleve.email}
                        </Text>
                      </VStack>
                    </HStack>
                    <Button
                      colorScheme="blue"
                      size="sm"
                      onClick={() => openModal(eleve)}
                    >
                      Créer Avertissement
                    </Button>
                  </Box>
                ))}
              </Box>
            )}
          </VStack>
        )}

        {/* Modal pour créer un avertissement */}
        <Modal isOpen={isModalOpen} onClose={closeModal} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Créer un Avertissement</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {selectedEleve && (
                <VStack spacing={4} align="stretch">
                  <Text>
                    <strong>Élève :</strong> {selectedEleve.firstName}{' '}
                    {selectedEleve.lastName}
                  </Text>
                  <FormControl id="message" isRequired>
                    <FormLabel>Message de l'Avertissement</FormLabel>
                    <Textarea
                      placeholder="Entrez le message de l'avertissement..."
                      value={warningMessage}
                      onChange={(e) => setWarningMessage(e.target.value)}
                    />
                  </FormControl>
                </VStack>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={handleCreateWarning}
                isLoading={isGenerating}
              >
                Créer et Télécharger
              </Button>
              <Button variant="ghost" onClick={closeModal}>
                Annuler
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Flex>
  );
};

export default Avertissements;
