// src/centre/Informations.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  Image,
  Button,
  Input,
  VStack,
  Flex,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const Informations = () => {
  const auth = getAuth();
  const toast = useToast();
  const [centre, setCentre] = useState(null);
  const [loading, setLoading] = useState(true);
  const [logoFile, setLogoFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const fetchCentreInfo = async () => {
      const user = auth.currentUser;
      if (!user) {
        toast({
          title: 'Non authentifié',
          description: 'Veuillez vous connecter.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const centreId = userData.centreId;

          if (centreId) {
            const centreDoc = await getDoc(doc(db, 'centres', centreId));
            if (centreDoc.exists()) {
              setCentre({ id: centreDoc.id, ...centreDoc.data() });
            } else {
              toast({
                title: 'Centre non trouvé.',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }
          } else {
            toast({
              title: 'Aucun centre rattaché.',
              status: 'warning',
              duration: 5000,
              isClosable: true,
            });
          }
        } else {
          toast({
            title: 'Utilisateur non trouvé.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: 'Erreur',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCentreInfo();
  }, [auth, toast]);

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Optionnel : Ajouter des validations ici
      setLogoFile(file);
    }
  };

  const handleUploadLogo = async () => {
    if (!logoFile) {
      toast({
        title: 'Aucun fichier sélectionné.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setUploading(true);

    try {
      const fileExtension = logoFile.name.split('.').pop();
      const logoRef = ref(storage, `centres/${centre.id}/logo.${fileExtension}`);
      await uploadBytes(logoRef, logoFile);
      const logoURL = await getDownloadURL(logoRef);

      // Mettre à jour Firestore avec l'URL du logo
      await updateDoc(doc(db, 'centres', centre.id), {
        logoURL,
        updatedAt: new Date(),
      });

      setCentre((prev) => ({ ...prev, logoURL }));
      toast({
        title: 'Logo mis à jour.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setLogoFile(null);
    } catch (error) {
      toast({
        title: 'Erreur lors du téléchargement.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setUploading(false);
    }
  };

  if (loading) {
    return (
      <Flex align="center" justify="center" height="80vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (!centre) {
    return (
      <Box p={8}>
        <Text>Aucune information disponible pour ce centre.</Text>
      </Box>
    );
  }

  return (
    <Flex direction="column" align="center" justify="center" padding={8}>
      <Box
        p={8}
        bg="white"
        borderRadius="lg"
        shadow="lg"
        width={['90%', '80%', '60%']}
      >
        <Heading as="h2" mb={6} textAlign="center">
          Informations du Centre
        </Heading>

        <VStack spacing={4} align="stretch">
          {centre.logoURL && (
            <Image
              src={centre.logoURL}
              alt="Logo du Centre"
              boxSize="150px"
              objectFit="contain"
              mx="auto"
            />
          )}
          <Button onClick={() => document.getElementById('logoUpload').click()} colorScheme="blue">
            {centre.logoURL ? 'Modifier le Logo' : 'Ajouter un Logo'}
          </Button>
          <Input
            id="logoUpload"
            type="file"
            accept="image/*"
            display="none"
            onChange={handleLogoChange}
          />
          {logoFile && (
            <Button
              onClick={handleUploadLogo}
              colorScheme="green"
              isLoading={uploading}
            >
              Télécharger le Logo
            </Button>
          )}
          <Box mt={4}>
            <Text><strong>Nom du Centre :</strong> {centre.centreName}</Text>
            <Text><strong>Adresse :</strong> {centre.address}</Text>
            <Text><strong>Code Postal :</strong> {centre.postalCode}</Text>
            <Text><strong>Région :</strong> {centre.region}</Text>
            <Text><strong>Département :</strong> {centre.department}</Text>
            <Text><strong>Ville :</strong> {centre.city}</Text>
            <Text><strong>Numéro de Téléphone :</strong> {centre.phoneNumber}</Text>
            <Text><strong>Email :</strong> {centre.email}</Text>
          </Box>
        </VStack>
      </Box>
    </Flex>
  );
};

export default Informations;
