import React from 'react';
import { Box, Heading, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

const ManageInternOrder = () => {
  return (
    <Box p={8}>
      <Heading as="h1" size="xl" mb={6} textAlign="center">
        Gestion des Demandes Internes
      </Heading>
      
      <Tabs variant="enclosed">
        <TabList>
          <Tab>Demande de fournitures</Tab>
          <Tab>Demande d'impression</Tab>
        </TabList>

        <TabPanels>
          {/* Onglet Demande de fournitures */}
          <TabPanel>
            <Heading as="h2" size="lg" mb={4}>Demande de fournitures</Heading>
            {/* Insère ici la logique pour recevoir et gérer les demandes de fournitures */}
          </TabPanel>

          {/* Onglet Demande d'impression */}
          <TabPanel>
            <Heading as="h2" size="lg" mb={4}>Demande d'impression</Heading>
            {/* Insère ici la logique pour recevoir et gérer les demandes d'impression */}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default ManageInternOrder;
