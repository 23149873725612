import React from 'react';
import {
  Box,
  Button,
  Heading,
  Text,
  VStack,
  Icon,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaUserTie, FaSchool, FaClipboardList, FaPlus } from 'react-icons/fa'; // Ajout des icônes nécessaires

const HomePage = () => {
  const navigate = useNavigate();

  const handleOrganismeClick = () => {
    navigate('/auth-organisme');
  };

  const handleCentreClick = () => {
    navigate('/auth-centre');
  };

  const handleProjectClick = () => {
    navigate('/projet-formation'); // Naviguer vers la page "En projet de formation"
  };

  const handleCreateOrganismeClick = () => {
    navigate('/organisme-signup'); // Naviguer vers la page de création de compte organisme
  };

  return (
    <Box
      position="relative"
      minHeight="100vh"
      overflow="hidden"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <video
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          minWidth: '100%',
          minHeight: '100%',
          width: 'auto',
          height: 'auto',
          zIndex: -1,
          transform: 'translate(-50%, -50%)',
        }}
      >
        <source src="/classe.mp4" type="video/mp4" />
        Votre navigateur ne supporte pas la balise vidéo.
      </video>
      <Box
        bg={useColorModeValue('whiteAlpha.800', 'blackAlpha.700')}
        p={8}
        rounded="lg"
        shadow="lg"
        maxW="lg"
        textAlign="center"
      >
        <Heading as="h1" size="xl" mb={4}>
          Bienvenue sur Trainotech
        </Heading>
        <Text fontSize="lg" mb={8}>
          Veuillez choisir votre rôle pour continuer
        </Text>
        <Stack direction={['column', 'row']} spacing={6} justify="center">
          <VStack>
            <Icon as={FaUserTie} w={12} h={12} color="blue.500" />
            <Button
              onClick={handleOrganismeClick}
              colorScheme="blue"
              variant="solid"
              size="lg"
              fontSize="lg"
            >
              Connexion Organisme
            </Button>
          </VStack>
          <VStack>
            <Icon as={FaSchool} w={12} h={12} color="purple.500" />
            <Button
              onClick={handleCentreClick}
              colorScheme="purple"
              variant="solid"
              size="lg"
              fontSize="lg"
            >
              Connexion Centre
            </Button>
          </VStack>
          <VStack>
            <Icon as={FaClipboardList} w={12} h={12} color="orange.500" /> {/* Icône pour le projet de formation */}
            <Button
              onClick={handleProjectClick}
              colorScheme="orange"
              variant="solid"
              size="lg"
              fontSize="lg"
              isDisabled // Désactivation du bouton "En projet de formation"
            >
              En projet de formation
            </Button>
          </VStack>
          <VStack>
            <Icon as={FaPlus} w={12} h={12} color="teal.500" /> {/* Icône pour créer un compte organisme */}
            <Button
              onClick={handleCreateOrganismeClick}
              colorScheme="teal"
              variant="solid"
              size="lg"
              fontSize="lg"
              isDisabled // Désactivation du bouton "Créer un compte Organisme"
            >
              Créer un compte Organisme
            </Button>
          </VStack>
        </Stack>
      </Box>
    </Box>
  );
};

export default HomePage;
