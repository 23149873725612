// src/centre/TrackOrders.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Image,
  Text,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import { FiTrash } from 'react-icons/fi';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  deleteDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  getDoc, // Ajouté getDoc
} from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../AuthContext'; // Assure-toi que le chemin est correct

const TrackOrders = () => {
  const { centreId: paramCentreId } = useParams(); // Récupère le centreId depuis l'URL
  const navigate = useNavigate(); // Utiliser navigate pour la redirection
  const toast = useToast();
  const { currentUser, centreId: userCentreId } = useAuth(); // Utilise le contexte d'authentification

  const effectiveCentreId = paramCentreId || userCentreId;

  const [orders, setOrders] = useState([]);

  // États pour les sessions de commandes
  const [sessions, setSessions] = useState([]);
  const [sessionName, setSessionName] = useState('');
  const [sessionStartDate, setSessionStartDate] = useState('');
  const [sessionEndDate, setSessionEndDate] = useState('');

  // États pour les produits
  const [products, setProducts] = useState([]);
  const [productName, setProductName] = useState('');
  const [productDetails, setProductDetails] = useState('');
  const [productPhoto, setProductPhoto] = useState('');

  // États pour les e-mails autorisés
  const [authorizedEmail, setAuthorizedEmail] = useState('');
  const [authorizedEmails, setAuthorizedEmails] = useState([]);

  useEffect(() => {
    if (effectiveCentreId) {
      fetchOrders(effectiveCentreId);
      fetchSessions(effectiveCentreId);
      fetchProducts(effectiveCentreId);
      fetchAuthorizedEmails(effectiveCentreId);
    }
  }, [effectiveCentreId]);

  // Fonction pour récupérer les commandes du centre
  const fetchOrders = async (id) => {
    try {
      const ordersQuery = query(
        collection(db, 'orders'),
        where('centreId', '==', id)
      );
      const querySnapshot = await getDocs(ordersQuery);
      const ordersList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOrders(ordersList);
    } catch (error) {
      console.error('Erreur lors de la récupération des commandes :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer les commandes pour ce centre.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction pour récupérer les sessions de commandes
  const fetchSessions = async (id) => {
    try {
      const sessionsQuery = query(
        collection(db, 'sessions'),
        where('centreId', '==', id)
      );
      const querySnapshot = await getDocs(sessionsQuery);
      const sessionsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSessions(sessionsList);
    } catch (error) {
      console.error('Erreur lors de la récupération des sessions :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer les sessions de commandes.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction pour récupérer les produits du centre
  const fetchProducts = async (id) => {
    try {
      const productsQuery = query(
        collection(db, 'products'),
        where('centreId', '==', id)
      );
      const querySnapshot = await getDocs(productsQuery);
      const productsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsList);
    } catch (error) {
      console.error('Erreur lors de la récupération des produits :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer les produits pour ce centre.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction pour récupérer les e-mails autorisés
  const fetchAuthorizedEmails = async (id) => {
    try {
      const centreDoc = await getDoc(doc(db, 'centres', id));
      if (centreDoc.exists()) {
        setAuthorizedEmails(centreDoc.data().authorizedEmails || []);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des e-mails autorisés :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer les e-mails autorisés.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction pour ajouter une session de commandes
  const handleAddSession = async () => {
    if (!sessionName || !sessionStartDate || !sessionEndDate) {
      toast({
        title: 'Erreur',
        description: 'Veuillez remplir tous les champs de la session.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (new Date(sessionStartDate) > new Date(sessionEndDate)) {
      toast({
        title: 'Erreur',
        description: 'La date de début doit être antérieure à la date de fin.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const newSession = {
        centreId: effectiveCentreId,
        name: sessionName,
        startDate: new Date(sessionStartDate),
        endDate: new Date(sessionEndDate),
      };

      const docRef = await addDoc(collection(db, 'sessions'), newSession);
      setSessions([...sessions, { id: docRef.id, ...newSession }]);
      setSessionName('');
      setSessionStartDate('');
      setSessionEndDate('');

      toast({
        title: 'Session ajoutée',
        description: 'La session de commandes a été ajoutée avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la session :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible d\'ajouter la session de commandes.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction pour supprimer une session de commandes
  const handleDeleteSession = async (sessionId) => {
    try {
      await deleteDoc(doc(db, 'sessions', sessionId));
      setSessions(sessions.filter((session) => session.id !== sessionId));
      toast({
        title: 'Session supprimée',
        description: 'La session de commandes a été supprimée avec succès.',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression de la session :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer la session de commandes.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction pour ajouter un produit
  const handleAddProduct = async () => {
    if (!productName || !productDetails || !productPhoto) {
      toast({
        title: 'Erreur',
        description: 'Veuillez remplir tous les champs du produit.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const newProduct = {
        centreId: effectiveCentreId,
        name: productName,
        details: productDetails,
        photo: productPhoto,
        createdAt: new Date(),
      };

      const docRef = await addDoc(collection(db, 'products'), newProduct);
      setProducts([...products, { id: docRef.id, ...newProduct }]);
      setProductName('');
      setProductDetails('');
      setProductPhoto('');

      toast({
        title: 'Produit ajouté',
        description: 'Le produit a été ajouté avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de l\'ajout du produit :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible d\'ajouter le produit.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction pour supprimer un produit
  const handleDeleteProduct = async (productId) => {
    try {
      await deleteDoc(doc(db, 'products', productId));
      setProducts(products.filter((product) => product.id !== productId));
      toast({
        title: 'Produit supprimé',
        description: 'Le produit a été supprimé avec succès.',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression du produit :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer le produit.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction pour ajouter une e-mail autorisé
  const handleAddAuthorizedEmail = async () => {
    if (!authorizedEmail) {
      toast({
        title: 'Erreur',
        description: 'Veuillez entrer une adresse e-mail.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const centreRef = doc(db, 'centres', effectiveCentreId);
      await updateDoc(centreRef, {
        authorizedEmails: arrayUnion(authorizedEmail),
      });
      setAuthorizedEmails([...authorizedEmails, authorizedEmail]);
      setAuthorizedEmail('');

      toast({
        title: 'E-mail ajouté',
        description: 'L\'adresse e-mail a été ajoutée avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de l\'ajout de l\'e-mail autorisé :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible d\'ajouter l\'adresse e-mail.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction pour supprimer une e-mail autorisé
  const handleDeleteAuthorizedEmail = async (email) => {
    try {
      const centreRef = doc(db, 'centres', effectiveCentreId);
      await updateDoc(centreRef, {
        authorizedEmails: arrayRemove(email),
      });
      setAuthorizedEmails(authorizedEmails.filter((e) => e !== email));

      toast({
        title: 'E-mail supprimé',
        description: 'L\'adresse e-mail a été supprimée avec succès.',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'e-mail autorisé :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer l\'adresse e-mail.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction pour naviguer vers le catalogue public
  const handleViewCatalog = () => {
    navigate(`/internOrder/${effectiveCentreId}`);
  };

  return (
    <Box p={8}>
      <Heading mb={6}>Commandes pour le Centre: {effectiveCentreId}</Heading>

      {/* Bouton pour aller au catalogue public */}
      <Button mb={4} colorScheme="blue" onClick={handleViewCatalog}>
        Aller au catalogue du centre
      </Button>

      {/* Section Sessions de Commandes */}
      <Box mb={8} p={6} borderWidth="1px" borderRadius="lg">
        <Heading size="md" mb={4}>Définir une Session de Commandes</Heading>
        <Flex direction="column" gap={4}>
          <FormControl id="sessionName" isRequired>
            <FormLabel>Nom de la Session</FormLabel>
            <Input
              type="text"
              value={sessionName}
              onChange={(e) => setSessionName(e.target.value)}
              placeholder="Ex : Session Printemps 2024"
            />
          </FormControl>

          <FormControl id="sessionStartDate" isRequired>
            <FormLabel>Date de Début</FormLabel>
            <Input
              type="date"
              value={sessionStartDate}
              onChange={(e) => setSessionStartDate(e.target.value)}
            />
          </FormControl>

          <FormControl id="sessionEndDate" isRequired>
            <FormLabel>Date de Fin</FormLabel>
            <Input
              type="date"
              value={sessionEndDate}
              onChange={(e) => setSessionEndDate(e.target.value)}
            />
          </FormControl>

          <Button colorScheme="green" onClick={handleAddSession}>
            Ajouter la Session
          </Button>
        </Flex>
      </Box>

      {/* Liste des Sessions de Commandes */}
      <Box mb={8}>
        <Heading size="md" mb={4}>Sessions de Commandes</Heading>
        {sessions.length > 0 ? (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Nom</Th>
                <Th>Date de Début</Th>
                <Th>Date de Fin</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {sessions.map((session) => (
                <Tr key={session.id}>
                  <Td>{session.name}</Td>
                  <Td>{new Date(session.startDate.seconds * 1000).toLocaleDateString()}</Td>
                  <Td>{new Date(session.endDate.seconds * 1000).toLocaleDateString()}</Td>
                  <Td>
                    <IconButton
                      aria-label="Supprimer la session"
                      icon={<FiTrash />}
                      colorScheme="red"
                      onClick={() => handleDeleteSession(session.id)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Text>Aucune session de commandes définie pour ce centre.</Text>
        )}
      </Box>

      {/* Section Ajouter un Produit */}
      <Box mb={8} p={6} borderWidth="1px" borderRadius="lg">
        <Heading size="md" mb={4}>Ajouter un Produit</Heading>
        <Flex direction="column" gap={4}>
          <FormControl id="productName" isRequired>
            <FormLabel>Nom du Produit</FormLabel>
            <Input
              type="text"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              placeholder="Nom du produit"
            />
          </FormControl>

          <FormControl id="productDetails" isRequired>
            <FormLabel>Caractéristiques</FormLabel>
            <Input
              type="text"
              value={productDetails}
              onChange={(e) => setProductDetails(e.target.value)}
              placeholder="Détails du produit"
            />
          </FormControl>

          <FormControl id="productPhoto" isRequired>
            <FormLabel>Photo du Produit (URL)</FormLabel>
            <Input
              type="text"
              value={productPhoto}
              onChange={(e) => setProductPhoto(e.target.value)}
              placeholder="URL de la photo"
            />
          </FormControl>

          <Button colorScheme="green" onClick={handleAddProduct}>
            Ajouter le Produit
          </Button>
        </Flex>
      </Box>

      {/* Liste des Produits */}
      <Box mb={8}>
        <Heading size="md" mb={4}>Produits Ajoutés</Heading>
        {products.length > 0 ? (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Nom</Th>
                <Th>Caractéristiques</Th>
                <Th>Photo</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {products.map((product) => (
                <Tr key={product.id}>
                  <Td>{product.name}</Td>
                  <Td>{product.details}</Td>
                  <Td>
                    <Image src={product.photo} alt={product.name} boxSize="50px" objectFit="cover" />
                  </Td>
                  <Td>
                    <IconButton
                      aria-label="Supprimer le produit"
                      icon={<FiTrash />}
                      colorScheme="red"
                      onClick={() => handleDeleteProduct(product.id)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Text>Aucun produit ajouté pour ce centre.</Text>
        )}
      </Box>

      {/* Section Gérer les E-mails Autorisés */}
      <Box mb={8} p={6} borderWidth="1px" borderRadius="lg">
        <Heading size="md" mb={4}>Gérer les E-mails Autorisés</Heading>
        <Flex direction="column" gap={4}>
          <FormControl id="authorizedEmail">
            <FormLabel>Ajouter une Adresse E-mail</FormLabel>
            <Input
              type="email"
              value={authorizedEmail}
              onChange={(e) => setAuthorizedEmail(e.target.value)}
              placeholder="email@example.com"
            />
          </FormControl>
          <Button colorScheme="green" onClick={handleAddAuthorizedEmail}>
            Ajouter l'E-mail
          </Button>
        </Flex>
      </Box>

      {/* Liste des E-mails Autorisés */}
      <Box mb={8}>
        <Heading size="md" mb={4}>E-mails Autorisés</Heading>
        {authorizedEmails.length > 0 ? (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Adresse E-mail</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {authorizedEmails.map((email, index) => (
                <Tr key={index}>
                  <Td>{email}</Td>
                  <Td>
                    <IconButton
                      aria-label="Supprimer l'e-mail autorisé"
                      icon={<FiTrash />}
                      colorScheme="red"
                      onClick={() => handleDeleteAuthorizedEmail(email)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Text>Aucun e-mail autorisé pour ce centre.</Text>
        )}
      </Box>

      {/* Liste des Commandes */}
      <Box>
        <Heading size="md" mb={4}>Commandes en Cours</Heading>
        {orders.length > 0 ? (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>ID Commande</Th>
                <Th>Nom du Produit</Th>
                <Th>Quantité</Th>
                <Th>Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {orders.map((order) => (
                <Tr key={order.id}>
                  <Td>{order.id}</Td>
                  <Td>{order.productName}</Td>
                  <Td>{order.quantity}</Td>
                  <Td>{new Date(order.orderDate.seconds * 1000).toLocaleDateString()}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Text>Aucune commande trouvée pour ce centre.</Text>
        )}
      </Box>
    </Box>
  );
};

export default TrackOrders;
