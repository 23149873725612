// src/organisme/Financement.js
import React, { useState } from 'react';
import {
  Box,
  Heading,
  Input,
  Flex,
  useToast,
  Button,
  Select,
  Textarea,
  FormControl,
  FormLabel,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';

const Financement = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [limiteGeographique, setLimiteGeographique] = useState('France');
  const [delai, setDelai] = useState('');
  const [typeBeneficiaire, setTypeBeneficiaire] = useState('');
  const [prerequis, setPrerequis] = useState('');
  const [demarches, setDemarches] = useState('');

  const bg = useColorModeValue('gray.50', 'gray.800');
  const boxBg = useColorModeValue('white', 'gray.700');

  const calculateDeadline = (type, startDate) => {
    const date = new Date(startDate);
    switch (type) {
      case 'CPF':
        date.setDate(date.getDate() - 11);
        break;
      case 'France Travail':
        date.setDate(date.getDate() - 1);
        break;
      case 'AIRE 2 Ile De France':
        date.setDate(date.getDate() - (Math.random() * (12 - 6) + 6));
        break;
      default:
        break;
    }
    return date.toISOString().split('T')[0];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!delai || !typeBeneficiaire) {
      toast({
        title: 'Erreur de validation',
        description: "Veuillez remplir les champs obligatoires.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const calculatedDeadline = calculateDeadline(typeBeneficiaire, delai);

    try {
      await addDoc(collection(db, 'financements'), {
        limiteGeographique,
        delai: calculatedDeadline,
        typeBeneficiaire,
        prerequis,
        demarches,
        createdAt: new Date(),
      });

      toast({
        title: 'Financement ajouté',
        description: "Le financement a été ajouté avec succès.",
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      navigate('/');
    } catch (error) {
      console.error("Erreur lors de l'ajout du financement :", error);
      toast({
        title: 'Erreur',
        description: "Impossible d'ajouter le financement.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex justify="center" align="center" minHeight="100vh" bg={bg} padding={4}>
      <Box bg={boxBg} p={8} borderRadius="lg" boxShadow="lg" width={{ base: '90%', md: '500px' }}>
        <Heading as="h2" size="lg" mb={6} textAlign="center" color="teal.600">
          Ajouter un Financement
        </Heading>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl id="limiteGeographique">
              <FormLabel>Limite Géographique</FormLabel>
              <Select value={limiteGeographique} onChange={(e) => setLimiteGeographique(e.target.value)}>
                <option value="France">Toute la France</option>
                <option value="Région">Région spécifique</option>
                <option value="Département">Département spécifique</option>
                <option value="Autre">Autre</option>
              </Select>
            </FormControl>

            <FormControl id="delai" isRequired>
              <FormLabel>Date Limite</FormLabel>
              <Input type="date" value={delai} onChange={(e) => setDelai(e.target.value)} />
            </FormControl>

            <FormControl id="typeBeneficiaire" isRequired>
              <FormLabel>Type de Bénéficiaire</FormLabel>
              <Select placeholder="Sélectionnez un type" value={typeBeneficiaire} onChange={(e) => setTypeBeneficiaire(e.target.value)}>
                <option value="Étudiants">Étudiants</option>
                <option value="Professionnels">Professionnels</option>
                <option value="Demandeurs d'emploi">Demandeurs d'emploi</option>
                <option value="CPF">CPF</option>
                <option value="France Travail">France Travail</option>
                <option value="AIRE 2 Ile De France">AIRE 2 Ile De France</option>
                <option value="Autre">Autre</option>
              </Select>
            </FormControl>

            <FormControl id="prerequis">
              <FormLabel>Prérequis</FormLabel>
              <Textarea placeholder="Listez les prérequis..." value={prerequis} onChange={(e) => setPrerequis(e.target.value)} />
            </FormControl>

            <FormControl id="demarches">
              <FormLabel>Démarches à Effectuer</FormLabel>
              <Textarea placeholder="Décrivez les démarches à effectuer..." value={demarches} onChange={(e) => setDemarches(e.target.value)} />
            </FormControl>

            <Button colorScheme="teal" type="submit" width="full">
              Ajouter
            </Button>
            <Button variant="outline" colorScheme="red" width="full" onClick={() => navigate(-1)}>
              Annuler
            </Button>
          </VStack>
        </form>
      </Box>
    </Flex>
  );
};

export default Financement;
