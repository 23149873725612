import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Heading,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  FormControl,
  FormLabel,
  Flex,
  Select,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  Text,
} from '@chakra-ui/react';
import { ViewIcon, DeleteIcon, EditIcon, AddIcon } from '@chakra-ui/icons';
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  addDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  getDoc,
} from 'firebase/firestore';
import { db, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { parse, isValid, format } from 'date-fns';

const Eleves = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [eleves, setEleves] = useState([]);
  const [centres, setCentres] = useState([]);
  const [classes, setClasses] = useState([]);

  const [selectedEleve, setSelectedEleve] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    civilite: '',
    firstName: '',
    lastName: '',
    email: '',
    dateOfBirth: '',
    placeOfBirth: '',
    adresse: '',
    complementAdresse: '',
    codePostal: '',
    ville: '',
    centreId: '',
    classeId: '',
  });

  const [searchTerm, setSearchTerm] = useState('');

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  const fetchEleves = useCallback(async () => {
    try {
      if (!currentUser) return;

      const elevesCol = collection(db, 'eleves');
      const elevesSnapshot = await getDocs(elevesCol);
      const elevesList = elevesSnapshot.docs
        .map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            dateOfBirth: data.dateOfBirth ? data.dateOfBirth.toDate() : null,
          };
        })
        .filter((eleve) => eleve.organismeId === currentUser.uid);
      setEleves(elevesList);
    } catch (error) {
      console.error('Erreur lors de la récupération des élèves:', error);
      toast({
        title: 'Erreur',
        description: "Impossible de récupérer les élèves.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [currentUser, toast]);

  useEffect(() => {
    fetchEleves();
  }, [fetchEleves]);

  const formatDateSafe = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
      console.error('Date invalide:', date);
      return 'Date Invalide';
    }
    return format(date, 'dd/MM/yyyy');
  };

  const getClasseName = (classeId) => {
    const classe = classes.find((c) => c.id === classeId);
    return classe ? classe.className : 'Classe Inconnue';
  };

  const handleViewDetails = (eleve) => {
    setSelectedEleve(eleve);
    setIsEditing(false);
    onOpen();
  };

  const openModal = (eleve = null) => {
    setSelectedEleve(eleve);
    if (eleve) {
      setFormData({
        civilite: eleve.civilite || '',
        firstName: eleve.firstName || '',
        lastName: eleve.lastName || '',
        email: eleve.email || '',
        dateOfBirth: eleve.dateOfBirth ? formatDateSafe(eleve.dateOfBirth) : '',
        placeOfBirth: eleve.placeOfBirth || '',
        adresse: eleve.adresse || '',
        complementAdresse: eleve.complementAdresse || '',
        codePostal: eleve.codePostal || '',
        ville: eleve.ville || '',
        centreId: eleve.centreId || '',
        classeId: eleve.classeId || '',
      });
      setIsEditing(true);
    } else {
      setFormData({
        civilite: '',
        firstName: '',
        lastName: '',
        email: '',
        dateOfBirth: '',
        placeOfBirth: '',
        adresse: '',
        complementAdresse: '',
        codePostal: '',
        ville: '',
        centreId: '',
        classeId: '',
      });
      setIsEditing(false);
    }
    onOpen();
  };

  const handleDeleteEleve = async (eleveId) => {
    try {
      await deleteDoc(doc(db, 'eleves', eleveId));
      setEleves((prevEleves) => prevEleves.filter((e) => e.id !== eleveId));
      toast({
        title: 'Succès',
        description: 'Élève supprimé avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erreur lors de la suppression de l'élève:", error);
      toast({
        title: 'Erreur',
        description: "Impossible de supprimer l'élève.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const filteredEleves = eleves.filter((eleve) =>
    (
      (eleve.firstName || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (eleve.lastName || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (eleve.email || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (eleve.civilite || '').toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <Box p={4}>
      <Heading as="h2" size="lg" mb={4}>
        Gestion des Élèves
      </Heading>

      <Flex mb={4} align="center" wrap="wrap" justify="space-between">
        <Flex align="center" wrap="wrap">
          <Input
            placeholder="Rechercher un élève par civilité, prénom, nom ou email"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            mr={2}
            mb={{ base: 2, md: 0 }}
          />
          <Button onClick={() => setSearchTerm('')} mr={2} mb={{ base: 2, md: 0 }}>
            Réinitialiser
          </Button>
        </Flex>
        <Button
          leftIcon={<AddIcon />}
          colorScheme="teal"
          onClick={() => openModal()}
          mb={{ base: 2, md: 0 }}
        >
          Ajouter Élève
        </Button>
      </Flex>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Civilité</Th>
            <Th>Prénom</Th>
            <Th>Nom</Th>
            <Th>Email</Th>
            <Th>Date de Naissance</Th>
            <Th>Ville de Naissance</Th>
            <Th>Adresse</Th>
            <Th>Code Postal</Th>
            <Th>Ville</Th>
            <Th>Classe</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredEleves.map((eleve) => (
            <Tr key={eleve.id}>
              <Td>{eleve.civilite}</Td>
              <Td>{eleve.firstName}</Td>
              <Td>{eleve.lastName}</Td>
              <Td>{eleve.email}</Td>
              <Td>{eleve.dateOfBirth ? formatDateSafe(eleve.dateOfBirth) : 'N/A'}</Td>
              <Td>{eleve.placeOfBirth}</Td>
              <Td>{eleve.adresse}</Td>
              <Td>{eleve.codePostal}</Td>
              <Td>{eleve.ville}</Td>
              <Td>{getClasseName(eleve.classeId)}</Td>
              <Td>
                <IconButton
                  aria-label="Voir les détails"
                  icon={<ViewIcon />}
                  size="sm"
                  mr={2}
                  onClick={() => handleViewDetails(eleve)}
                />
                <IconButton
                  aria-label="Éditer l'élève"
                  icon={<EditIcon />}
                  size="sm"
                  colorScheme="yellow"
                  mr={2}
                  onClick={() => openModal(eleve)}
                />
                <IconButton
                  aria-label="Supprimer l'élève"
                  icon={<DeleteIcon />}
                  size="sm"
                  colorScheme="red"
                  onClick={() => handleDeleteEleve(eleve.id)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default Eleves;