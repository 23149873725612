// src/organisme/OrganismeSettings.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Input,
  Heading,
  Select,
  Text,
  VStack,
  Divider,
} from '@chakra-ui/react';
import {
  collection,
  getDocs,
  query,
  where,
  setDoc,
  doc,
  getDoc,
} from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase';
import { useAuth } from '../AuthContext';
import CreateRole from './CreateRole';
import CreateCentre from './CreateCentre';

const OrganismeSettings = () => {
  const [users, setUsers] = useState([]);
  const [centres, setCentres] = useState([]);
  const [roles, setRoles] = useState([]);
  const [newUser, setNewUser] = useState({
    email: '',
    password: '',
    role: '',
    centreId: '',
  });
  const [organismeId, setOrganismeId] = useState(null);
  const { currentUser } = useAuth();

  // Récupérer l'organismeId basé sur l'utilisateur connecté
  useEffect(() => {
    const fetchOrganismeId = async () => {
      if (!currentUser) return;

      try {
        // Récupérer le document de l'utilisateur courant
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const userOrganismeId = userData.organismeId;

          if (userOrganismeId) {
            setOrganismeId(userOrganismeId);
          } else {
            console.error("L'utilisateur n'a pas d'organismeId");
          }
        } else {
          console.error("Le document de l'utilisateur n'existe pas");
        }
      } catch (error) {
        console.error('Erreur lors de la récupération de organismeId :', error);
      }
    };

    fetchOrganismeId();
  }, [currentUser]);

  // Fonction pour récupérer les centres
  const fetchCentres = async () => {
    if (!organismeId) return;

    try {
      const centresQuery = query(
        collection(db, 'centres'),
        where('organismeId', '==', organismeId)
      );
      const centresSnapshot = await getDocs(centresQuery);
      setCentres(centresSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error('Erreur lors de la récupération des centres :', error);
    }
  };

  // Appel initial pour récupérer les centres
  useEffect(() => {
    fetchCentres();
  }, [organismeId]);

  // Fonction pour récupérer les utilisateurs
  const fetchUsers = async () => {
    if (!organismeId) return;

    try {
      const usersQuery = query(
        collection(db, 'users'),
        where('organismeId', '==', organismeId)
      );
      const usersSnapshot = await getDocs(usersQuery);
      setUsers(usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error('Erreur lors de la récupération des utilisateurs :', error);
    }
  };

  // Appel initial pour récupérer les utilisateurs
  useEffect(() => {
    fetchUsers();
  }, [organismeId]);

  // Fonction pour récupérer les rôles
  const fetchRoles = async () => {
    if (!organismeId) return;

    try {
      const rolesQuery = query(
        collection(db, 'roles'),
        where('organismeId', '==', organismeId)
      );
      const rolesSnapshot = await getDocs(rolesQuery);
      setRoles(rolesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error('Erreur lors de la récupération des rôles :', error);
    }
  };

  // Appel initial pour récupérer les rôles
  useEffect(() => {
    fetchRoles();
  }, [organismeId]);

  // Gérer les changements dans le formulaire de création d'utilisateur
  const handleUserChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  // Gérer la création d'un nouvel utilisateur
  const handleCreateUser = async (e) => {
    e.preventDefault();

    if (!newUser.email || !newUser.password || !newUser.role || !newUser.centreId) {
      console.error('Veuillez remplir tous les champs');
      return;
    }

    try {
      // Créer un utilisateur avec Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        newUser.email,
        newUser.password
      );
      const user = userCredential.user;

      // Ajouter un document utilisateur dans Firestore avec l'UID comme identifiant
      await setDoc(doc(db, 'users', user.uid), {
        email: newUser.email,
        role: newUser.role,
        centreId: newUser.centreId,
        organismeId: organismeId,
      });

      // Réinitialiser le formulaire
      setNewUser({
        email: '',
        password: '',
        role: '',
        centreId: '',
      });

      // Rafraîchir la liste des utilisateurs
      fetchUsers();
    } catch (error) {
      console.error("Erreur lors de la création de l'utilisateur :", error);
    }
  };

  return (
    <Box p={8}>
      <Heading as="h2" mb={6}>
        Paramètres de l'organisme
      </Heading>

      {/* Intégration du composant CreateCentre */}
      <Box mb={8}>
        <CreateCentre
          organismeId={organismeId}
          onCentreCreated={fetchCentres}
        />
      </Box>

      <Divider my={8} />

      {/* Formulaire pour créer un utilisateur */}
      <Box mb={8}>
        <Heading as="h3" size="md" mb={4}>
          Créer un utilisateur
        </Heading>
        <form onSubmit={handleCreateUser}>
          <VStack spacing={4} align="stretch">
            <Input
              placeholder="Email"
              name="email"
              type="email"
              value={newUser.email}
              onChange={handleUserChange}
              required
            />
            <Input
              placeholder="Mot de passe"
              name="password"
              type="password"
              value={newUser.password}
              onChange={handleUserChange}
              required
            />
            <Select
              placeholder="Sélectionner un rôle"
              name="role"
              value={newUser.role}
              onChange={handleUserChange}
              required
            >
              {roles.map((role) => (
                <option key={role.id} value={role.roleName}>
                  {role.roleName}
                </option>
              ))}
            </Select>
            <Select
              placeholder="Sélectionner un centre"
              name="centreId"
              value={newUser.centreId}
              onChange={handleUserChange}
              required
            >
              {centres.map((centre) => (
                <option key={centre.id} value={centre.id}>
                  {centre.centreName}
                </option>
              ))}
            </Select>
            <Button type="submit" colorScheme="blue">
              Créer l'utilisateur
            </Button>
          </VStack>
        </form>
      </Box>

      <Divider my={8} />

      {/* Intégration du composant CreateRole */}
      <Box mb={8}>
        <CreateRole organismeId={organismeId} onRoleCreated={fetchRoles} />
      </Box>

      <Divider my={8} />

      {/* Affichage des utilisateurs existants */}
      <Box>
        <Heading as="h3" size="md" mb={4}>
          Utilisateurs existants
        </Heading>
        {users.length > 0 ? (
          <VStack spacing={3} align="stretch">
            {users.map((user) => (
              <Box key={user.id} p={4} borderWidth="1px" borderRadius="md">
                <Text>
                  <strong>Email :</strong> {user.email}
                </Text>
                <Text>
                  <strong>Rôle :</strong> {user.role}
                </Text>
                <Text>
                  <strong>Centre :</strong>{' '}
                  {centres.find((c) => c.id === user.centreId)?.centreName || 'N/A'}
                </Text>
              </Box>
            ))}
          </VStack>
        ) : (
          <Text>Aucun utilisateur trouvé.</Text>
        )}
      </Box>
    </Box>
  );
};

export default OrganismeSettings;
