import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { db } from './firebase'; // Assurez-vous que Firestore est bien configuré
import { Spinner } from '@chakra-ui/react'; // Utilisation de Chakra UI pour afficher un spinner

const PrivateRoute = ({ children, requiredRole }) => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    // Observer l'état d'authentification de l'utilisateur
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        // Récupérer le rôle de l'utilisateur à partir de Firestore
        try {
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setRole(userData.role); // Stocker le rôle de l'utilisateur
          }
        } catch (error) {
          console.error('Erreur lors de la récupération du rôle :', error);
        }
      } else {
        setUser(null);
        setRole(null);
      }
      setLoading(false); // Fin du chargement après vérification
    });

    // Nettoyage de l'abonnement lors de la déconnexion
    return () => unsubscribe();
  }, [auth]);

  // Si l'utilisateur n'est pas connecté, redirection vers la page de connexion
  if (!user && !loading) {
    return <Navigate to="/auth-centre" />;
  }

  // Affichage pendant la récupération des données
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner size="xl" />
      </div>
    );
  }

  // Si le rôle ne correspond pas, redirige vers la page d'accueil
  if (role !== requiredRole) {
    return <Navigate to="/" />;
  }

  // Si tout est correct, rendre le composant enfant
  return children;
};

export default PrivateRoute;
