import React from 'react';
import {
  Box,
  Heading,
  Flex,
  Icon,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FiLayers } from 'react-icons/fi';

const PublicDashboard = () => {
  const navigate = useNavigate();

  const handleViewCatalogCentre = () => {
    navigate('/catalog-centre');
  };

  return (
    <Box
      minHeight="100vh"
      bgImage="url('/bureau.jpg')"
      bgSize="cover"
      bgPosition="center"
      color={useColorModeValue('black', 'white')}
    >
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        padding={4}
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow="sm"
      >
        <Heading size="md" color="blue.500">
          Centre de Formation
        </Heading>
      </Flex>

      <Flex
        direction="column"
        align="center"
        justify="center"
        padding={8}
        mt={4}
      >
        <Heading as="h1" size="xl" mb={6} textAlign="center">
          Tableau de bord
        </Heading>
        <Flex
          direction={['column', 'row']}
          wrap="wrap"
          justify="center"
          maxW="1200px"
          gap={6}
        >
          {/* Carte Catalogue des Formations */}
          <Box
            bg={useColorModeValue('white', 'gray.700')}
            borderRadius="lg"
            shadow="md"
            p={6}
            textAlign="center"
            width={['100%', '45%', '30%']}
          >
            <Icon as={FiLayers} w={12} h={12} color="green.500" mb={4} />
            <Heading as="h3" size="md" mb={2}>
              Catalogue des Formations
            </Heading>
            <Button
              colorScheme="green"
              variant="outline"
              onClick={handleViewCatalogCentre}
            >
              Voir
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default PublicDashboard;
