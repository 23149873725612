// src/organisme/ManageClasses.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  Text,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import { AddIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  onSnapshot,
  Timestamp,
  query,
  where,
  getDoc,
} from 'firebase/firestore';
import { db, auth } from '../firebase';
import { useForm } from 'react-hook-form';
import { useAuthState } from 'react-firebase-hooks/auth';

const ManageClasses = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [classes, setClasses] = useState([]);
  const [formations, setFormations] = useState([]);
  const [centres, setCentres] = useState([]);
  const [selectedClasse, setSelectedClasse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormationsLoading, setIsFormationsLoading] = useState(true);
  const [isCentresLoading, setIsCentresLoading] = useState(true);
  const [organismeId, setOrganismeId] = useState(null);

  // Utiliser react-firebase-hooks pour obtenir l'utilisateur actuel
  const [user, loadingAuth, errorAuth] = useAuthState(auth);

  // Initialiser react-hook-form
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  // Récupérer organismeId de l'utilisateur connecté
  useEffect(() => {
    if (loadingAuth) return;
    if (!user) {
      toast({
        title: 'Non Authentifié',
        description: "Vous devez être connecté pour gérer les classes.",
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const fetchOrganismeId = async () => {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();

          if (userData.role !== 'organisme') {
            toast({
              title: 'Accès Refusé',
              description: "Vous n'êtes pas autorisé à gérer les classes.",
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
            // Vous pouvez rediriger l'utilisateur ou prendre une autre action ici
            return;
          }

          // Si l'utilisateur est un organisme, son uid est utilisé comme organismeId
          setOrganismeId(user.uid);
        } else {
          console.error('Document utilisateur non trouvé');
          toast({
            title: 'Erreur',
            description: "Impossible de récupérer les informations de l'utilisateur.",
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error('Erreur lors de la récupération de organismeId :', error);
        toast({
          title: 'Erreur',
          description: "Impossible de récupérer les informations de l'utilisateur.",
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchOrganismeId();
  }, [user, loadingAuth, toast]);

  // Fetch Classes with Real-time Listener filtered by organismeId
  useEffect(() => {
    if (!organismeId) {
      setIsLoading(false);
      setClasses([]);
      return;
    }

    const q = query(collection(db, 'classes'), where('organismeId', '==', organismeId));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const classesList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        startDate: doc.data().startDate?.toDate(),
        endDate: doc.data().endDate?.toDate(),
      }));
      setClasses(classesList);
      setIsLoading(false);
      console.log("Classes fetched:", classesList); // Log classes
    }, (error) => {
      console.error("Erreur lors de la récupération des classes :", error);
      toast({
        title: 'Erreur',
        description: "Impossible de récupérer les classes.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [organismeId, toast]);

  // Fetch Formations associées à l'organisme
  useEffect(() => {
    const fetchFormations = async () => {
      if (!organismeId) return;

      try {
        const formationsQuery = query(
          collection(db, 'formations'),
          where('organismeId', '==', organismeId)
        );
        const formationsSnapshot = await getDocs(formationsQuery);
        const formationsList = formationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setFormations(formationsList);
        setIsFormationsLoading(false);
        console.log("Formations fetched:", formationsList); // Log formations
      } catch (error) {
        console.error("Erreur lors de la récupération des formations :", error);
        toast({
          title: 'Erreur',
          description: "Impossible de récupérer les formations.",
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setIsFormationsLoading(false);
      }
    };

    fetchFormations();
  }, [organismeId, toast]);

  // Fetch Centres associés à l'organisme
  useEffect(() => {
    const fetchCentres = async () => {
      if (!organismeId) return;

      try {
        const centresQuery = query(
          collection(db, 'centres'),
          where('organismeId', '==', organismeId)
        );
        const centresSnapshot = await getDocs(centresQuery);
        const centresList = centresSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCentres(centresList);
        setIsCentresLoading(false);
        console.log("Centres fetched:", centresList); // Log centres
      } catch (error) {
        console.error("Erreur lors de la récupération des centres :", error);
        toast({
          title: 'Erreur',
          description: "Impossible de récupérer les centres.",
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setIsCentresLoading(false);
      }
    };

    fetchCentres();
  }, [organismeId, toast]);

  // Open Modal for Add/Edit
  const openModal = (classe = null) => {
    if (!user) {
      toast({
        title: 'Non Authentifié',
        description: "Vous devez être connecté pour gérer les classes.",
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setSelectedClasse(classe);
    if (classe) {
      // Pre-fill form for editing
      reset({
        className: classe.className,
        formationId: classe.formationId,
        startDate: classe.startDate ? classe.startDate.toISOString().substr(0, 10) : '',
        endDate: classe.endDate ? classe.endDate.toISOString().substr(0, 10) : '',
        centreId: classe.centreId,
      });
    } else {
      // Reset form for adding
      reset({
        className: '',
        formationId: '',
        startDate: '',
        endDate: '',
        centreId: '',
      });
    }
    onOpen();
  };

  // Submit Handler
  const onSubmit = async (data) => {
    if (!user) {
      toast({
        title: 'Non Authentifié',
        description: "Vous devez être connecté pour gérer les classes.",
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const classData = {
        className: data.className,
        formationId: data.formationId,
        startDate: Timestamp.fromDate(new Date(data.startDate)),
        endDate: Timestamp.fromDate(new Date(data.endDate)),
        centreId: data.centreId,
        updatedAt: Timestamp.now(),
        organismeId: organismeId, // Associer la classe à l'organisme
      };

      if (selectedClasse) {
        // Update existing class
        const classeRef = doc(db, 'classes', selectedClasse.id);
        await updateDoc(classeRef, classData);
        toast({
          title: 'Succès',
          description: 'Classe mise à jour avec succès.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        // Add new class
        await addDoc(collection(db, 'classes'), {
          ...classData,
          createdAt: Timestamp.now(),
        });
        toast({
          title: 'Succès',
          description: 'Classe ajoutée avec succès.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
      onClose();
      setSelectedClasse(null);
      reset();
    } catch (error) {
      console.error("Erreur lors de l'ajout/mise à jour de la classe :", error);
      toast({
        title: 'Erreur',
        description: "Impossible d'ajouter ou de mettre à jour la classe.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Delete Handler
  const handleDelete = async (classeId) => {
    if (!user) {
      toast({
        title: 'Non Authentifié',
        description: "Vous devez être connecté pour gérer les classes.",
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      // Vérifier que la classe appartient à l'organisme avant de la supprimer
      const classeRef = doc(db, 'classes', classeId);
      const classeSnap = await getDoc(classeRef);

      if (!classeSnap.exists() || classeSnap.data().organismeId !== organismeId) {
        toast({
          title: 'Erreur',
          description: "Vous n'avez pas la permission de supprimer cette classe.",
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      await deleteDoc(classeRef);
      toast({
        title: 'Succès',
        description: 'Classe supprimée avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erreur lors de la suppression de la classe :", error);
      toast({
        title: 'Erreur',
        description: "Impossible de supprimer la classe.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Helper Functions to Get Names
  const getFormationName = (formationId) => {
    const formation = formations.find(f => f.id === formationId);
    return formation ? (formation.title || 'Formation Inconnue') : 'Formation Inconnue';
  };

  const getCentreName = (centreId) => {
    const centre = centres.find(c => c.id === centreId);
    return centre ? (centre.centreName || centre.name) : 'Centre Inconnu';
  };

  return (
    <Box p={6} bg="white" borderRadius="lg" shadow="lg" maxW="1200px" mx="auto" mt={8}>
      <Heading as="h2" size="lg" mb={6} textAlign="center">
        Gestion des Classes
      </Heading>

      {/* Button to Add New Class */}
      <Button
        leftIcon={<AddIcon />}
        colorScheme="teal"
        mb={4}
        onClick={() => openModal()}
        isDisabled={!user}
      >
        Ajouter une Classe
      </Button>

      {/* Classes Table */}
      {isLoading ? (
        <Flex justify="center" align="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Nom de la Classe</Th>
              <Th>Formation</Th>
              <Th>Date de Début</Th>
              <Th>Date de Fin</Th>
              <Th>Centre</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {classes.map(classe => (
              <Tr key={classe.id}>
                <Td>{classe.className}</Td>
                <Td>{getFormationName(classe.formationId)}</Td>
                <Td>{classe.startDate ? classe.startDate.toLocaleDateString() : 'N/A'}</Td>
                <Td>{classe.endDate ? classe.endDate.toLocaleDateString() : 'N/A'}</Td>
                <Td>{getCentreName(classe.centreId)}</Td>
                <Td>
                  <IconButton
                    aria-label="Modifier"
                    icon={<EditIcon />}
                    mr={2}
                    onClick={() => openModal(classe)}
                    isDisabled={!user}
                  />
                  <IconButton
                    aria-label="Supprimer"
                    icon={<DeleteIcon />}
                    colorScheme="red"
                    onClick={() => handleDelete(classe.id)}
                    isDisabled={!user}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}

      {/* Modal for Add/Edit Class */}
      <Modal isOpen={isOpen} onClose={() => { onClose(); setSelectedClasse(null); }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedClasse ? 'Modifier la Classe' : 'Ajouter une Classe'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack as="form" spacing={4} onSubmit={handleSubmit(onSubmit)}>
              {/* Class Name */}
              <FormControl isRequired isInvalid={errors.className}>
                <FormLabel>Nom de la Classe</FormLabel>
                <Input
                  placeholder="Entrez le nom de la classe"
                  {...register('className', { required: 'Le nom est requis' })}
                />
                {errors.className && <Text color="red.500">{errors.className.message}</Text>}
              </FormControl>

              {/* Formation Selection */}
              <FormControl isRequired isInvalid={errors.formationId}>
                <FormLabel>Formation</FormLabel>
                {isFormationsLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <Select
                    placeholder="Sélectionnez une formation"
                    {...register('formationId', { required: 'La formation est requise' })}
                  >
                    {formations.length === 0 ? (
                      <option disabled>Aucune formation disponible</option>
                    ) : (
                      formations.map(formation => (
                        <option key={formation.id} value={formation.id}>
                          {formation.title || 'Nom de Formation'}
                        </option>
                      ))
                    )}
                  </Select>
                )}
                {errors.formationId && <Text color="red.500">{errors.formationId.message}</Text>}
              </FormControl>

              {/* Start Date */}
              <FormControl isRequired isInvalid={errors.startDate}>
                <FormLabel>Date de Début</FormLabel>
                <Input
                  type="date"
                  {...register('startDate', { required: 'La date de début est requise' })}
                />
                {errors.startDate && <Text color="red.500">{errors.startDate.message}</Text>}
              </FormControl>

              {/* End Date */}
              <FormControl isRequired isInvalid={errors.endDate}>
                <FormLabel>Date de Fin</FormLabel>
                <Input
                  type="date"
                  {...register('endDate', { required: 'La date de fin est requise' })}
                />
                {errors.endDate && <Text color="red.500">{errors.endDate.message}</Text>}
              </FormControl>

              {/* Centre Selection */}
              <FormControl isRequired isInvalid={errors.centreId}>
                <FormLabel>Centre Rattaché</FormLabel>
                {isCentresLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <Select
                    placeholder="Sélectionnez un centre"
                    {...register('centreId', { required: 'Le centre est requis' })}
                  >
                    {centres.length === 0 ? (
                      <option disabled>Aucun centre disponible</option>
                    ) : (
                      centres.map(centre => (
                        <option key={centre.id} value={centre.id}>
                          {centre.centreName || centre.name}
                        </option>
                      ))
                    )}
                  </Select>
                )}
                {errors.centreId && <Text color="red.500">{errors.centreId.message}</Text>}
              </FormControl>

              {/* Submit Button */}
              <Button type="submit" colorScheme="teal" width="full">
                {selectedClasse ? 'Mettre à Jour' : 'Ajouter'}
              </Button>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={() => { onClose(); setSelectedClasse(null); }}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ManageClasses;
