// src/firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Pour l'authentification (si nécessaire)
import { getFirestore } from "firebase/firestore"; // Firestore
import { getStorage } from "firebase/storage"; // Storage
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyA2b-CYPG6owGxUjtyj5fHE5yJrwHr74fM",
  authDomain: "trainotech-e19e2.firebaseapp.com",
  databaseURL: "https://trainotech-e19e2-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "trainotech-e19e2",
  storageBucket: "trainotech-e19e2.appspot.com",
  messagingSenderId: "116103532820",
  appId: "1:116103532820:web:dbd0379a463f628c14f201",
  measurementId: "G-ELP2J8KTSH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase services
export const auth = getAuth(app); // Authentification (si nécessaire)
export const db = getFirestore(app); // Firestore
export const storage = getStorage(app); // Storage

export default app;
