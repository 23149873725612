// src/organisme/Dashboard.js

import React from 'react';
import {
  Box,
  Heading,
  Flex,
  Icon,
  Button,
  VStack,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  FiBook,
  FiUsers,
  FiHome,
  FiUser,
  FiBookOpen,
  FiDollarSign,
  FiLogOut,
  FiMenu,
} from 'react-icons/fi';
import { getAuth } from 'firebase/auth';

const Dashboard = () => {
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogout = () => {
    auth.signOut();
    navigate('/');
  };

  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <Box
      minHeight="100vh"
      bgImage="url('/bureauOrganisme.jpg')"
      bgSize="cover"
      bgPosition="center"
      color={useColorModeValue('black', 'white')}
    >
      {/* Barre de navigation */}
      <Flex as="nav" align="center" justify="space-between" padding={4} bg={useColorModeValue('white', 'gray.900')} boxShadow="sm">
        <Heading size="md" color="blue.500">Tableau de bord Organisme</Heading>
        <Menu>
          <MenuButton as={Button} variant="ghost" rightIcon={<FiMenu />}>
            Menu
          </MenuButton>
          <MenuList>
            <MenuItem icon={<FiLogOut />} onClick={handleLogout}>Déconnexion</MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      {/* Contenu principal */}
      <Flex direction="column" align="center" justify="center" padding={8} mt={4}>
        <Heading as="h1" size="xl" mb={6} textAlign="center">Tableau de bord</Heading>
        <Flex direction={['column', 'row']} wrap="wrap" justify="center" maxW="1200px" gap={6}>
          
          {/* Carte Classes */}
          <Box bg={useColorModeValue('white', 'gray.700')} borderRadius="lg" shadow="md" p={6} textAlign="center" width={['100%', '45%', '30%']}>
            <Icon as={FiBook} w={12} h={12} color="blue.500" mb={4} />
            <Heading as="h3" size="md" mb={2}>Classes</Heading>
            <Button colorScheme="blue" variant="outline" onClick={() => handleClick('/manage-classes')}>Gérer</Button>
          </Box>

          {/* Carte Candidats */}
          <Box bg={useColorModeValue('white', 'gray.700')} borderRadius="lg" shadow="md" p={6} textAlign="center" width={['100%', '45%', '30%']}>
            <Icon as={FiUsers} w={12} h={12} color="teal.500" mb={4} />
            <Heading as="h3" size="md" mb={2}>Candidats</Heading>
            <Button colorScheme="teal" variant="outline" onClick={() => handleClick('/manage-candidates')}>Gérer</Button>
          </Box>

          {/* Carte Centres */}
          <Box bg={useColorModeValue('white', 'gray.700')} borderRadius="lg" shadow="md" p={6} textAlign="center" width={['100%', '45%', '30%']}>
            <Icon as={FiHome} w={12} h={12} color="purple.500" mb={4} />
            <Heading as="h3" size="md" mb={2}>Centres</Heading>
            <Button colorScheme="purple" variant="outline" onClick={() => handleClick('/manage-centres')}>Gérer</Button>
          </Box>

          {/* Carte Élèves */}
          <Box bg={useColorModeValue('white', 'gray.700')} borderRadius="lg" shadow="md" p={6} textAlign="center" width={['100%', '45%', '30%']}>
            <Icon as={FiUser} w={12} h={12} color="green.500" mb={4} />
            <Heading as="h3" size="md" mb={2}>Élèves</Heading>
            <Button colorScheme="green" variant="outline" onClick={() => handleClick('/eleves')}>Gérer</Button>
          </Box>

          {/* Carte Formations */}
          <Box bg={useColorModeValue('white', 'gray.700')} borderRadius="lg" shadow="md" p={6} textAlign="center" width={['100%', '45%', '30%']}>
            <Icon as={FiBookOpen} w={12} h={12} color="cyan.500" mb={4} />
            <Heading as="h3" size="md" mb={2}>Formations</Heading>
            <Button colorScheme="cyan" variant="outline" onClick={() => handleClick('/formations')}>Gérer</Button>
          </Box>

          {/* Carte Financement */}
          <Box bg={useColorModeValue('white', 'gray.700')} borderRadius="lg" shadow="md" p={6} textAlign="center" width={['100%', '45%', '30%']}>
            <Icon as={FiDollarSign} w={12} h={12} color="orange.500" mb={4} />
            <Heading as="h3" size="md" mb={2}>Financement</Heading>
            <Button colorScheme="orange" variant="outline" onClick={() => handleClick('/financement')}>Gérer</Button>
          </Box>

          {/* Carte Paramètres */}
          <Box bg={useColorModeValue('white', 'gray.700')} borderRadius="lg" shadow="md" p={6} textAlign="center" width={['100%', '45%', '30%']}>
            <Icon as={FiMenu} w={12} h={12} color="yellow.500" mb={4} />
            <Heading as="h3" size="md" mb={2}>Paramètres</Heading>
            <Button colorScheme="yellow" variant="outline" onClick={() => handleClick('/settings')}>Gérer</Button>
          </Box>
          
        </Flex>
      </Flex>
    </Box>
  );
};

export default Dashboard;
