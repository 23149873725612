// src/components/NavigationArrows.js

import React from 'react';
import { HStack, IconButton, Tooltip } from '@chakra-ui/react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const NavigationArrows = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigue vers la page précédente dans l'historique
  };

  const handleForward = () => {
    navigate(1); // Navigue vers la page suivante dans l'historique
  };

  return (
    <HStack spacing={4} position="fixed" bottom="20px" right="20px" zIndex="1000">
      <Tooltip label="Retour" aria-label="Retour">
        <IconButton
          icon={<FaArrowLeft />}
          onClick={handleBack}
          variant="solid"
          colorScheme="teal"
          aria-label="Retour"
        />
      </Tooltip>
      <Tooltip label="Avancer" aria-label="Avancer">
        <IconButton
          icon={<FaArrowRight />}
          onClick={handleForward}
          variant="solid"
          colorScheme="teal"
          aria-label="Avancer"
        />
      </Tooltip>
    </HStack>
  );
};

export default NavigationArrows;
