// src/OrganismeLogin.js
import React, { useState } from 'react';
import { Button, Input, Box, Heading, Text, Flex, Spinner } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { db } from './firebase'; // Assurez-vous que Firebase est bien configuré

const OrganismeLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleLogin = async () => {
    // Réinitialiser les messages d'erreur
    setErrorMessage('');

    // Validation des champs
    if (!email || !password) {
      setErrorMessage('Tous les champs sont obligatoires.');
      return;
    }

    if (!validateEmail(email)) {
      setErrorMessage('Veuillez entrer une adresse email valide.');
      return;
    }

    if (password.length < 6) {
      setErrorMessage('Le mot de passe doit contenir au moins 6 caractères.');
      return;
    }

    setIsLoading(true);

    try {
      const auth = getAuth();
      // Connexion de l'utilisateur avec Firebase Authentication
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Vérifier le rôle dans Firestore
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.role === 'organisme') {
          navigate('/dashboard'); // Redirection vers le dashboard organisme
        } else {
          setErrorMessage('Accès refusé. Ce compte n\'est pas un compte organisme.');
        }
      } else {
        setErrorMessage('Utilisateur non trouvé.');
      }
    } catch (error) {
      // Gestion des erreurs spécifiques
      switch (error.code) {
        case 'auth/invalid-email':
          setErrorMessage('Adresse email invalide.');
          break;
        case 'auth/user-disabled':
          setErrorMessage('Ce compte a été désactivé.');
          break;
        case 'auth/user-not-found':
          setErrorMessage('Aucun utilisateur trouvé avec cet email.');
          break;
        case 'auth/wrong-password':
          setErrorMessage('Mot de passe incorrect.');
          break;
        default:
          setErrorMessage('Erreur lors de la connexion : ' + error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      height="100vh"
      bgImage="url('/commercial.jpg')" // Assurez-vous que le chemin de l'image est correct
      bgSize="cover"
      bgPosition="center"
    >
      <Box
        p={8}
        bg="whiteAlpha.900" // Utilisation d'une opacité pour permettre un léger aperçu de l'image de fond
        borderRadius="lg"
        shadow="lg"
        width={{ base: '90%', sm: '400px' }}
      >
        <Heading as="h2" mb={6} textAlign="center" color="gray.700">
          Connexion Organisme
        </Heading>

        {errorMessage && (
          <Text color="red.500" mb={4} textAlign="center">
            {errorMessage}
          </Text>
        )}

        <Input
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          mb={4}
          focusBorderColor="blue.500"
          type="email"
          aria-label="Email"
          onKeyPress={handleKeyPress}
        />
        <Input
          placeholder="Mot de passe"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          mb={4}
          focusBorderColor="blue.500"
          aria-label="Mot de passe"
          onKeyPress={handleKeyPress}
        />
        <Button
          onClick={handleLogin}
          colorScheme="blue"
          size="lg"
          width="100%"
          isDisabled={isLoading}
        >
          {isLoading ? <Spinner size="sm" /> : 'Se connecter'}
        </Button>
      </Box>
    </Flex>
  );
};

export default OrganismeLogin;
