import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Heading, Text, VStack, Flex, Button, Stack, Input, HStack, Avatar, Badge, IconButton, Divider,
  Alert, AlertIcon, AlertTitle, AlertDescription, useToast, Image, FormControl, FormLabel, Textarea,
  Select, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton,
  Table, Thead, Tbody, Tr, Th, Td, Spinner,
} from '@chakra-ui/react';
import { CloseIcon, DownloadIcon, EditIcon, AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { doc, getDoc, updateDoc, deleteDoc, addDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useParams, useNavigate } from 'react-router-dom';

const ViewEleveFile = () => {
  const { eleveId } = useParams();
  const navigate = useNavigate();
  const [eleve, setEleve] = useState(null);
  const [centreName, setCentreName] = useState('');
  const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [allStudentDocuments, setAllStudentDocuments] = useState([]);
  const [competences, setCompetences] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [notes, setNotes] = useState([]);
  const [comments, setComments] = useState([]);
  const [dispenses, setDispenses] = useState([]);
  const [internships, setInternships] = useState([]);
  const [companies, setCompanies] = useState([]); // Pour stocker les entreprises
  const [newNote, setNewNote] = useState('');
  const [newComment, setNewComment] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const [photoFile, setPhotoFile] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [documentToUpload, setDocumentToUpload] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const toast = useToast();

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    let date;
    if (typeof timestamp.toDate === 'function') {
      date = timestamp.toDate();
    } else if (timestamp instanceof Date) {
      date = timestamp;
    } else if (typeof timestamp === 'string') {
      date = new Date(timestamp);
      if (isNaN(date)) return 'N/A';
    } else {
      return 'N/A';
    }
    return `${date.getDate().toString().padStart(2, '0')}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;
  };

  const sanitizeFileName = (str) => str.replace(/[^a-z0-9_\-\.]/gi, '_').toLowerCase();

  // Fonction pour calculer le statut en fonction des dates
  const getStatus = (start, end) => {
    const now = new Date();
    const startDate = start instanceof Date ? start : start.toDate();
    const endDate = end instanceof Date ? end : end.toDate();
    if (now < startDate) {
      return 'À venir';
    } else if (now > endDate) {
      return 'Terminé';
    } else {
      return 'En cours';
    }
  };

  // Fonction pour récupérer l'élève
  const fetchEleve = useCallback(async () => {
    try {
      const eleveDoc = await getDoc(doc(db, 'eleves', eleveId));
      if (eleveDoc.exists()) {
        setEleve({ id: eleveDoc.id, ...eleveDoc.data() });
      } else {
        toast({ title: 'Erreur', description: "Élève non trouvé.", status: 'error', duration: 3000, isClosable: true });
        navigate('/eleves');
      }
    } catch {
      toast({ title: 'Erreur', description: "Impossible de récupérer les informations de l'élève.", status: 'error', duration: 3000, isClosable: true });
    }
  }, [eleveId, navigate, toast]);

  // Fonction pour récupérer le centre
  const fetchCentreData = useCallback(async (centreId) => {
    try {
      const centreDoc = await getDoc(doc(db, 'centres', centreId));
      setCentreName(centreDoc.exists() ? centreDoc.data().name || 'Centre de Formation' : 'Centre de Formation');
    } catch {
      setCentreName('Centre de Formation');
    }
  }, []);

  // Fonction pour récupérer les documents requis
  const fetchRequiredDocuments = useCallback(async (classeId) => {
    try {
      const docsSnapshot = await getDocs(query(collection(db, 'requiredDocuments'), where('classeId', '==', classeId)));
      setRequiredDocuments(docsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch {
      toast({ title: 'Erreur', description: 'Impossible de récupérer les documents requis.', status: 'error', duration: 3000, isClosable: true });
    }
  }, [toast]);

  // Fonction pour récupérer les compétences
  const fetchCompetences = useCallback(async (formationId) => {
    try {
      const formationDoc = await getDoc(doc(db, 'formations', formationId));
      setCompetences(formationDoc.exists() ? formationDoc.data().competences || [] : []);
    } catch {
      setCompetences([]);
    }
  }, []);

  // Fonction pour récupérer les documents de l'élève
  const fetchStudentDocuments = useCallback(async (studentId) => {
    try {
      const docsSnapshot = await getDocs(query(collection(db, 'studentDocuments'), where('studentId', '==', studentId)));
      setAllStudentDocuments(docsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch {
      toast({ title: 'Erreur', description: "Impossible de récupérer les documents de l'élève.", status: 'error', duration: 3000, isClosable: true });
    }
  }, [toast]);

  // Fonction pour récupérer les avertissements
  const fetchWarnings = useCallback(async (eleveId) => {
    try {
      const warningsSnapshot = await getDocs(query(collection(db, 'warnings'), where('eleveId', '==', eleveId)));
      setWarnings(warningsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch {
      toast({ title: 'Erreur', description: "Impossible de récupérer les avertissements de l'élève.", status: 'error', duration: 3000, isClosable: true });
    }
  }, [toast]);

  // Fonction pour récupérer les notes
  const fetchNotes = useCallback(async (eleveId) => {
    try {
      const notesSnapshot = await getDocs(query(collection(db, 'notes'), where('studentId', '==', eleveId)));
      setNotes(notesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch {
      toast({ title: 'Erreur', description: "Impossible de récupérer les notes de l'élève.", status: 'error', duration: 3000, isClosable: true });
    }
  }, [toast]);

  // Fonction pour récupérer les commentaires
  const fetchComments = useCallback(async (eleveId) => {
    try {
      const commentsSnapshot = await getDocs(query(collection(db, 'comments'), where('studentId', '==', eleveId)));
      setComments(commentsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch {
      toast({ title: 'Erreur', description: "Impossible de récupérer les commentaires de l'élève.", status: 'error', duration: 3000, isClosable: true });
    }
  }, [toast]);

  // Fonction pour récupérer les dispenses de l'élève
  const fetchDispenses = useCallback(async (studentId) => {
    try {
      const dispensesQuery = query(
        collection(db, 'dispenses'),
        where('studentId', '==', studentId)
      );
      const dispensesSnapshot = await getDocs(dispensesQuery);
      const fetchedDispenses = dispensesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDispenses(fetchedDispenses);
    } catch (error) {
      console.error('Erreur lors de la récupération des dispenses :', error);
      toast({
        title: 'Erreur',
        description: "Impossible de récupérer les dispenses de l'élève.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  // Fonction pour récupérer les entreprises
  const fetchCompanies = useCallback(async () => {
    try {
      const companiesSnapshot = await getDocs(collection(db, 'companies'));
      setCompanies(companiesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error('Erreur lors de la récupération des entreprises :', error);
      toast({
        title: 'Erreur',
        description: "Impossible de récupérer les entreprises.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  // Fonction pour récupérer les stages de l'élève
  const fetchInternships = useCallback(async (studentId) => {
    try {
      const internshipsQuery = query(
        collection(db, 'internships'),
        where('studentId', '==', studentId)
      );
      const internshipsSnapshot = await getDocs(internshipsQuery);
      const fetchedInternships = internshipsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setInternships(fetchedInternships);
    } catch (error) {
      console.error('Erreur lors de la récupération des stages :', error);
      toast({
        title: 'Erreur',
        description: "Impossible de récupérer les stages de l'élève.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  // useEffect pour récupérer les données de l'élève
  useEffect(() => {
    if (eleveId) fetchEleve();
  }, [eleveId, fetchEleve]);

  // useEffect pour récupérer les autres données une fois l'élève chargé
  useEffect(() => {
    if (eleve) {
      eleve.centreId && fetchCentreData(eleve.centreId);
      eleve.classeId && fetchRequiredDocuments(eleve.classeId);
      eleve.formationId && fetchCompetences(eleve.formationId);
      fetchStudentDocuments(eleve.id);
      fetchWarnings(eleve.id);
      fetchNotes(eleve.id);
      fetchComments(eleve.id);
      fetchDispenses(eleve.id);
      fetchCompanies();
      fetchInternships(eleve.id);
    }
  }, [
    eleve,
    fetchCentreData,
    fetchRequiredDocuments,
    fetchCompetences,
    fetchStudentDocuments,
    fetchWarnings,
    fetchNotes,
    fetchComments,
    fetchDispenses,
    fetchCompanies,
    fetchInternships,
  ]);

  // Fonction pour télécharger tous les documents de l'élève
  const handleDownloadAllFiles = async () => {
    if (!eleve) {
      toast({ title: 'Information', description: "Veuillez sélectionner un élève.", status: 'info', duration: 3000, isClosable: true });
      return;
    }
    setIsDownloading(true);
    const zip = new JSZip();
    const folderName = sanitizeFileName(`${eleve.lastName}_${eleve.firstName}`);
    const folder = zip.folder(folderName);
    try {
      const docsSnapshot = await getDocs(query(collection(db, 'studentDocuments'), where('studentId', '==', eleve.id)));
      const studentDocs = docsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      for (const doc of studentDocs) {
        const response = await fetch(doc.fileURL);
        if (!response.ok) throw new Error(`Échec du téléchargement du fichier: ${doc.documentName}`);
        const blob = await response.blob();
        folder.file(sanitizeFileName(doc.documentName), blob);
      }
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, `${folderName}_documents.zip`);
      toast({ title: 'Succès', description: 'Documents téléchargés avec succès.', status: 'success', duration: 3000, isClosable: true });
    } catch {
      toast({ title: 'Erreur', description: 'Impossible de télécharger les documents.', status: 'error', duration: 3000, isClosable: true });
    } finally {
      setIsDownloading(false);
    }
  };

  // Fonction pour ouvrir le modal d'édition
  const openEditModal = () => {
    if (eleve) {
      setEditFormData({
        civilite: eleve.civilite || '',
        firstName: eleve.firstName || '',
        lastName: eleve.lastName || '',
        email: eleve.email || '',
        dateOfBirth: eleve.dateOfBirth ? eleve.dateOfBirth.toDate().toISOString().split('T')[0] : '',
        placeOfBirth: eleve.placeOfBirth || '',
      });
      setPhotoFile(null);
      setIsEditModalOpen(true);
    }
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setEditFormData({});
    setPhotoFile(null);
  };

  const handleEditFormChange = (e) => setEditFormData({ ...editFormData, [e.target.name]: e.target.value });

  const handlePhotoChange = (e) => e.target.files && setPhotoFile(e.target.files[0]);

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (!eleve) return;
    setIsUpdating(true);
    try {
      const eleveRef = doc(db, 'eleves', eleve.id);
      const updatedData = {
        ...editFormData,
        dateOfBirth: editFormData.dateOfBirth ? new Date(editFormData.dateOfBirth) : null,
      };
      if (photoFile) {
        const photoExtension = photoFile.name.split('.').pop();
        const photoFileName = `photo.${photoExtension}`;
        const folderName = `${eleve.id}_${updatedData.lastName}_${updatedData.firstName}`;
        const photoStorageRef = ref(storage, `photos/${folderName}/${photoFileName}`);
        await uploadBytes(photoStorageRef, photoFile);
        updatedData.photoURL = await getDownloadURL(photoStorageRef);
      }
      await updateDoc(eleveRef, updatedData);
      toast({ title: 'Succès', description: "Informations mises à jour.", status: 'success', duration: 3000, isClosable: true });
      fetchEleve();
      closeEditModal();
    } catch {
      toast({ title: 'Erreur', description: "Impossible de mettre à jour les informations.", status: 'error', duration: 3000, isClosable: true });
    } finally {
      setIsUpdating(false);
    }
  };

  const handleOpenUploadDocumentModal = (doc) => {
    setDocumentToUpload(doc);
    setUploadFile(null);
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadDocumentModal = () => {
    setIsUploadModalOpen(false);
    setDocumentToUpload(null);
    setUploadFile(null);
  };

  const handleUploadFileChange = (e) => e.target.files && setUploadFile(e.target.files[0]);

  const handleUploadDocument = async () => {
    if (!uploadFile || !documentToUpload || !eleve) {
      toast({ title: 'Erreur', description: 'Veuillez sélectionner un fichier.', status: 'error', duration: 3000, isClosable: true });
      return;
    }
    setIsUploading(true);
    try {
      const fileExtension = uploadFile.name.split('.').pop();
      const fileName = `${documentToUpload.name}.${fileExtension}`;
      const folderName = `${eleve.id}_${eleve.lastName}_${eleve.firstName}`;
      const storageRefDoc = ref(storage, `studentDocuments/${folderName}/${fileName}`);
      await uploadBytes(storageRefDoc, uploadFile);
      const fileURL = await getDownloadURL(storageRefDoc);
      await addDoc(collection(db, 'studentDocuments'), {
        studentId: eleve.id,
        documentId: documentToUpload.id,
        documentName: documentToUpload.name,
        fileURL,
        uploadedAt: new Date(),
      });
      toast({ title: 'Succès', description: 'Document uploadé avec succès.', status: 'success', duration: 3000, isClosable: true });
      fetchStudentDocuments(eleve.id);
      handleCloseUploadDocumentModal();
    } catch {
      toast({ title: 'Erreur', description: "Impossible d'uploader le document.", status: 'error', duration: 3000, isClosable: true });
    } finally {
      setIsUploading(false);
    }
  };

  const handleDownloadStudentDocument = (studentDocument) => window.open(studentDocument.fileURL, '_blank');

  const handleDeleteEleve = async () => {
    if (!eleve) return;
    try {
      await deleteDoc(doc(db, 'eleves', eleve.id));
      toast({ title: 'Succès', description: "L'élève a été supprimé.", status: 'success', duration: 3000, isClosable: true });
      navigate('/eleves');
    } catch {
      toast({ title: 'Erreur', description: "Impossible de supprimer l'élève.", status: 'error', duration: 3000, isClosable: true });
    }
  };

  const handleAddNote = async () => {
    if (!newNote.trim()) {
      toast({ title: 'Erreur', description: 'Le champ de note est vide.', status: 'error', duration: 3000, isClosable: true });
      return;
    }
    try {
      await addDoc(collection(db, 'notes'), { studentId: eleve.id, content: newNote.trim(), createdAt: new Date() });
      toast({ title: 'Succès', description: 'Note ajoutée avec succès.', status: 'success', duration: 3000, isClosable: true });
      setNewNote('');
      fetchNotes(eleve.id);
    } catch {
      toast({ title: 'Erreur', description: "Impossible d'ajouter la note.", status: 'error', duration: 3000, isClosable: true });
    }
  };

  const handleAddComment = async () => {
    if (!newComment.trim()) {
      toast({ title: 'Erreur', description: 'Le champ de commentaire est vide.', status: 'error', duration: 3000, isClosable: true });
      return;
    }
    try {
      await addDoc(collection(db, 'comments'), { studentId: eleve.id, content: newComment.trim(), createdAt: new Date() });
      toast({ title: 'Succès', description: 'Commentaire ajouté avec succès.', status: 'success', duration: 3000, isClosable: true });
      setNewComment('');
      fetchComments(eleve.id);
    } catch {
      toast({ title: 'Erreur', description: "Impossible d'ajouter le commentaire.", status: 'error', duration: 3000, isClosable: true });
    }
  };

  return (
    <>
      <Flex direction={['column', 'row']} p={6} bg="gray.50" minHeight="100vh">
        {/* Left Column - General Information */}
        <Box flex="1" p={4} bg="white" borderRadius="md" boxShadow="md" mb={[6, 0]} mr={[0, 6]}>
          <VStack spacing={4} align="start">
            <Avatar name={`${eleve?.lastName} ${eleve?.firstName}`} src={eleve?.photoURL || ''} size="2xl" />
            <Heading size="md">{`${eleve?.lastName} ${eleve?.firstName}`}</Heading>
            <Badge colorScheme="green">Étudiant</Badge>
            <Divider />
            <Box>
              <Text fontWeight="bold">Civilité :</Text>
              <Text>{eleve?.civilite || 'N/A'}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Email :</Text>
              <Text>{eleve?.email}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Date de Naissance :</Text>
              <Text>{eleve?.dateOfBirth ? formatDate(eleve.dateOfBirth) : 'N/A'}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Lieu de Naissance :</Text>
              <Text>{eleve?.placeOfBirth || 'N/A'}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Adresse :</Text>
              <Text>{eleve?.adresse || 'N/A'}</Text>
            </Box>
            {eleve?.complementAdresse && (
              <Box>
                <Text fontWeight="bold">Complément d'Adresse :</Text>
                <Text>{eleve.complementAdresse}</Text>
              </Box>
            )}
            <Box>
              <Text fontWeight="bold">Code Postal :</Text>
              <Text>{eleve?.codePostal || 'N/A'}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Ville :</Text>
              <Text>{eleve?.ville || 'N/A'}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Centre de Formation :</Text>
              <Text>{centreName || 'N/A'}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Classe :</Text>
              <Text>{eleve?.classeName || 'N/A'}</Text>
            </Box>
            <Button leftIcon={<EditIcon />} colorScheme="yellow" onClick={openEditModal} width="full">
              Modifier
            </Button>
            <Button leftIcon={<DeleteIcon />} colorScheme="red" onClick={() => setIsDeleteModalOpen(true)} width="full">
              Supprimer l'Élève
            </Button>
            <Button leftIcon={<DownloadIcon />} colorScheme="blue" onClick={handleDownloadAllFiles} isLoading={isDownloading} width="full">
              Télécharger Tous les Documents
            </Button>
          </VStack>
        </Box>

        {/* Center Column - Notes and Comments */}
        <Box flex="2" p={4} bg="white" borderRadius="md" boxShadow="md" mr={[0, 6]} mb={[6, 0]}>
          <VStack spacing={4} align="stretch">
            <Heading size="md">Notes</Heading>
            <VStack align="stretch" spacing={2}>
              {notes.length > 0 ? (
                notes.map((note) => (
                  <Box key={note.id} p={3} bg="gray.100" borderRadius="md">
                    <Text>{note.content}</Text>
                    <Text fontSize="sm" color="gray.500">
                      {formatDate(note.createdAt)}
                    </Text>
                  </Box>
                ))
              ) : (
                <Text>Aucune note disponible.</Text>
              )}
            </VStack>
            <FormControl>
              <FormLabel>Ajouter une Note</FormLabel>
              <Textarea value={newNote} onChange={(e) => setNewNote(e.target.value)} placeholder="Écrire une nouvelle note..." />
              <Button mt={2} onClick={handleAddNote} leftIcon={<AddIcon />}>
                Ajouter
              </Button>
            </FormControl>

            <Divider />

            <Heading size="md">Commentaires</Heading>
            <VStack align="stretch" spacing={2}>
              {comments.length > 0 ? (
                comments.map((comment) => (
                  <Box key={comment.id} p={3} bg="gray.100" borderRadius="md">
                    <Text>{comment.content}</Text>
                    <Text fontSize="sm" color="gray.500">
                      {formatDate(comment.createdAt)}
                    </Text>
                  </Box>
                ))
              ) : (
                <Text>Aucun commentaire disponible.</Text>
              )}
            </VStack>
            <FormControl>
              <FormLabel>Ajouter un Commentaire</FormLabel>
              <Textarea value={newComment} onChange={(e) => setNewComment(e.target.value)} placeholder="Écrire un nouveau commentaire..." />
              <Button mt={2} onClick={handleAddComment} leftIcon={<AddIcon />}>
                Ajouter
              </Button>
            </FormControl>
          </VStack>
        </Box>

        {/* Right Column - Warnings, Dispenses, Internships, Competences, Documents */}
        <Box flex="1" p={4} bg="white" borderRadius="md" boxShadow="md">
          <VStack spacing={6} align="stretch">
            {/* Warnings */}
            <Box>
              <HStack justify="space-between">
                <Heading size="md">Avertissements</Heading>
              </HStack>
              <VStack align="stretch" spacing={2} mt={2}>
                {warnings.length > 0 ? (
                  warnings.map((warning) => (
                    <Alert status="warning" key={warning.id} borderRadius="md">
                      <AlertIcon />
                      <Box flex="1">
                        <AlertTitle>Avertissement</AlertTitle>
                        <AlertDescription>{warning.message}</AlertDescription>
                      </Box>
                      <IconButton
                        icon={<DeleteIcon />}
                        aria-label="Supprimer l'avertissement"
                        onClick={async () => {
                          if (window.confirm('Êtes-vous sûr de vouloir supprimer cet avertissement?')) {
                            try {
                              await deleteDoc(doc(db, 'warnings', warning.id));
                              toast({ title: 'Succès', description: 'Avertissement supprimé.', status: 'success', duration: 3000, isClosable: true });
                              fetchWarnings(eleve.id);
                            } catch {
                              toast({ title: 'Erreur', description: "Impossible de supprimer l'avertissement.", status: 'error', duration: 3000, isClosable: true });
                            }
                          }
                        }}
                      />
                    </Alert>
                  ))
                ) : (
                  <Alert status="success" borderRadius="md">
                    <AlertIcon />
                    Aucun avertissement.
                  </Alert>
                )}
              </VStack>
            </Box>

            {/* Dispenses */}
            <Box>
              <HStack justify="space-between">
                <Heading size="md">Dispenses</Heading>
              </HStack>
              <VStack align="stretch" spacing={2} mt={2}>
                {dispenses.length > 0 ? (
                  dispenses.map((dispense) => (
                    <Box key={dispense.id} p={3} bg="gray.100" borderRadius="md">
                      <Text>
                        Dispense du {formatDate(dispense.startDate)} au {formatDate(dispense.endDate)}
                      </Text>
                      <Text fontSize="sm" color="gray.500">
                        {dispense.requiredParticipation ? 'Participation requise' : 'Participation non requise'}
                      </Text>
                      <Text fontSize="sm" color="gray.500">
                        Statut: {getStatus(dispense.startDate, dispense.endDate)}
                      </Text>
                    </Box>
                  ))
                ) : (
                  <Text>Aucune dispense.</Text>
                )}
              </VStack>
            </Box>

            {/* Internships */}
            <Box>
              <HStack justify="space-between">
                <Heading size="md">Stages</Heading>
              </HStack>
              <VStack align="stretch" spacing={2} mt={2}>
                {internships.length > 0 ? (
                  internships.map((internship) => {
                    const company = companies.find((c) => c.id === internship.companyId);
                    return (
                      <Box key={internship.id} p={3} bg="gray.100" borderRadius="md">
                        <Text>
                          Stage du {formatDate(internship.startDate)} au {formatDate(internship.endDate)}
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                          Entreprise: {company ? company.name : 'N/A'}
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                          Statut: {getStatus(internship.startDate, internship.endDate)}
                        </Text>
                        <Button
                          mt={2}
                          size="sm"
                          leftIcon={<DownloadIcon />}
                          onClick={() => window.open(internship.agreementURL, '_blank')}
                        >
                          Voir la Convention
                        </Button>
                      </Box>
                    );
                  })
                ) : (
                  <Text>Aucun stage.</Text>
                )}
              </VStack>
            </Box>

            {/* Competences */}
            <Box>
              <HStack justify="space-between">
                <Heading size="md">Compétences</Heading>
              </HStack>
              <VStack align="stretch" spacing={2} mt={2}>
                {competences.length > 0 ? (
                  competences.map((competence, index) => (
                    <HStack key={index} justify="space-between" p={2} bg="gray.100" borderRadius="md">
                      <Text>{competence}</Text>
                      {/* Placeholder for future features */}
                      <Badge colorScheme="red">Non Dispensé</Badge>
                    </HStack>
                  ))
                ) : (
                  <Text>Aucune compétence définie pour cette classe.</Text>
                )}
              </VStack>
            </Box>

            {/* Required Documents */}
            <Box>
              <HStack justify="space-between">
                <Heading size="md">Documents Requis</Heading>
              </HStack>
              <VStack align="stretch" spacing={2} mt={2}>
                {requiredDocuments.length > 0 ? (
                  requiredDocuments.map((doc) => {
                    const studentDoc = allStudentDocuments.find((sd) => sd.documentId === doc.id);
                    return (
                      <HStack key={doc.id} justify="space-between" p={2} bg="gray.100" borderRadius="md">
                        <Text>{doc.name}</Text>
                        <HStack>
                          {studentDoc ? (
                            <>
                              <Badge colorScheme="green">Uploadé</Badge>
                              <IconButton
                                icon={<DownloadIcon />}
                                aria-label="Télécharger le document"
                                size="sm"
                                onClick={() => handleDownloadStudentDocument(studentDoc)}
                              />
                              <IconButton
                                icon={<DeleteIcon />}
                                aria-label="Supprimer le document"
                                size="sm"
                                onClick={async () => {
                                  if (window.confirm('Êtes-vous sûr de vouloir supprimer ce document?')) {
                                    try {
                                      await deleteDoc(doc(db, 'studentDocuments', studentDoc.id));
                                      toast({ title: 'Succès', description: 'Document supprimé.', status: 'success', duration: 3000, isClosable: true });
                                      fetchStudentDocuments(eleve.id);
                                    } catch {
                                      toast({ title: 'Erreur', description: 'Impossible de supprimer le document.', status: 'error', duration: 3000, isClosable: true });
                                    }
                                  }
                                }}
                              />
                            </>
                          ) : (
                            <Button colorScheme="blue" size="sm" leftIcon={<AddIcon />} onClick={() => handleOpenUploadDocumentModal(doc)}>
                              Uploader
                            </Button>
                          )}
                        </HStack>
                      </HStack>
                    );
                  })
                ) : (
                  <Text>Aucun document requis pour cette classe.</Text>
                )}
              </VStack>
            </Box>

            {/* Student Documents */}
            <Box>
              <HStack justify="space-between">
                <Heading size="md">Documents de l'Élève</Heading>
                {/* Placeholder for future features */}
              </HStack>
              <VStack align="stretch" spacing={2} mt={2}>
                {allStudentDocuments.length > 0 ? (
                  <Table variant="striped" colorScheme="teal">
                    <Thead>
                      <Tr>
                        <Th>Nom du Document</Th>
                        <Th>Date d'Upload</Th>
                        <Th>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {allStudentDocuments.map((doc) => (
                        <Tr key={doc.id}>
                          <Td>{doc.documentName}</Td>
                          <Td>{formatDate(doc.uploadedAt)}</Td>
                          <Td>
                            <HStack spacing={2}>
                              <IconButton icon={<DownloadIcon />} aria-label="Télécharger le document" size="sm" onClick={() => handleDownloadStudentDocument(doc)} />
                              <IconButton
                                icon={<DeleteIcon />}
                                aria-label="Supprimer le document"
                                size="sm"
                                onClick={async () => {
                                  if (window.confirm('Êtes-vous sûr de vouloir supprimer ce document?')) {
                                    try {
                                      await deleteDoc(doc(db, 'studentDocuments', doc.id));
                                      toast({ title: 'Succès', description: 'Document supprimé.', status: 'success', duration: 3000, isClosable: true });
                                      fetchStudentDocuments(eleve.id);
                                    } catch {
                                      toast({ title: 'Erreur', description: 'Impossible de supprimer le document.', status: 'error', duration: 3000, isClosable: true });
                                    }
                                  }
                                }}
                              />
                            </HStack>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                ) : (
                  <Text>Aucun document uploadé par l'élève.</Text>
                )}
              </VStack>
            </Box>
          </VStack>
        </Box>
      </Flex>

      {/* Edit Modal */}
      <Modal isOpen={isEditModalOpen} onClose={closeEditModal} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modifier les Informations de l'Élève</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleEditSubmit}>
            <ModalBody>
              <Stack spacing={4}>
                <FormControl id="civilite" isRequired>
                  <FormLabel>Civilité</FormLabel>
                  <Select name="civilite" value={editFormData.civilite} onChange={handleEditFormChange} placeholder="Sélectionnez une civilité">
                    <option value="M.">M.</option>
                    <option value="Mme">Mme</option>
                    <option value="Mlle">Mlle</option>
                  </Select>
                </FormControl>

                <FormControl id="firstName" isRequired>
                  <FormLabel>Prénom</FormLabel>
                  <Input type="text" name="firstName" value={editFormData.firstName} onChange={handleEditFormChange} placeholder="Prénom de l'élève" />
                </FormControl>

                <FormControl id="lastName" isRequired>
                  <FormLabel>Nom</FormLabel>
                  <Input type="text" name="lastName" value={editFormData.lastName} onChange={handleEditFormChange} placeholder="Nom de l'élève" />
                </FormControl>

                <FormControl id="email" isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" name="email" value={editFormData.email} onChange={handleEditFormChange} placeholder="Email de l'élève" />
                </FormControl>

                <FormControl id="dateOfBirth">
                  <FormLabel>Date de Naissance</FormLabel>
                  <Input type="date" name="dateOfBirth" value={editFormData.dateOfBirth} onChange={handleEditFormChange} />
                </FormControl>

                <FormControl id="placeOfBirth">
                  <FormLabel>Lieu de Naissance</FormLabel>
                  <Input type="text" name="placeOfBirth" value={editFormData.placeOfBirth} onChange={handleEditFormChange} placeholder="Lieu de naissance de l'élève" />
                </FormControl>

                <FormControl id="photo">
                  <FormLabel>Photo de l'Élève</FormLabel>
                  <Input type="file" accept="image/*" onChange={handlePhotoChange} />
                  {eleve?.photoURL && !photoFile && (
                    <Image src={eleve.photoURL} alt="Photo actuelle de l'élève" boxSize="100px" objectFit="cover" mt={2} borderRadius="full" />
                  )}
                  {photoFile && (
                    <Image src={URL.createObjectURL(photoFile)} alt="Nouvelle photo de l'élève" boxSize="100px" objectFit="cover" mt={2} borderRadius="full" />
                  )}
                </FormControl>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit" isLoading={isUpdating}>
                Enregistrer
              </Button>
              <Button variant="ghost" onClick={closeEditModal}>
                Annuler
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Supprimer l'élève</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Êtes-vous sûr de vouloir supprimer l'élève <strong>{eleve?.firstName} {eleve?.lastName}</strong>? Cette action est irréversible.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDeleteEleve}>
              Supprimer
            </Button>
            <Button variant="ghost" onClick={() => setIsDeleteModalOpen(false)}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Upload Document Modal */}
      <Modal isOpen={isUploadModalOpen} onClose={handleCloseUploadDocumentModal} size="md" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Uploader le document</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="uploadFile" isRequired>
              <FormLabel>{documentToUpload?.name}</FormLabel>
              <Input type="file" accept="application/pdf, image/*" onChange={handleUploadFileChange} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleUploadDocument} isLoading={isUploading}>
              Uploader
            </Button>
            <Button variant="ghost" onClick={handleCloseUploadDocumentModal}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewEleveFile;
