import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Box,
  Heading,
  Flex,
  Button,
  Input,
  Select,
  Checkbox,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Text,
  VStack,
  Spinner,
  Badge,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  FormControl,
  FormLabel,
  useColorModeValue,
  IconButton,
  Tooltip,
  Divider,
  Stack,
} from '@chakra-ui/react';
import { FiUsers, FiDownload, FiEdit, FiPlus, FiFilter } from 'react-icons/fi';
import { FaTrash } from 'react-icons/fa';
import { getAuth } from 'firebase/auth';
import {
  doc,
  getDoc,
  query,
  where,
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from '../firebase';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const useCentreData = (auth, toast) => {
  const [centreName, setCentreName] = useState('');
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    const fetchCentreData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setCentreName(userData.centreName || 'Centre de Formation');

            const classesSnapshot = await getDocs(
              query(collection(db, 'classes'), where('centreId', '==', userData.centreId))
            );
            setClasses(
              classesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
            );
          }
        } catch (error) {
          console.error('Erreur lors de la récupération du centre :', error);
          toast({
            title: 'Erreur',
            description: "Impossible de récupérer les informations du centre.",
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }
    };
    fetchCentreData();
  }, [auth, toast]);

  return { centreName, classes };
};

const useStudents = (classeId, toast) => {
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchStudentsByClass = async () => {
      if (!classeId) return;
      setIsLoading(true);
      try {
        const studentsSnapshot = await getDocs(
          query(collection(db, 'eleves'), where('classeId', '==', classeId))
        );
        setStudents(
          studentsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      } catch (error) {
        console.error('Erreur lors de la récupération des élèves :', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les élèves.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchStudentsByClass();
  }, [classeId, toast]);

  return { students, isLoading };
};

const useDispenses = (auth, classeId, selectedClass, toast) => {
  const [dispenses, setDispenses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDispenses = async () => {
      if (!classeId) return;
      setIsLoading(true);
      try {
        const dispensesSnapshot = await getDocs(
          query(
            collection(db, 'dispenses'),
            where('centreId', '==', auth.currentUser.uid),
            where('classeId', '==', classeId)
          )
        );
        setDispenses(
          dispensesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            className: selectedClass?.className || 'N/A',
          }))
        );
      } catch (error) {
        console.error('Erreur lors de la récupération des dispenses :', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les dispenses.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchDispenses();
  }, [auth.currentUser.uid, classeId, selectedClass, toast]);

  return { dispenses, isLoading };
};

const DispensesPage = () => {
  const auth = getAuth();
  const toast = useToast();
  const [selectedClass, setSelectedClass] = useState(null);
  const [formData, setFormData] = useState({
    studentId: '',
    startDate: '',
    endDate: '',
    requiredParticipation: false,
  });
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');
  const [selectedDispense, setSelectedDispense] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editFormData, setEditFormData] = useState({
    startDate: '',
    endDate: '',
    requiredParticipation: false,
    extendDays: 0,
  });
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [exportStartDate, setExportStartDate] = useState('');
  const [exportEndDate, setExportEndDate] = useState('');
  const [isExporting, setIsExporting] = useState(false);

  const { centreName, classes } = useCentreData(auth, toast);
  const { students } = useStudents(selectedClass?.id, toast);
  const { dispenses, isLoading: isDispensesLoading } = useDispenses(
    auth,
    selectedClass?.id,
    selectedClass,
    toast
  );

  const formatDateSafe = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = timestamp instanceof Date ? timestamp : timestamp.toDate();
    return `${String(date.getDate()).padStart(2, '0')}/${String(
      date.getMonth() + 1
    ).padStart(2, '0')}/${date.getFullYear()}`;
  };

  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { studentId, startDate, endDate, requiredParticipation } = formData;

    if (!studentId || !startDate || !endDate || new Date(startDate) > new Date(endDate)) {
      toast({
        title: 'Erreur',
        description: 'Vérifiez les champs du formulaire.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsFormSubmitting(true);
    try {
      const student = students.find((s) => s.id === studentId);
      const newDispense = {
        centreId: auth.currentUser.uid,
        classeId: selectedClass.id,
        className: selectedClass.className,
        studentId: student.id,
        studentName: `${student.firstName} ${student.lastName}`,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        requiredParticipation,
        isExtended: false,
        teachersNotified: [],
        createdAt: new Date(),
      };
      await addDoc(collection(db, 'dispenses'), newDispense);
      toast({
        title: 'Succès',
        description: 'Dispense enregistrée.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setFormData({
        studentId: '',
        startDate: '',
        endDate: '',
        requiredParticipation: false,
      });
    } catch (error) {
      console.error("Erreur lors de l'ajout de la dispense :", error);
      toast({
        title: 'Erreur',
        description: error.message || "Impossible d'ajouter la dispense.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsFormSubmitting(false);
    }
  };

  const handleDeleteDispense = async (dispenseId) => {
    try {
      await deleteDoc(doc(db, 'dispenses', dispenseId));
      toast({
        title: 'Succès',
        description: 'Dispense supprimée.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression de la dispense :', error);
      toast({
        title: 'Erreur',
        description: error.message || "Impossible de supprimer la dispense.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const openEditModal = (dispense) => {
    setSelectedDispense(dispense);
    setEditFormData({
      startDate:
        dispense.startDate instanceof Date
          ? dispense.startDate.toISOString().split('T')[0]
          : dispense.startDate.toDate().toISOString().split('T')[0],
      endDate:
        dispense.endDate instanceof Date
          ? dispense.endDate.toISOString().split('T')[0]
          : dispense.endDate.toDate().toISOString().split('T')[0],
      requiredParticipation: dispense.requiredParticipation,
      extendDays: 0,
    });
    setIsEditModalOpen(true);
  };

  const handleEditFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditFormData((prev) => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
  };

  const handleEditFormSubmit = async (e) => {
    e.preventDefault();
    const { startDate, endDate, requiredParticipation, extendDays } = editFormData;

    if (!startDate || !endDate || new Date(startDate) > new Date(endDate)) {
      toast({
        title: 'Erreur',
        description: 'Vérifiez les champs du formulaire.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsFormSubmitting(true);
    try {
      const dispenseRef = doc(db, 'dispenses', selectedDispense.id);

      let newEndDate = new Date(endDate);

      if (extendDays && parseInt(extendDays) > 0) {
        newEndDate.setDate(newEndDate.getDate() + parseInt(extendDays));
      }

      await updateDoc(dispenseRef, {
        startDate: new Date(startDate),
        endDate: newEndDate,
        requiredParticipation,
        isExtended:
          extendDays && parseInt(extendDays) > 0
            ? true
            : selectedDispense.isExtended || false,
      });

      toast({
        title: 'Succès',
        description: 'Dispense mise à jour.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la dispense :', error);
      toast({
        title: 'Erreur',
        description: error.message || "Impossible de mettre à jour la dispense.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsFormSubmitting(false);
    }
  };

  const exportDispenseToPDF = async (dispense) => {
    if (!dispense) {
      toast({
        title: 'Erreur',
        description: "Aucune dispense sélectionnée pour l'export.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const docPDF = new jsPDF();

    let yPos = 20;

    // Titre
    docPDF.setFontSize(20);
    docPDF.setTextColor('#2C7A7B'); // Couleur teal
    docPDF.text('Dossier de Dispense', 105, yPos, null, null, 'center');
    yPos += 10;

    // Si la dispense est prolongée, ajouter une mention "prolongé" en encadré orange
    if (dispense.isExtended) {
      docPDF.setFillColor(255, 165, 0); // Couleur orange
      docPDF.rect(14, yPos, 40, 10, 'F'); // Dessiner un rectangle rempli
      docPDF.setFontSize(12);
      docPDF.setTextColor(255, 255, 255); // Texte en blanc
      docPDF.text('Prolongé', 16, yPos + 7);
      yPos += 15;
    } else {
      yPos += 5;
    }

    // Informations sur la classe et le centre
    docPDF.setFontSize(12);
    docPDF.setTextColor('#4A5568'); // Couleur gray
    docPDF.text(`Centre: ${centreName}`, 14, yPos);
    yPos += 8;
    docPDF.text(
      `Classe: ${dispense.className || selectedClass.className || 'N/A'}`,
      14,
      yPos
    );
    yPos += 8;
    docPDF.text(`Élève: ${dispense.studentName}`, 14, yPos);
    yPos += 8;
    docPDF.text(`Date de Début: ${formatDateSafe(dispense.startDate)}`, 14, yPos);
    yPos += 8;
    docPDF.text(`Date de Fin: ${formatDateSafe(dispense.endDate)}`, 14, yPos);
    yPos += 8;
    docPDF.text(
      `Participation Requise: ${dispense.requiredParticipation ? 'Oui' : 'Non'}`,
      14,
      yPos
    );
    yPos += 8;

    // Ajouter une section pour les enseignants notifiés
    if (dispense.teachersNotified && dispense.teachersNotified.length > 0) {
      docPDF.text('Enseignants Notifiés:', 14, yPos);
      yPos += 8;

      // Récupérer les noms des enseignants
      const teachersToFetch = dispense.teachersNotified.slice(0, 10);
      const teachersSnapshot = await getDocs(
        query(collection(db, 'teachers'), where('__name__', 'in', teachersToFetch))
      );

      const teacherNames = [];
      teachersSnapshot.forEach((doc) => {
        teacherNames.push(`${doc.data().firstName} ${doc.data().lastName}`);
      });

      const teachersText = teacherNames.join(', ');
      docPDF.text(teachersText, 14, yPos);
      yPos += 8;
    }

    // Sauvegarder le PDF
    docPDF.save(`Dispense_${dispense.studentName}_${dispense.id}.pdf`);
  };

  const filteredDispenses = useMemo(() => {
    return dispenses.filter((dispense) => {
      const startDate = new Date(dispense.startDate);
      const endDate = new Date(dispense.endDate);
      if (filterStartDate && new Date(filterStartDate) > endDate) return false;
      if (filterEndDate && new Date(filterEndDate) < startDate) return false;
      return true;
    });
  }, [dispenses, filterStartDate, filterEndDate]);

  const bg = useColorModeValue('gray.50', 'gray.800');

  return (
    <Flex minHeight="100vh" bg={bg}>
      <Box width={['100%', '250px']} bg="blue.600" color="white" p={6} overflowY="auto">
        <Heading as="h3" size="lg" mb={6} textAlign="center">
          <Flex align="center" justify="center">
            <FiUsers size={24} style={{ marginRight: '8px' }} />
            Classes
          </Flex>
        </Heading>
        <VStack spacing={4} align="stretch">
          {classes.length > 0 ? (
            classes.map((cls) => (
              <Button
                key={cls.id}
                variant={selectedClass?.id === cls.id ? 'solid' : 'outline'}
                bg={selectedClass?.id === cls.id ? 'purple.500' : 'gray.200'}
                color={selectedClass?.id === cls.id ? 'white' : 'black'}
                justifyContent="flex-start"
                leftIcon={<FiUsers />}
                onClick={() => setSelectedClass(cls)}
                whiteSpace="normal"
                wordBreak="break-word"
                textAlign="left"
                width="100%"
                _hover={{
                  bg: selectedClass?.id === cls.id ? 'purple.600' : 'gray.300',
                  color: 'white',
                }}
              >
                {cls.className}
              </Button>
            ))
          ) : (
            <Text textAlign="center">Aucune classe assignée.</Text>
          )}
        </VStack>
      </Box>

      <Box flex="1" p={6}>
        {selectedClass ? (
          <>
            <Flex
              direction={['column', 'row']}
              justify="space-between"
              align={['flex-start', 'center']}
              mb={6}
            >
              <Box>
                <Heading as="h2" size="xl" mb={2}>
                  {selectedClass.className}
                </Heading>
                <Text fontSize="md" color="gray.600">
                  Centre : {centreName}
                </Text>
              </Box>
              <Button
                colorScheme="teal"
                leftIcon={<FiDownload />}
                onClick={() => setIsExportModalOpen(true)}
                mt={[4, 0]}
              >
                Exporter les Dispenses
              </Button>
            </Flex>

            <Box
              mb={8}
              p={6}
              bg="white"
              borderRadius="md"
              boxShadow="md"
              border="1px"
              borderColor="gray.200"
            >
              <Flex
                justify="space-between"
                align="center"
                mb={4}
                flexDirection={['column', 'row']}
              >
                <Heading size="md" mb={[4, 0]}>
                  <Flex align="center">
                    <FiPlus style={{ marginRight: '8px' }} />
                    Ajouter une Nouvelle Dispense
                  </Flex>
                </Heading>
                <Button
                  colorScheme="teal"
                  leftIcon={<FiDownload />}
                  onClick={() => setIsExportModalOpen(true)}
                >
                  Exporter les Dispenses
                </Button>
              </Flex>
              <Divider mb={4} />
              <form onSubmit={handleFormSubmit}>
                <Stack direction={['column', 'row']} spacing={4} align="flex-start">
                  <FormControl isRequired>
                    <FormLabel>Élève</FormLabel>
                    <Select
                      placeholder="Sélectionnez un élève"
                      name="studentId"
                      value={formData.studentId}
                      onChange={handleFormChange}
                    >
                      {students.map((student) => (
                        <option key={student.id} value={student.id}>
                          {student.firstName} {student.lastName}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Date de Début</FormLabel>
                    <Input
                      type="date"
                      name="startDate"
                      value={formData.startDate}
                      onChange={handleFormChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Date de Fin</FormLabel>
                    <Input
                      type="date"
                      name="endDate"
                      value={formData.endDate}
                      onChange={handleFormChange}
                    />
                  </FormControl>
                  <FormControl alignSelf="flex-end">
                    <Checkbox
                      name="requiredParticipation"
                      isChecked={formData.requiredParticipation}
                      onChange={handleFormChange}
                    >
                      Participation requise
                    </Checkbox>
                  </FormControl>
                </Stack>
                <Button type="submit" colorScheme="blue" mt={4} isLoading={isFormSubmitting}>
                  Enregistrer la Dispense
                </Button>
              </form>
            </Box>

            <Box mb={6}>
              <Heading size="md" mb={4}>
                <Flex align="center">
                  <FiFilter style={{ marginRight: '8px' }} />
                  Filtrer par Date
                </Flex>
              </Heading>
              <Stack direction={['column', 'row']} spacing={4} align="flex-start">
                <FormControl>
                  <FormLabel>Date de Début</FormLabel>
                  <Input
                    type="date"
                    value={filterStartDate}
                    onChange={(e) => setFilterStartDate(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Date de Fin</FormLabel>
                  <Input
                    type="date"
                    value={filterEndDate}
                    onChange={(e) => setFilterEndDate(e.target.value)}
                  />
                </FormControl>
                <Button
                  colorScheme="red"
                  leftIcon={<FaTrash />}
                  mt={[4, 8]}
                  onClick={() => {
                    setFilterStartDate('');
                    setFilterEndDate('');
                  }}
                >
                  Réinitialiser
                </Button>
              </Stack>
            </Box>

            {isDispensesLoading ? (
              <Flex justifyContent="center" alignItems="center" height="200px">
                <Spinner size="xl" />
              </Flex>
            ) : filteredDispenses.length > 0 ? (
              <Box
                p={6}
                bg="white"
                borderRadius="md"
                boxShadow="md"
                border="1px"
                borderColor="gray.200"
              >
                <Heading size="md" mb={4}>
                  Liste des Dispenses
                </Heading>
                <Table variant="striped" colorScheme="teal">
                  <Thead>
                    <Tr>
                      <Th>Élève</Th>
                      <Th>Date de Début</Th>
                      <Th>Date de Fin</Th>
                      <Th>Participation Requise</Th>
                      <Th textAlign="center">Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredDispenses.map((dispense) => (
                      <Tr key={dispense.id}>
                        <Td>{dispense.studentName}</Td>
                        <Td>{formatDateSafe(dispense.startDate)}</Td>
                        <Td>{formatDateSafe(dispense.endDate)}</Td>
                        <Td>
                          {dispense.requiredParticipation ? (
                            <Badge colorScheme="green">Oui</Badge>
                          ) : (
                            <Badge colorScheme="red">Non</Badge>
                          )}
                        </Td>
                        <Td textAlign="center">
                          <HStack spacing={2} justify="center">
                            <Tooltip label="Modifier">
                              <IconButton
                                icon={<FiEdit />}
                                aria-label="Modifier"
                                size="sm"
                                colorScheme="yellow"
                                onClick={() => openEditModal(dispense)}
                              />
                            </Tooltip>
                            <Tooltip label="Supprimer">
                              <IconButton
                                icon={<FaTrash />}
                                aria-label="Supprimer"
                                size="sm"
                                colorScheme="red"
                                onClick={() => handleDeleteDispense(dispense.id)}
                              />
                            </Tooltip>
                            <Tooltip label="Exporter en PDF">
                              <IconButton
                                icon={<FiDownload />}
                                aria-label="Exporter"
                                size="sm"
                                colorScheme="blue"
                                onClick={() => exportDispenseToPDF(dispense)}
                              />
                            </Tooltip>
                          </HStack>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            ) : (
              <Flex justifyContent="center" alignItems="center" height="200px">
                <Text fontSize="lg" color="gray.500">
                  Aucune dispense trouvée.
                </Text>
              </Flex>
            )}

            {selectedDispense && (
              <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} size="lg">
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Modifier la Dispense</ModalHeader>
                  <ModalCloseButton />
                  <form onSubmit={handleEditFormSubmit}>
                    <ModalBody>
                      <Stack spacing={4}>
                        <FormControl isRequired>
                          <FormLabel>Date de Début</FormLabel>
                          <Input
                            type="date"
                            name="startDate"
                            value={editFormData.startDate}
                            onChange={handleEditFormChange}
                          />
                        </FormControl>
                        <FormControl isRequired>
                          <FormLabel>Date de Fin</FormLabel>
                          <Input
                            type="date"
                            name="endDate"
                            value={editFormData.endDate}
                            onChange={handleEditFormChange}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Nombre de Jours à Prolonger</FormLabel>
                          <Input
                            type="number"
                            name="extendDays"
                            value={editFormData.extendDays}
                            onChange={handleEditFormChange}
                            min="0"
                          />
                        </FormControl>
                        <Checkbox
                          name="requiredParticipation"
                          isChecked={editFormData.requiredParticipation}
                          onChange={handleEditFormChange}
                        >
                          Participation requise
                        </Checkbox>
                      </Stack>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        type="submit"
                        colorScheme="blue"
                        mr={3}
                        isLoading={isFormSubmitting}
                      >
                        Enregistrer
                      </Button>
                      <Button variant="ghost" onClick={() => setIsEditModalOpen(false)}>
                        Annuler
                      </Button>
                    </ModalFooter>
                  </form>
                </ModalContent>
              </Modal>
            )}

            <Modal
              isOpen={isExportModalOpen}
              onClose={() => setIsExportModalOpen(false)}
              size="lg"
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Exporter les Dispenses</ModalHeader>
                <ModalCloseButton />
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    /* Handle Export */
                  }}
                >
                  <ModalBody>
                    <Stack spacing={4}>
                      <FormControl isRequired>
                        <FormLabel>Date de Début</FormLabel>
                        <Input
                          type="date"
                          value={exportStartDate}
                          onChange={(e) => setExportStartDate(e.target.value)}
                        />
                      </FormControl>
                      <FormControl isRequired>
                        <FormLabel>Date de Fin</FormLabel>
                        <Input
                          type="date"
                          value={exportEndDate}
                          onChange={(e) => setExportEndDate(e.target.value)}
                        />
                      </FormControl>
                    </Stack>
                  </ModalBody>
                  <ModalFooter>
                    <Button type="submit" colorScheme="blue" mr={3} isLoading={isExporting}>
                      Exporter
                    </Button>
                    <Button
                      variant="ghost"
                      onClick={() => setIsExportModalOpen(false)}
                      isDisabled={isExporting}
                    >
                      Annuler
                    </Button>
                  </ModalFooter>
                </form>
              </ModalContent>
            </Modal>
          </>
        ) : (
          <Flex justifyContent="center" alignItems="center" height="100%" flexDirection="column">
            <FiUsers size={48} color="#CBD5E0" />
            <Text fontSize="xl" color="gray.500" mt={4}>
              Veuillez sélectionner une classe pour afficher les dispenses.
            </Text>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default DispensesPage;
