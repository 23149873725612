// src/centre/Internship.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Heading,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Input,
  Button,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Spinner,
  IconButton,
  HStack,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { AddIcon, AttachmentIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { getAuth } from 'firebase/auth';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
  addDoc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const Internship = () => {
  const [internships, setInternships] = useState([]);
  const [students, setStudents] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [classes, setClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [newInternshipData, setNewInternshipData] = useState({
    studentId: '',
    companyId: '',
    startDate: '',
    endDate: '',
  });
  const [agreementFile, setAgreementFile] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [editInternshipData, setEditInternshipData] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [internshipToDelete, setInternshipToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const auth = getAuth();
  const toast = useToast();
  const bg = useColorModeValue('brand.white', 'gray.800'); // Utilisation de 'brand.white' pour le fond clair

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const user = auth.currentUser;
    if (user) {
      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const centreId = userData.centreId;

          // Fetch classes
          const classesQuery = query(
            collection(db, 'classes'),
            where('centreId', '==', centreId)
          );
          const classesSnapshot = await getDocs(classesQuery);
          const fetchedClasses = classesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setClasses(fetchedClasses);

          // Get class IDs
          const classIds = fetchedClasses.map((classe) => classe.id);

          // Fetch students in these classes
          let fetchedStudents = [];
          const batchSize = 10;
          for (let i = 0; i < classIds.length; i += batchSize) {
            const batchClassIds = classIds.slice(i, i + batchSize);
            const studentsQuery = query(
              collection(db, 'eleves'),
              where('classeId', 'in', batchClassIds)
            );
            const studentsSnapshot = await getDocs(studentsQuery);
            fetchedStudents.push(
              ...studentsSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
            );
          }
          setStudents(fetchedStudents);

          // Fetch companies
          const companiesQuery = query(
            collection(db, 'companies'),
            where('centreId', '==', centreId)
          );
          const companiesSnapshot = await getDocs(companiesQuery);
          const fetchedCompanies = companiesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCompanies(fetchedCompanies);

          // Fetch internships
          const internshipsQuery = query(
            collection(db, 'internships'),
            where('centreId', '==', centreId)
          );
          const internshipsSnapshot = await getDocs(internshipsQuery);
          const fetchedInternships = internshipsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setInternships(fetchedInternships);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les données.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
    setIsLoading(false);
  }, [auth, toast]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const openCreateModal = () => {
    setNewInternshipData({
      studentId: '',
      companyId: '',
      startDate: '',
      endDate: '',
    });
    setAgreementFile(null);
    setIsCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleCreateFormChange = (e) => {
    const { name, value } = e.target;
    setNewInternshipData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAgreementFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setAgreementFile(e.target.files[0]);
    }
  };

  const handleCreateInternship = async () => {
    if (
      !newInternshipData.studentId ||
      !newInternshipData.companyId ||
      !newInternshipData.startDate ||
      !newInternshipData.endDate ||
      !agreementFile
    ) {
      toast({
        title: 'Erreur',
        description: 'Veuillez remplir tous les champs et joindre la convention signée.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsCreating(true);

    try {
      // Upload agreement file
      const fileExtension = agreementFile.name.split('.').pop();
      const fileName = `convention_${Date.now()}.${fileExtension}`;
      const storageRef = ref(storage, `internshipAgreements/${fileName}`);
      await uploadBytes(storageRef, agreementFile);
      const fileURL = await getDownloadURL(storageRef);

      // Add internship to Firestore
      const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
      const centreId = userDoc.exists() ? userDoc.data().centreId : null;

      await addDoc(collection(db, 'internships'), {
        studentId: newInternshipData.studentId,
        companyId: newInternshipData.companyId,
        startDate: new Date(newInternshipData.startDate),
        endDate: new Date(newInternshipData.endDate),
        agreementURL: fileURL,
        centreId: centreId,
        createdAt: new Date(),
      });

      toast({
        title: 'Succès',
        description: 'Stage ajouté avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      fetchData();
      closeCreateModal();
    } catch (error) {
      console.error('Erreur lors de la création du stage :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de créer le stage.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    setIsCreating(false);
  };

  const openEditModal = (internship) => {
    setEditInternshipData({
      ...internship,
      startDate: internship.startDate.toDate().toISOString().split('T')[0],
      endDate: internship.endDate.toDate().toISOString().split('T')[0],
    });
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditInternshipData(null);
    setIsEditModalOpen(false);
  };

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditInternshipData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditInternship = async () => {
    if (
      !editInternshipData.studentId ||
      !editInternshipData.companyId ||
      !editInternshipData.startDate ||
      !editInternshipData.endDate
    ) {
      toast({
        title: 'Erreur',
        description: 'Veuillez remplir tous les champs.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsProcessing(true);

    try {
      const internshipRef = doc(db, 'internships', editInternshipData.id);

      await updateDoc(internshipRef, {
        studentId: editInternshipData.studentId,
        companyId: editInternshipData.companyId,
        startDate: new Date(editInternshipData.startDate),
        endDate: new Date(editInternshipData.endDate),
      });

      toast({
        title: 'Succès',
        description: 'Stage mis à jour avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      fetchData();
      closeEditModal();
    } catch (error) {
      console.error('Erreur lors de la mise à jour du stage :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de mettre à jour le stage.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    setIsProcessing(false);
  };

  const openDeleteModal = (internship) => {
    setInternshipToDelete(internship);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setInternshipToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const handleDeleteInternship = async () => {
    if (!internshipToDelete) return;

    setIsProcessing(true);

    try {
      await deleteDoc(doc(db, 'internships', internshipToDelete.id));

      toast({
        title: 'Succès',
        description: 'Stage supprimé avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      fetchData();
      closeDeleteModal();
    } catch (error) {
      console.error('Erreur lors de la suppression du stage :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer le stage.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    setIsProcessing(false);
  };

  const formatDate = (date) => {
    if (!date) return 'N/A';
    return date.toDate().toLocaleDateString();
  };

  return (
    <Flex direction="column" p={6} bg={bg} minHeight="100vh">
      <Heading as="h2" size="lg" mb={4} color="brand.blue">
        Gestion des Stages
      </Heading>
      <Button
        leftIcon={<AddIcon />}
        onClick={openCreateModal}
        mb={4}
        alignSelf="flex-start"
        variant="solid" // Utilisation du variant personnalisé
      >
        Ajouter un Stage
      </Button>
      {isLoading ? (
        <Spinner size="xl" alignSelf="center" mt={10} color="brand.blue" />
      ) : (
        <Box overflowX="auto">
          <Table variant="striped" colorScheme="brand">
            <Thead>
              <Tr>
                <Th color="brand.blue">Étudiant</Th>
                <Th color="brand.blue">Entreprise</Th>
                <Th color="brand.blue">Date de début</Th>
                <Th color="brand.blue">Date de fin</Th>
                <Th color="brand.blue">Convention signée</Th>
                <Th color="brand.blue">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {internships.map((internship) => {
                const student = students.find((s) => s.id === internship.studentId);
                const company = companies.find((c) => c.id === internship.companyId);
                return (
                  <Tr key={internship.id}>
                    <Td>{student ? `${student.firstName} ${student.lastName}` : 'N/A'}</Td>
                    <Td>{company ? company.name : 'N/A'}</Td>
                    <Td>{formatDate(internship.startDate)}</Td>
                    <Td>{formatDate(internship.endDate)}</Td>
                    <Td>
                      <IconButton
                        icon={<AttachmentIcon />}
                        onClick={() => window.open(internship.agreementURL, '_blank')}
                        aria-label="Voir la convention"
                        bg="brand.blue"
                        color="brand.white"
                        _hover={{ bg: 'brand.orange' }}
                      />
                    </Td>
                    <Td>
                      <HStack spacing={2}>
                        <IconButton
                          icon={<EditIcon />}
                          bg="brand.orange"
                          color="brand.white"
                          size="sm"
                          onClick={() => openEditModal(internship)}
                          aria-label="Modifier le stage"
                          _hover={{ bg: 'brand.blue' }}
                        />
                        <IconButton
                          icon={<DeleteIcon />}
                          bg="brand.red" // Vous pouvez ajouter 'brand.red' dans votre theme.js si nécessaire
                          color="brand.white"
                          size="sm"
                          onClick={() => openDeleteModal(internship)}
                          aria-label="Supprimer le stage"
                          _hover={{ bg: 'brand.orange' }}
                        />
                      </HStack>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      )}

      {/* Modal pour ajouter un stage */}
      <Modal isOpen={isCreateModalOpen} onClose={closeCreateModal} isCentered>
        <ModalOverlay />
        <ModalContent bg="brand.white" color="brand.blue">
          <ModalHeader>Ajouter un Stage</ModalHeader>
          <ModalBody>
            <FormControl id="studentId" mb={3} isRequired>
              <FormLabel>Étudiant</FormLabel>
              <Select
                name="studentId"
                value={newInternshipData.studentId}
                onChange={handleCreateFormChange}
                placeholder="Sélectionner un étudiant"
                bg="brand.white"
                color="brand.blue"
              >
                {students.map((student) => (
                  <option key={student.id} value={student.id}>
                    {student.firstName} {student.lastName}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl id="companyId" mb={3} isRequired>
              <FormLabel>Entreprise</FormLabel>
              <Select
                name="companyId"
                value={newInternshipData.companyId}
                onChange={handleCreateFormChange}
                placeholder="Sélectionner une entreprise"
                bg="brand.white"
                color="brand.blue"
              >
                {companies.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl id="startDate" mb={3} isRequired>
              <FormLabel>Date de début</FormLabel>
              <Input
                type="date"
                name="startDate"
                value={newInternshipData.startDate}
                onChange={handleCreateFormChange}
                bg="brand.white"
                color="brand.blue"
              />
            </FormControl>
            <FormControl id="endDate" mb={3} isRequired>
              <FormLabel>Date de fin</FormLabel>
              <Input
                type="date"
                name="endDate"
                value={newInternshipData.endDate}
                onChange={handleCreateFormChange}
                bg="brand.white"
                color="brand.blue"
              />
            </FormControl>
            <FormControl id="agreementFile" mb={3} isRequired>
              <FormLabel>Convention signée</FormLabel>
              <Input
                type="file"
                accept="application/pdf"
                onChange={handleAgreementFileChange}
                bg="brand.white"
                color="brand.blue"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="solid" // Utilisation du variant personnalisé
              mr={3}
              onClick={handleCreateInternship}
              isLoading={isCreating}
            >
              Ajouter
            </Button>
            <Button variant="ghost" onClick={closeCreateModal} color="brand.orange">
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal pour éditer un stage */}
      <Modal isOpen={isEditModalOpen} onClose={closeEditModal} isCentered>
        <ModalOverlay />
        <ModalContent bg="brand.white" color="brand.blue">
          <ModalHeader>Modifier le Stage</ModalHeader>
          <ModalBody>
            <FormControl id="studentId" mb={3} isRequired>
              <FormLabel>Étudiant</FormLabel>
              <Select
                name="studentId"
                value={editInternshipData?.studentId || ''}
                onChange={handleEditFormChange}
                placeholder="Sélectionner un étudiant"
                bg="brand.white"
                color="brand.blue"
              >
                {students.map((student) => (
                  <option key={student.id} value={student.id}>
                    {student.firstName} {student.lastName}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl id="companyId" mb={3} isRequired>
              <FormLabel>Entreprise</FormLabel>
              <Select
                name="companyId"
                value={editInternshipData?.companyId || ''}
                onChange={handleEditFormChange}
                placeholder="Sélectionner une entreprise"
                bg="brand.white"
                color="brand.blue"
              >
                {companies.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl id="startDate" mb={3} isRequired>
              <FormLabel>Date de début</FormLabel>
              <Input
                type="date"
                name="startDate"
                value={editInternshipData?.startDate || ''}
                onChange={handleEditFormChange}
                bg="brand.white"
                color="brand.blue"
              />
            </FormControl>
            <FormControl id="endDate" mb={3} isRequired>
              <FormLabel>Date de fin</FormLabel>
              <Input
                type="date"
                name="endDate"
                value={editInternshipData?.endDate || ''}
                onChange={handleEditFormChange}
                bg="brand.white"
                color="brand.blue"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="solid" // Utilisation du variant personnalisé
              mr={3}
              onClick={handleEditInternship}
              isLoading={isProcessing}
            >
              Enregistrer
            </Button>
            <Button variant="ghost" onClick={closeEditModal} color="brand.orange">
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal de confirmation de suppression */}
      <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal} isCentered>
        <ModalOverlay />
        <ModalContent bg="brand.white" color="brand.blue">
          <ModalHeader>Supprimer le Stage</ModalHeader>
          <ModalBody>
            <Text>
              Êtes-vous sûr de vouloir supprimer ce stage ? Cette action est irréversible.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="solid" // Utilisation du variant personnalisé
              bg="brand.red" // Assurez-vous que 'brand.red' est défini dans votre theme.js
              color="brand.white"
              mr={3}
              onClick={handleDeleteInternship}
              isLoading={isProcessing}
              _hover={{ bg: 'brand.orange' }}
            >
              Supprimer
            </Button>
            <Button variant="ghost" onClick={closeDeleteModal} color="brand.orange">
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Internship;
