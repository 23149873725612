// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from './AuthContext'; // Chemin correct
import HomePage from './HomePage';
import OrganismeLogin from './OrganismeLogin';
import OrganismeSignup from './OrganismeSignup';
import CentreLogin from './CentreLogin';
import Dashboard from './organisme/Dashboard';
import ManageClasses from './organisme/ManageClasses';
import ManageCandidates from './organisme/ManageCandidates';
import ManageCentres from './organisme/ManageCentres';
import CreateRole from './organisme/CreateRole';
import CreateCentre from './organisme/CreateCentre';
import CreateUserInCentre from './organisme/CreateUserInCentre';
import Eleve from './organisme/Eleves';
import Formations from './organisme/Formations';
import Financement from './organisme/Financement';
import CentreDashboard from './centre/CentreDashboard';
import ViewAssignedClasses from './centre/ViewAssignedClasses';
import ManageDocuments from './centre/ManageDocuments';
import EleveFile from './centre/EleveFile';
import ViewEleveFile from './centre/ViewEleveFile';
import ManageHours from './centre/ManageHours';
import ManageCourses from './centre/ManageCourses';
import CatalogCentre from './centre/CatalogCentre';
import TrackOrders from './centre/TrackOrders';
import InternOrder from './centre/InternOrder';
import ManageInternOrder from './centre/ManageInternOrder';
import DispensesPage from './centre/DispensesPage';
import PrivateRoute from './PrivateRoute';
import PublicDashboard from './allpublic/PublicDashboard';
import NavigationArrows from './components/NavigationArrows';
import Informations from './centre/Informations';
import Avertissements from './centre/Avertissements';
import Companies from './centre/Companies';
import Internship from './centre/Internship';

// Import du composant OrganismeSettings
import OrganismeSettings from './organisme/OrganismeSettings';

function App() {
  return (
    <ChakraProvider>
      <AuthProvider>
        <Router>
          {/* Composant de Navigation */}
          <NavigationArrows />
          <Routes>
            {/* Routes publiques accessibles à tous */}
            <Route path="/" element={<HomePage />} />
            <Route path="/auth-organisme" element={<OrganismeLogin />} />
            <Route path="/organisme-signup" element={<OrganismeSignup />} /> {/* Route mise à jour */}
            <Route path="/auth-centre" element={<CentreLogin />} />

            {/* Nouvelle route pour PublicDashboard */}
            <Route path="/public-dashboard" element={<PublicDashboard />} />

            {/* Routes Publiques pour les commandes via un lien partagé */}
            <Route path="/internOrder/:centreId" element={<InternOrder />} />
            <Route path="/track-orders/:centreId" element={<TrackOrders />} />

            {/* Routes protégées pour l'organisme */}
            <Route
              path="/dashboard"
              element={
                <PrivateRoute requiredRole="organisme">
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-classes"
              element={
                <PrivateRoute requiredRole="organisme">
                  <ManageClasses />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-candidates"
              element={
                <PrivateRoute requiredRole="organisme">
                  <ManageCandidates />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-centres"
              element={
                <PrivateRoute requiredRole="organisme">
                  <ManageCentres />
                </PrivateRoute>
              }
            />
            <Route
              path="/create-role"
              element={
                <PrivateRoute requiredRole="organisme">
                  <CreateRole />
                </PrivateRoute>
              }
            />
            <Route
              path="/create-centre"
              element={
                <PrivateRoute requiredRole="organisme">
                  <CreateCentre />
                </PrivateRoute>
              }
            />
            <Route
              path="/create-user-in-centre"
              element={
                <PrivateRoute requiredRole="organisme">
                  <CreateUserInCentre />
                </PrivateRoute>
              }
            />
            <Route
              path="/eleves"
              element={
                <PrivateRoute requiredRole="organisme">
                  <Eleve />
                </PrivateRoute>
              }
            />
            <Route
              path="/formations"
              element={
                <PrivateRoute requiredRole="organisme">
                  <Formations />
                </PrivateRoute>
              }
            />
            <Route
              path="/financement"
              element={
                <PrivateRoute requiredRole="organisme">
                  <Financement />
                </PrivateRoute>
              }
            />

            {/* Nouvelle Route pour OrganismeSettings */}
            <Route
              path="/settings"
              element={
                <PrivateRoute requiredRole="organisme">
                  <OrganismeSettings />
                </PrivateRoute>
              }
            />

            {/* Routes protégées pour le centre */}
            <Route
              path="/centre-dashboard"
              element={
                <PrivateRoute requiredRole="centre">
                  <CentreDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-assigned-classes"
              element={
                <PrivateRoute requiredRole="centre">
                  <ViewAssignedClasses />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-documents"
              element={
                <PrivateRoute requiredRole="centre">
                  <ManageDocuments />
                </PrivateRoute>
              }
            />
            <Route
              path="/eleve-file"
              element={
                <PrivateRoute requiredRole="centre">
                  <EleveFile />
                </PrivateRoute>
              }
            />
            <Route
              path="/view-eleve-file/:eleveId"
              element={
                <PrivateRoute requiredRole="centre">
                  <ViewEleveFile />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-hours"
              element={
                <PrivateRoute requiredRole="centre">
                  <ManageHours />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-courses"
              element={
                <PrivateRoute requiredRole="centre">
                  <ManageCourses />
                </PrivateRoute>
              }
            />
            <Route
              path="/catalog-centre"
              element={
                <PrivateRoute requiredRole="centre">
                  <CatalogCentre />
                </PrivateRoute>
              }
            />

            {/* Route Privée pour TrackOrders */}
            <Route
              path="/track-orders"
              element={
                <PrivateRoute requiredRole="centre">
                  <TrackOrders />
                </PrivateRoute>
              }
            />

            {/* Nouvelle Route pour ManageInternOrder */}
            <Route
              path="/manage-intern-order"
              element={
                <PrivateRoute requiredRole="centre">
                  <ManageInternOrder />
                </PrivateRoute>
              }
            />

            {/* Nouvelle Route pour Informations */}
            <Route
              path="/informations"
              element={
                <PrivateRoute requiredRole="centre">
                  <Informations />
                </PrivateRoute>
              }
            />

            {/* Nouvelle Route pour Dispenses */}
            <Route
              path="/dispenses"
              element={
                <PrivateRoute requiredRole="centre">
                  <DispensesPage />
                </PrivateRoute>
              }
            />

            {/* Nouvelle Route pour Avertissements */}
            <Route
              path="/centre/avertissements"
              element={
                <PrivateRoute requiredRole="centre">
                  <Avertissements />
                </PrivateRoute>
              }
            />

            {/* Routes pour les nouvelles fonctionnalités */}
            <Route
              path="/centre/companies"
              element={
                <PrivateRoute requiredRole="centre">
                  <Companies />
                </PrivateRoute>
              }
            />
            <Route
              path="/centre/internship"
              element={
                <PrivateRoute requiredRole="centre">
                  <Internship />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
