// src/organisme/CreateRole.js
import React, { useState } from 'react';
import { Box, Button, Input, Heading, Checkbox, VStack } from '@chakra-ui/react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Import de la configuration Firebase

const CreateRole = () => {
  const [roleName, setRoleName] = useState('');
  const [permissions, setPermissions] = useState({
    manageUsers: false,
    viewReports: false,
    manageDocuments: false,
  });

  const handlePermissionChange = (e) => {
    setPermissions({
      ...permissions,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSubmit = async () => {
    const selectedPermissions = Object.keys(permissions).filter((key) => permissions[key]);
    try {
      const docRef = await addDoc(collection(db, 'roles'), {
        roleName,
        permissions: selectedPermissions,
      });
      console.log('Rôle créé avec succès : ', docRef.id);
      setRoleName('');
      setPermissions({
        manageUsers: false,
        viewReports: false,
        manageDocuments: false,
      });
    } catch (error) {
      console.error('Erreur lors de la création du rôle : ', error);
    }
  };

  return (
    <Box p={8}>
      <Heading as="h2" mb={6}>Créer un rôle</Heading>
      <Input
        placeholder="Nom du rôle"
        value={roleName}
        onChange={(e) => setRoleName(e.target.value)}
        mb={4}
      />
      <VStack spacing={2} align="start">
        <Checkbox name="manageUsers" isChecked={permissions.manageUsers} onChange={handlePermissionChange}>
          Gérer les utilisateurs
        </Checkbox>
        <Checkbox name="viewReports" isChecked={permissions.viewReports} onChange={handlePermissionChange}>
          Voir les rapports
        </Checkbox>
        <Checkbox name="manageDocuments" isChecked={permissions.manageDocuments} onChange={handlePermissionChange}>
          Gérer les documents
        </Checkbox>
      </VStack>
      <Button onClick={handleSubmit} colorScheme="blue" mt={4}>
        Créer le rôle
      </Button>
    </Box>
  );
};

export default CreateRole;
