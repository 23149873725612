import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Button, Flex, FormControl, FormLabel, Input, Textarea, Table, Thead, Tbody, Tr, Th, Td,
  TableContainer, IconButton, useToast, VStack, HStack, Heading, Select, Text, Stat, StatLabel,
  StatNumber, StatHelpText, StatArrow, SimpleGrid, Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Checkbox, CheckboxGroup, Stack, Icon,
} from '@chakra-ui/react';
import { FaTrash, FaFileExcel, FaDownload, FaCheckCircle } from 'react-icons/fa';
import { getDocs, collection, addDoc, updateDoc, deleteDoc, doc, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const monthOrder = {
  Janvier: 1, Février: 2, Mars: 3, Avril: 4, Mai: 5, Juin: 6,
  Juillet: 7, Août: 8, Septembre: 9, Octobre: 10, Novembre: 11, Décembre: 12,
};

const ManageHours = () => {
  const [classes, setClasses] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState('');
  const [selectedPeriodId, setSelectedPeriodId] = useState('');
  const [entries, setEntries] = useState([]);
  const [formData, setFormData] = useState({ matiere: '', volumePrev: '', volumeReel: '', groups: [] });
  const [groups, setGroups] = useState([]);
  const [theoreticalVolume, setTheoreticalVolume] = useState(null);
  const [annualRecap, setAnnualRecap] = useState({ theoreticalVolume: 0, volumePrev: 0, volumeReel: 0 });
  const [probleme, setProbleme] = useState('');
  const [solution, setSolution] = useState('');
  const [isPeriodClosed, setIsPeriodClosed] = useState(false);
  const toast = useToast();
  const fileInputRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const kpiRef = useRef(null);
  const chartRef = useRef(null);
  const annualChartRef = useRef(null);

  // Récupérer les classes depuis Firebase
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const snapshot = await getDocs(collection(db, 'classes'));
        setClasses(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        toast({
          title: 'Erreur de récupération des classes',
          description: error.message,
          status: 'error', duration: 5000, isClosable: true,
        });
      }
    };
    fetchClasses();
  }, [toast]);

  // Récupérer les périodes en fonction de la classe sélectionnée
  useEffect(() => {
    const fetchPeriods = async () => {
      if (!selectedClassId) return setPeriods([]);
      try {
        const q = query(collection(db, 'periods'), where('classId', '==', selectedClassId));
        const snapshot = await getDocs(q);
        const periodList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        periodList.sort((a, b) => (a.year !== b.year ? a.year - b.year : monthOrder[a.month] - monthOrder[b.month]));
        setPeriods(periodList);
      } catch (error) {
        toast({
          title: 'Erreur de récupération des périodes',
          description: error.message,
          status: 'error', duration: 5000, isClosable: true,
        });
      }
    };
    fetchPeriods();
  }, [selectedClassId, toast]);

  // Récupérer les groupes pour la classe sélectionnée
  useEffect(() => {
    const selectedClass = classes.find(cls => cls.id === selectedClassId);
    setGroups(selectedClass ? ['Classe', ...(selectedClass.groups || [])] : []);
  }, [selectedClassId, classes]);

  // Récupérer les entrées pour la classe et la période sélectionnées
  useEffect(() => {
    const fetchEntries = async () => {
      if (!selectedClassId || !selectedPeriodId) {
        setEntries([]);
        setAnnualRecap({ theoreticalVolume: 0, volumePrev: 0, volumeReel: 0 });
        setProbleme('');
        setSolution('');
        setIsPeriodClosed(false);
        return;
      }
      try {
        const q = query(
          collection(db, 'manageHours'),
          where('classId', '==', selectedClassId),
          where('periodId', '==', selectedPeriodId)
        );
        const snapshot = await getDocs(q);
        setEntries(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), groups: doc.data().groups || [] })));

        const selectedPeriod = periods.find(p => p.id === selectedPeriodId);
        if (selectedPeriod) {
          const { year, status, probleme, solution } = selectedPeriod;
          setProbleme(probleme || '');
          setSolution(solution || '');
          setIsPeriodClosed(status === 'Clôturée');

          // Calcul du récapitulatif annuel
          const qAnnualPeriods = query(
            collection(db, 'periods'),
            where('classId', '==', selectedClassId),
            where('year', '==', year)
          );
          const snapshotAnnual = await getDocs(qAnnualPeriods);
          const annualPeriods = snapshotAnnual.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          const totalTheoretical = annualPeriods.reduce((acc, period) => acc + (period.theoreticalVolume || 0), 0);

          const periodIds = annualPeriods.map(p => p.id);
          let annualEntries = [];
          for (let i = 0; i < periodIds.length; i += 10) {
            const chunk = periodIds.slice(i, i + 10);
            const qChunk = query(
              collection(db, 'manageHours'),
              where('classId', '==', selectedClassId),
              where('periodId', 'in', chunk)
            );
            const snapshotChunk = await getDocs(qChunk);
            annualEntries = [...annualEntries, ...snapshotChunk.docs.map(doc => doc.data())];
          }
          const totalPlanned = annualEntries.reduce((acc, entry) => acc + (entry.volumePrev || 0), 0);
          const totalActual = annualEntries.reduce((acc, entry) => acc + (entry.volumeReel || 0), 0);
          setAnnualRecap({ theoreticalVolume: totalTheoretical, volumePrev: totalPlanned, volumeReel: totalActual });
        } else {
          setAnnualRecap({ theoreticalVolume: 0, volumePrev: 0, volumeReel: 0 });
          setIsPeriodClosed(false);
        }
      } catch (error) {
        toast({
          title: 'Erreur de récupération des données',
          description: error.message,
          status: 'error', duration: 5000, isClosable: true,
        });
      }
    };
    fetchEntries();
  }, [selectedClassId, selectedPeriodId, periods, toast]);

  // Calcul des totaux pour le KPI
  const totalPlannedHours = entries.reduce((acc, entry) => acc + (entry.volumePrev || 0), 0);
  const totalActualHours = entries.reduce((acc, entry) => acc + (entry.volumeReel || 0), 0);
  const percentageCompletion = theoreticalVolume ? ((totalActualHours / theoreticalVolume) * 100).toFixed(2) : 0;

  // Gestion du formulaire d'ajout
  const handleInputChange = e => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));

  const handleAddEntry = async () => {
    if (isPeriodClosed) {
      toast({
        title: 'Modification désactivée',
        description: "Cette période est clôturée et ne peut pas être modifiée.",
        status: 'warning', duration: 3000, isClosable: true,
      });
      return;
    }
    const { matiere, volumePrev, volumeReel, groups } = formData;
    if (!matiere || !volumePrev || !volumeReel || groups.length === 0) {
      toast({
        title: 'Champs manquants',
        description: 'Veuillez remplir tous les champs.',
        status: 'warning', duration: 3000, isClosable: true,
      });
      return;
    }
    try {
      const docRef = await addDoc(collection(db, 'manageHours'), {
        matiere,
        volumePrev: parseFloat(volumePrev),
        volumeReel: parseFloat(volumeReel),
        groups,
        classId: selectedClassId,
        periodId: selectedPeriodId,
      });
      setEntries([...entries, { id: docRef.id, matiere, volumePrev: parseFloat(volumePrev), volumeReel: parseFloat(volumeReel), groups }]);
      setFormData({ matiere: '', volumePrev: '', volumeReel: '', groups: [] });
      toast({
        title: 'Succès',
        description: 'Entrée ajoutée avec succès.',
        status: 'success', duration: 3000, isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur',
        description: "Impossible d'ajouter l'entrée.",
        status: 'error', duration: 5000, isClosable: true,
      });
    }
  };

  // Gestion de la suppression
  const handleDelete = async id => {
    if (isPeriodClosed) {
      toast({
        title: 'Modification désactivée',
        description: "Cette période est clôturée et ne peut pas être modifiée.",
        status: 'warning', duration: 3000, isClosable: true,
      });
      return;
    }
    try {
      await deleteDoc(doc(db, 'manageHours', id));
      setEntries(entries.filter(entry => entry.id !== id));
      toast({
        title: 'Supprimé',
        description: 'Entrée supprimée avec succès.',
        status: 'success', duration: 3000, isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur',
        description: "Impossible de supprimer l'entrée.",
        status: 'error', duration: 5000, isClosable: true,
      });
    }
  };

  // Gestion de l'édition
  const handleEdit = async (id, field, value) => {
    if (isPeriodClosed) {
      toast({
        title: 'Modification désactivée',
        description: "Cette période est clôturée et ne peut pas être modifiée.",
        status: 'warning', duration: 3000, isClosable: true,
      });
      return;
    }
    try {
      const updatedValue = ['volumePrev', 'volumeReel'].includes(field) ? parseFloat(value) : value;
      await updateDoc(doc(db, 'manageHours', id), { [field]: updatedValue });
      setEntries(entries.map(entry => (entry.id === id ? { ...entry, [field]: updatedValue } : entry)));
      toast({
        title: 'Modifié',
        description: 'Entrée mise à jour avec succès.',
        status: 'success', duration: 3000, isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur',
        description: "Impossible de mettre à jour l'entrée.",
        status: 'error', duration: 5000, isClosable: true,
      });
    }
  };

  // Gestion de l'import Excel
  const handleImportExcel = e => {
    if (isPeriodClosed) {
      toast({
        title: 'Modification désactivée',
        description: "Cette période est clôturée et ne peut pas être modifiée.",
        status: 'warning', duration: 3000, isClosable: true,
      });
      return;
    }
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = evt => {
      const data = new Uint8Array(evt.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      processImportedData(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  const processImportedData = async data => {
    const expectedHeaders = ['Matière', 'Volume Prévu', 'Volume Réel', 'Groupes'];
    if (!expectedHeaders.every((header, index) => header === data[0][index])) {
      toast({
        title: 'Erreur de format',
        description: 'Le fichier Excel importé ne correspond pas au template attendu.',
        status: 'error', duration: 5000, isClosable: true,
      });
      return;
    }

    const newEntries = data.slice(1).map((row, index) => {
      const [matiere, volumePrev, volumeReel, groupes] = row;
      const parsedVolumePrev = parseFloat(volumePrev);
      const parsedVolumeReel = parseFloat(volumeReel);
      if (!matiere || isNaN(parsedVolumePrev) || isNaN(parsedVolumeReel) || !groupes) {
        toast({
          title: 'Erreur de données',
          description: `Ligne ${index + 2} contient des données invalides.`,
          status: 'error', duration: 5000, isClosable: true,
        });
        return null;
      }
      return {
        matiere,
        volumePrev: parsedVolumePrev,
        volumeReel: parsedVolumeReel,
        groups: groupes.split(',').map(g => g.trim()),
      };
    }).filter(Boolean);

    if (newEntries.length === 0) {
      toast({
        title: 'Aucune donnée valide',
        description: "Aucune donnée valide n'a été importée.",
        status: 'warning', duration: 5000, isClosable: true,
      });
      return;
    }

    try {
      const batchPromises = newEntries.map(entry =>
        addDoc(collection(db, 'manageHours'), {
          ...entry,
          classId: selectedClassId,
          periodId: selectedPeriodId,
        })
      );
      const docRefs = await Promise.all(batchPromises);
      const addedEntries = docRefs.map((docRef, idx) => ({
        id: docRef.id,
        ...newEntries[idx],
        classId: selectedClassId,
        periodId: selectedPeriodId,
      }));
      setEntries([...entries, ...addedEntries]);
      toast({
        title: 'Succès',
        description: 'Données importées avec succès depuis Excel.',
        status: 'success', duration: 5000, isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur',
        description: "Impossible d'importer les données.",
        status: 'error', duration: 5000, isClosable: true,
      });
    }
  };

  // Fonction pour générer le PDF
  const handleGeneratePDF = async () => {
    if (entries.length === 0) {
      toast({
        title: 'Aucune donnée',
        description: "Il n'y a aucune donnée à exporter.",
        status: 'warning', duration: 5000, isClosable: true,
      });
      return;
    }

    const docPDF = new jsPDF();
    docPDF.setFontSize(18);
    docPDF.text('Rapport des Heures', 105, 20, null, null, 'center');

    // Ajouter les informations
    const selectedClass = classes.find(cls => cls.id === selectedClassId);
    const selectedPeriod = periods.find(period => period.id === selectedPeriodId);
    docPDF.setFontSize(12);
    docPDF.text(`Classe: ${selectedClass ? selectedClass.className : ''}`, 10, 30);
    docPDF.text(`Période: ${selectedPeriod ? `${selectedPeriod.month} ${selectedPeriod.year}` : ''}`, 10, 40);
    docPDF.text(`Heures Prévu Totales: ${totalPlannedHours} h`, 10, 50);
    docPDF.text(`Heures Réel Totales: ${totalActualHours} h`, 10, 60);
    docPDF.text(`Complétion: ${percentageCompletion}%`, 10, 70);
    docPDF.text(`Volume Théorique: ${theoreticalVolume || 0} h`, 10, 80);
    docPDF.text(`Problème: ${probleme || 'N/A'}`, 10, 90);
    docPDF.text(`Solution Trouvée: ${solution || 'N/A'}`, 10, 100);

    // Capturer les graphiques
    try {
      const chartCanvas = await html2canvas(chartRef.current, { scale: 2 });
      docPDF.addImage(chartCanvas.toDataURL('image/png'), 'PNG', 10, 110, 190, 80);
      const annualChartCanvas = await html2canvas(annualChartRef.current, { scale: 2 });
      docPDF.addImage(annualChartCanvas.toDataURL('image/png'), 'PNG', 10, 200, 190, 80);
    } catch (error) {
      toast({
        title: 'Erreur',
        description: "Impossible de capturer les visuels pour le PDF.",
        status: 'error', duration: 5000, isClosable: true,
      });
    }

    // Ajouter la table
    docPDF.autoTable({
      head: [['Matière', 'Volume Prévu', 'Volume Réel', 'Groupes']],
      body: entries.map(entry => [
        entry.matiere,
        entry.volumePrev,
        entry.volumeReel,
        entry.groups.join(', '),
      ]),
      startY: 290,
      styles: { halign: 'center' },
      headStyles: { fillColor: [255, 153, 0] },
      theme: 'striped',
    });

    docPDF.save(`Rapport_Heures_${selectedPeriod ? `${selectedPeriod.month}_${selectedPeriod.year}` : ''}.pdf`);
  };

  // Fonction pour définir le volume théorique
  const handleSetTheoreticalVolume = async () => {
    if (!theoreticalVolume || isNaN(theoreticalVolume) || theoreticalVolume <= 0) {
      toast({
        title: 'Valeur invalide',
        description: 'Veuillez entrer un volume théorique valide.',
        status: 'warning', duration: 3000, isClosable: true,
      });
      return;
    }

    try {
      await updateDoc(doc(db, 'periods', selectedPeriodId), {
        theoreticalVolume: parseFloat(theoreticalVolume),
      });
      setTheoreticalVolume(parseFloat(theoreticalVolume));
      onClose();
      toast({
        title: 'Volume Théorique Défini',
        description: 'Le volume théorique a été défini avec succès.',
        status: 'success', duration: 3000, isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur',
        description: "Impossible de définir le volume théorique.",
        status: 'error', duration: 5000, isClosable: true,
      });
    }
  };

  // Fonction pour clôturer ou réactiver la période
  const togglePeriodStatus = async () => {
    if (!selectedPeriodId) return;
    try {
      const newStatus = isPeriodClosed ? 'En cours' : 'Clôturée';
      await updateDoc(doc(db, 'periods', selectedPeriodId), { status: newStatus });
      setIsPeriodClosed(!isPeriodClosed);
      toast({
        title: `Période ${newStatus === 'Clôturée' ? 'Clôturée' : 'Réactivée'}`,
        description: `La période a été ${newStatus === 'Clôturée' ? 'clôturée' : 'réactivée'} avec succès.`,
        status: 'success', duration: 3000, isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur',
        description: `Impossible de ${isPeriodClosed ? 'réactiver' : 'clôturer'} la période.`,
        status: 'error', duration: 5000, isClosable: true,
      });
    }
  };

  // Fonction pour mettre à jour le problème ou la solution
  const handleUpdatePeriodInfo = async (field, value) => {
    if (isPeriodClosed) {
      toast({
        title: 'Modification désactivée',
        description: "Cette période est clôturée et ne peut pas être modifiée.",
        status: 'warning', duration: 3000, isClosable: true,
      });
      return;
    }
    try {
      await updateDoc(doc(db, 'periods', selectedPeriodId), { [field]: value });
      toast({
        title: `${field === 'probleme' ? 'Problème' : 'Solution'} Défini(e)`,
        description: `${field === 'probleme' ? 'Le problème' : 'La solution'} a été défini(e) avec succès.`,
        status: 'success', duration: 3000, isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur',
        description: `Impossible de définir ${field === 'probleme' ? 'le problème' : 'la solution'}.`,
        status: 'error', duration: 5000, isClosable: true,
      });
    }
  };

  // Données pour les graphiques
  const chartData = [
    { name: 'Volume Prévu', Volume: totalPlannedHours, fill: '#82ca9d' },
    { name: 'Volume Réel', Volume: totalActualHours, fill: '#ffc658' },
  ];
  const annualChartData = [
    { name: 'Volume Théorique', Volume: annualRecap.theoreticalVolume, fill: '#8884d8' },
    { name: 'Volume Prévu', Volume: annualRecap.volumePrev, fill: '#82ca9d' },
    { name: 'Volume Réel', Volume: annualRecap.volumeReel, fill: '#ffc658' },
  ];

  return (
    <Flex direction="column" p={6} maxW="1600px" mx="auto">
      <Heading mb={6} textAlign="center">Gestion des Heures</Heading>

      {/* Sélection de la Classe et de la Période */}
      <Flex mb={6} justifyContent="space-between" wrap="wrap" gap={4}>
        <FormControl id="class" maxW="300px">
          <FormLabel>Classe</FormLabel>
          <Select
            placeholder="Sélectionner une classe"
            value={selectedClassId}
            onChange={e => {
              setSelectedClassId(e.target.value);
              setSelectedPeriodId('');
              setTheoreticalVolume(null);
              setAnnualRecap({ theoreticalVolume: 0, volumePrev: 0, volumeReel: 0 });
              setProbleme('');
              setSolution('');
              setIsPeriodClosed(false);
            }}
          >
            {classes.map(cls => (
              <option key={cls.id} value={cls.id}>{cls.className}</option>
            ))}
          </Select>
        </FormControl>
        <FormControl id="period" maxW="300px" isDisabled={!selectedClassId}>
          <FormLabel>Période</FormLabel>
          <Select
            placeholder="Sélectionner une période"
            value={selectedPeriodId}
            onChange={e => {
              setSelectedPeriodId(e.target.value);
              const period = periods.find(p => p.id === e.target.value);
              if (period && period.theoreticalVolume) {
                setTheoreticalVolume(period.theoreticalVolume);
              } else {
                setTheoreticalVolume(null);
                onOpen();
              }
            }}
          >
            {periods.map(period => (
              <option key={period.id} value={period.id}>
                {`${period.month} ${period.year} (${period.status})`}
              </option>
            ))}
          </Select>
        </FormControl>
      </Flex>

      {/* Indicateur de clôture de la période */}
      {isPeriodClosed && (
        <Flex alignItems="center" mt={2}>
          <Text color="green.500" fontWeight="bold" mr={2}>
            Cette période est clôturée
          </Text>
          <Icon as={FaCheckCircle} color="green.500" />
        </Flex>
      )}

      {/* Modal pour définir le volume théorique */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Définir le Volume Théorique</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="theoreticalVolume" isRequired>
              <FormLabel>Volume Théorique Mensuel (heures)</FormLabel>
              <Input
                type="number"
                placeholder="Volume Théorique"
                value={theoreticalVolume || ''}
                onChange={e => setTheoreticalVolume(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleSetTheoreticalVolume}>
              Définir
            </Button>
            <Button variant="ghost" onClick={onClose}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Formulaire pour Problème et Solution */}
      {selectedClassId && selectedPeriodId && (
        <Box mb={6} p={4} borderWidth="1px" borderRadius="md">
          <Heading size="md" mb={4}>Problème et Solution</Heading>
          <VStack spacing={4}>
            <FormControl id="probleme">
              <FormLabel>Problème</FormLabel>
              <Textarea
                placeholder="Décrire le problème rencontré"
                value={probleme}
                onChange={e => setProbleme(e.target.value)}
                isDisabled={isPeriodClosed}
              />
            </FormControl>
            <Button
              colorScheme="blue"
              onClick={() => handleUpdatePeriodInfo('probleme', probleme)}
              isDisabled={isPeriodClosed}
            >
              Enregistrer Problème
            </Button>
            <FormControl id="solution">
              <FormLabel>Solution Trouvée</FormLabel>
              <Textarea
                placeholder="Décrire la solution trouvée"
                value={solution}
                onChange={e => setSolution(e.target.value)}
                isDisabled={isPeriodClosed}
              />
            </FormControl>
            <Button
              colorScheme="green"
              onClick={() => handleUpdatePeriodInfo('solution', solution)}
              isDisabled={isPeriodClosed}
            >
              Enregistrer Solution
            </Button>
          </VStack>
        </Box>
      )}

      {/* Cartes de KPIs */}
      {selectedClassId && selectedPeriodId && (
        <Box ref={kpiRef} mb={6}>
          <SimpleGrid columns={{ base: 1, md: 4 }} spacing={6}>
            <Stat p={4} borderWidth="1px" borderRadius="md">
              <StatLabel>Heures Prévu Totales</StatLabel>
              <StatNumber>{totalPlannedHours} h</StatNumber>
            </Stat>
            <Stat p={4} borderWidth="1px" borderRadius="md">
              <StatLabel>Heures Réel Totales</StatLabel>
              <StatNumber>{totalActualHours} h</StatNumber>
            </Stat>
            <Stat p={4} borderWidth="1px" borderRadius="md">
              <StatLabel>Complétion</StatLabel>
              <StatNumber>{percentageCompletion}%</StatNumber>
              <StatHelpText>
                <StatArrow type={percentageCompletion >= 100 ? 'increase' : 'decrease'} />
                {percentageCompletion >= 100 ? 'Atteint' : 'En cours'}
              </StatHelpText>
            </Stat>
            <Stat p={4} borderWidth="1px" borderRadius="md">
              <StatLabel>Volume Théorique</StatLabel>
              <StatNumber>{theoreticalVolume || 0} h</StatNumber>
            </Stat>
          </SimpleGrid>
          {/* Bouton pour Clôturer ou Réactiver la Période */}
          <Flex justifyContent="flex-end" mt={4}>
            <Button colorScheme={isPeriodClosed ? 'green' : 'red'} onClick={togglePeriodStatus}>
              {isPeriodClosed ? 'Réactiver la Période' : 'Clôturer la Période'}
            </Button>
          </Flex>
        </Box>
      )}

      {/* Graphique de Comparaison */}
      {selectedClassId && selectedPeriodId && (
        <Box ref={chartRef} mb={6} p={4} borderWidth="1px" borderRadius="md">
          <Heading size="md" mb={4}>Comparaison des Heures</Heading>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Volume" fill="#82ca9d" name="Volume Prévu" />
              <Bar dataKey="Volume" fill="#ffc658" name="Volume Réel" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}

      {/* Récapitulatif Annuel */}
      {selectedClassId && selectedPeriodId && (
        <Box ref={annualChartRef} mb={6} p={4} borderWidth="1px" borderRadius="md">
          <Heading size="md" mb={4}>Récapitulatif Annuel</Heading>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={annualChartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Volume" fill="#8884d8" name="Volume Théorique Annuel" />
              <Bar dataKey="Volume" fill="#82ca9d" name="Volume Prévu Annuel" />
              <Bar dataKey="Volume" fill="#ffc658" name="Volume Réel Annuel" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}

      {/* Formulaire d'ajout manuel */}
      {selectedClassId && selectedPeriodId && !isPeriodClosed && (
        <Box mb={6} p={4} borderWidth="1px" borderRadius="md">
          <Heading size="md" mb={4}>Ajouter une Entrée Manuellement</Heading>
          <VStack spacing={4}>
            <FormControl id="matiere" isRequired>
              <FormLabel>Matière</FormLabel>
              <Input
                placeholder="Matière"
                name="matiere"
                value={formData.matiere}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="volumePrev" isRequired>
              <FormLabel>Volume Prévu (heures)</FormLabel>
              <Input
                type="number"
                placeholder="Volume Prévu"
                name="volumePrev"
                value={formData.volumePrev}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="volumeReel" isRequired>
              <FormLabel>Volume Réel (heures)</FormLabel>
              <Input
                type="number"
                placeholder="Volume Réel"
                name="volumeReel"
                value={formData.volumeReel}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="groups" isRequired>
              <FormLabel>Groupes</FormLabel>
              <CheckboxGroup
                colorScheme="green"
                value={formData.groups}
                onChange={value => setFormData(prev => ({ ...prev, groups: value }))}
              >
                <Stack spacing={2}>
                  {groups.map((group, index) => (
                    <Checkbox key={index} value={group}>{group}</Checkbox>
                  ))}
                </Stack>
              </CheckboxGroup>
            </FormControl>
            <Button colorScheme="orange" onClick={handleAddEntry}>Ajouter</Button>
          </VStack>
        </Box>
      )}

      {/* Importation Excel et Export PDF */}
      {selectedClassId && selectedPeriodId && (
        <Flex mb={6} justifyContent="space-between" alignItems="center" wrap="wrap" gap={4}>
          <Heading size="md">Entrées</Heading>
          <HStack>
            <Button
              leftIcon={<FaFileExcel />}
              colorScheme="green"
              onClick={() => fileInputRef.current.click()}
              isDisabled={isPeriodClosed}
            >
              Importer Excel
            </Button>
            <input
              type="file"
              accept=".xlsx, .xls"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleImportExcel}
            />
            <Button
              leftIcon={<FaDownload />}
              colorScheme="blue"
              onClick={handleGeneratePDF}
              isDisabled={entries.length === 0}
            >
              Exporter en PDF
            </Button>
          </HStack>
        </Flex>
      )}

      {/* Table des entrées */}
      {selectedClassId && selectedPeriodId && (
        <TableContainer borderWidth="1px" borderRadius="md" mb={6}>
          <Table variant="simple">
            <Thead bg="orange.100">
              <Tr>
                <Th>Matière</Th>
                <Th>Volume Prévu</Th>
                <Th>Volume Réel</Th>
                <Th>Groupes</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {entries.length > 0 ? (
                entries.map(entry => (
                  <Tr key={entry.id}>
                    <Td>
                      <Input
                        value={entry.matiere}
                        onChange={e => handleEdit(entry.id, 'matiere', e.target.value)}
                        placeholder="Matière"
                        isReadOnly={isPeriodClosed}
                      />
                    </Td>
                    <Td>
                      <Input
                        type="number"
                        value={entry.volumePrev}
                        onChange={e => handleEdit(entry.id, 'volumePrev', e.target.value)}
                        placeholder="Volume Prévu"
                        isReadOnly={isPeriodClosed}
                      />
                    </Td>
                    <Td>
                      <Input
                        type="number"
                        value={entry.volumeReel}
                        onChange={e => handleEdit(entry.id, 'volumeReel', e.target.value)}
                        placeholder="Volume Réel"
                        isReadOnly={isPeriodClosed}
                      />
                    </Td>
                    <Td>{entry.groups.join(', ')}</Td>
                    <Td>
                      <IconButton
                        aria-label="Supprimer"
                        icon={<FaTrash />}
                        colorScheme="red"
                        onClick={() => handleDelete(entry.id)}
                        isDisabled={isPeriodClosed}
                      />
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="5" textAlign="center">
                    <Text>Aucune donnée disponible.</Text>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Flex>
  );
};

export default ManageHours;
