// src/organisme/CreateCentre.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Input,
  Heading,
  Text,
  VStack,
  Spinner,
  Alert,
  AlertIcon,
  Flex,
} from '@chakra-ui/react';
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { db } from '../firebase';

const CreateCentre = ({ organismeId, onCentreCreated }) => {
  const [centreName, setCentreName] = useState('');
  const [centres, setCentres] = useState([]);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingCentres, setIsFetchingCentres] = useState(false);

  useEffect(() => {
    const fetchCentres = async () => {
      if (!organismeId) return;

      setIsFetchingCentres(true);
      setError('');

      try {
        const centresQuery = query(
          collection(db, 'centres'),
          where('organismeId', '==', organismeId)
        );
        const centresSnapshot = await getDocs(centresQuery);
        setCentres(
          centresSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      } catch (error) {
        console.error('Erreur lors de la récupération des centres : ', error);
        setError('Erreur lors de la récupération des centres.');
      } finally {
        setIsFetchingCentres(false);
      }
    };

    fetchCentres();
  }, [organismeId]);

  const validateCentreName = (name) => {
    if (!name.trim()) {
      return 'Le nom du centre ne peut pas être vide.';
    }
    if (name.length < 3) {
      return 'Le nom du centre doit contenir au moins 3 caractères.';
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Empêche le rechargement de la page
    setError('');
    setSuccessMessage('');

    const validationError = validateCentreName(centreName);
    if (validationError) {
      setError(validationError);
      return;
    }

    if (!organismeId) {
      setError("Identifiant de l'organisme non disponible. Impossible de créer un centre.");
      return;
    }

    setIsLoading(true);

    try {
      const centreData = {
        centreName: centreName.trim(),
        organismeId,
        createdAt: new Date(),
      };

      const docRef = await addDoc(collection(db, 'centres'), centreData);
      console.log('Centre créé avec succès : ', docRef.id);
      setSuccessMessage('Centre créé avec succès.');
      setCentreName('');

      // Rafraîchir la liste des centres après la création
      setCentres((prevCentres) => [...prevCentres, { id: docRef.id, ...centreData }]);

      // Appeler la fonction de rappel pour informer `OrganismeSettings` de la création
      if (onCentreCreated) {
        onCentreCreated();
      }
    } catch (error) {
      console.error('Erreur lors de la création du centre : ', error);
      setError('Erreur lors de la création du centre. Veuillez réessayer.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  return (
    <Box>
      <Heading as="h3" size="md" mb={4}>
        Créer un Centre
      </Heading>

      {error && (
        <Alert status="error" mb={4} borderRadius="md">
          <AlertIcon />
          {error}
        </Alert>
      )}

      {successMessage && (
        <Alert status="success" mb={4} borderRadius="md">
          <AlertIcon />
          {successMessage}
        </Alert>
      )}

      <VStack as="form" spacing={4} align="stretch" onSubmit={handleSubmit}>
        <Input
          placeholder="Nom du centre"
          value={centreName}
          onChange={(e) => setCentreName(e.target.value)}
          onKeyPress={handleKeyPress}
          focusBorderColor="blue.500"
          aria-label="Nom du centre"
        />
        <Button
          type="submit"
          colorScheme="blue"
          isLoading={isLoading}
          loadingText="Création..."
        >
          Créer le centre
        </Button>
      </VStack>

      <Heading as="h4" size="sm" mt={10} mb={4}>
        Centres Rattachés
      </Heading>

      {isFetchingCentres ? (
        <Flex justify="center" align="center">
          <Spinner size="lg" />
        </Flex>
      ) : centres.length > 0 ? (
        <VStack spacing={3} align="stretch">
          {centres.map((centre) => (
            <Box
              key={centre.id}
              p={4}
              borderWidth="1px"
              borderRadius="md"
              bg="gray.50"
              _hover={{ bg: 'gray.100' }}
            >
              <Text>
                <strong>Nom :</strong> {centre.centreName}
              </Text>
              <Text fontSize="sm" color="gray.600">
                Créé le : {centre.createdAt.toDate().toLocaleDateString()}
              </Text>
            </Box>
          ))}
        </VStack>
      ) : (
        <Text>Aucun centre rattaché à votre organisme.</Text>
      )}
    </Box>
  );
};

export default CreateCentre;
