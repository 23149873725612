// src/centre/ViewAssignedClasses.js

import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Heading, 
  VStack, 
  Text, 
  Flex, 
  Divider, 
  Table, 
  Thead, 
  Tbody, 
  Tr, 
  Th, 
  Td,
  useToast,
  useColorMode,
  Button,
  Input,
  FormControl,
  FormLabel,
  IconButton,
  Spinner,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from '@chakra-ui/react';
import { FaPlus, FaTrash, FaBars } from 'react-icons/fa';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, query, where, collection, getDocs, addDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const ViewAssignedClasses = () => {
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [eleves, setEleves] = useState([]);
  const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [allStudentDocuments, setAllStudentDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const auth = getAuth();
  const toast = useToast();
  const { colorMode } = useColorMode();
  
  // Disclosures pour Drawer et Modals
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  
  // États pour l'ajout de documents requis
  const [isAddRequiredDocModalOpen, setIsAddRequiredDocModalOpen] = useState(false);
  const [newRequiredDocName, setNewRequiredDocName] = useState('');
  const [isAddingRequiredDoc, setIsAddingRequiredDoc] = useState(false);

  // Fonction pour télécharger le tableau en PDF
  const handleDownloadPdf = () => {
    const input = document.getElementById('table-to-pdf');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('l', 'pt', 'a4'); // 'l' pour paysage
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save(`rapport_${selectedClass.className}.pdf`);
      })
      .catch((error) => {
        console.error('Erreur lors de la génération du PDF :', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de générer le PDF.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  // Fonction pour formater les dates de manière sécurisée
  const formatDateSafe = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return 'N/A';
    const date = timestamp.toDate();
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth()+1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };

  // Fonction pour récupérer les classes assignées au centre
  const fetchClasses = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      setIsLoading(true);
      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();

          // Requête pour récupérer les classes assignées au centre
          const classesQuery = query(
            collection(db, 'classes'), 
            where('centreId', '==', userData.centreId)
          );
          const classesSnapshot = await getDocs(classesQuery);
          const fetchedClasses = classesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setClasses(fetchedClasses);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des classes :", error);
        toast({
          title: 'Erreur',
          description: "Impossible de récupérer les classes.",
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, [auth, toast]);

  // Fonction pour récupérer les élèves rattachés à une classe
  const fetchElevesByClass = useCallback(async (classeId) => {
    setIsLoading(true);
    try {
      const elevesQuery = query(
        collection(db, 'eleves'),
        where('classeId', '==', classeId)
      );
      const elevesSnapshot = await getDocs(elevesQuery);
      const fetchedEleves = elevesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEleves(fetchedEleves);
    } catch (error) {
      console.error("Erreur lors de la récupération des élèves :", error);
      toast({
        title: 'Erreur',
        description: "Impossible de récupérer les élèves.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [toast]);

  // Fonction pour récupérer les documents requis pour la classe
  const fetchRequiredDocuments = useCallback(async (classeId) => {
    try {
      const docsQuery = query(
        collection(db, 'requiredDocuments'),
        where('classeId', '==', classeId)
      );
      const docsSnapshot = await getDocs(docsQuery);
      const fetchedDocuments = docsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRequiredDocuments(fetchedDocuments);
    } catch (error) {
      console.error('Erreur lors de la récupération des documents requis :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer les documents requis.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  // Fonction pour récupérer tous les documents des élèves de la classe
  const fetchAllStudentDocuments = useCallback(async (classeId) => {
    setIsLoading(true);
    try {
      const elevesQuery = query(
        collection(db, 'eleves'),
        where('classeId', '==', classeId)
      );
      const elevesSnapshot = await getDocs(elevesQuery);
      const fetchedEleves = elevesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEleves(fetchedEleves);

      // Récupérer tous les documents des élèves de la classe
      const studentIds = fetchedEleves.map((eleve) => eleve.id);

      // En raison de la limitation de Firestore, nous devons faire plusieurs requêtes si nécessaire
      const studentDocs = [];
      const batchSize = 10;
      for (let i = 0; i < studentIds.length; i += batchSize) {
        const batchIds = studentIds.slice(i, i + batchSize);
        const studentDocsQuery = query(
          collection(db, 'studentDocuments'),
          where('studentId', 'in', batchIds)
        );
        const studentDocsSnapshot = await getDocs(studentDocsQuery);
        studentDocs.push(...studentDocsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })));
      }

      setAllStudentDocuments(studentDocs);
      setIsLoading(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des documents des élèves :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer les documents des élèves.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  }, [toast]);

  // Effet pour récupérer les classes au montage du composant
  useEffect(() => {
    fetchClasses();
  }, [fetchClasses]);

  // Fonction pour sélectionner une classe et récupérer les élèves et documents associés
  const handleSelectClass = (classe) => {
    setSelectedClass(classe);
    fetchElevesByClass(classe.id);
    fetchRequiredDocuments(classe.id);
    fetchAllStudentDocuments(classe.id);
    onDrawerClose();
  };

  // Fonctions pour gérer l'ajout de documents requis
  const openAddRequiredDocModal = () => {
    setNewRequiredDocName('');
    setIsAddRequiredDocModalOpen(true);
  };

  const closeAddRequiredDocModal = () => {
    setIsAddRequiredDocModalOpen(false);
    setNewRequiredDocName('');
  };

  const handleNewRequiredDocNameChange = (e) => {
    setNewRequiredDocName(e.target.value);
  };

  const handleAddRequiredDocument = async () => {
    if (!newRequiredDocName.trim()) {
      toast({
        title: 'Erreur',
        description: 'Veuillez entrer un nom pour le document requis.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsAddingRequiredDoc(true);

    try {
      // Ajouter le document requis à Firestore
      await addDoc(collection(db, 'requiredDocuments'), {
        name: newRequiredDocName.trim(),
        classeId: selectedClass.id,
      });

      toast({
        title: 'Succès',
        description: 'Document requis ajouté avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      // Rafraîchir la liste des documents requis
      fetchRequiredDocuments(selectedClass.id);

      closeAddRequiredDocModal();
    } catch (error) {
      console.error("Erreur lors de l'ajout du document requis :", error);
      toast({
        title: 'Erreur',
        description: "Impossible d'ajouter le document requis.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsAddingRequiredDoc(false);
    }
  };

  return (
    <Flex minHeight="100vh" bg={colorMode === 'light' ? 'gray.100' : 'gray.800'}>
      
      {/* Bouton Hamburger pour ouvrir le Drawer sur mobile */}
      <IconButton
        aria-label="Ouvrir le menu"
        icon={<FaBars />}
        display={{ base: 'flex', md: 'none' }}
        position="fixed"
        top="4"
        left="4"
        zIndex="overlay"
        onClick={onDrawerOpen}
        colorScheme="blue" // Utilisation de couleur du thème
      />

      {/* Sidebar Drawer pour mobile */}
      <Drawer
        isOpen={isDrawerOpen}
        placement="left"
        onClose={onDrawerClose}
        size="xs"
      >
        <DrawerOverlay>
          <DrawerContent bg={colorMode === 'light' ? 'brand.white' : 'gray.900'} color={colorMode === 'light' ? 'brand.blue' : 'brand.white'}>
            <DrawerCloseButton />
            <DrawerHeader>Gestion des Classes</DrawerHeader>
            <DrawerBody>
              <VStack spacing={4} align="stretch">
                <Divider my={4} borderColor={colorMode === 'light' ? 'gray.200' : 'gray.700'} />
                <Heading as="h4" size="sm" color={colorMode === 'light' ? 'brand.blue' : 'brand.white'}>Classes Assignées</Heading>
                {isLoading ? (
                  <Spinner color="brand.blue" />
                ) : (
                  classes.length > 0 ? (
                    classes.map(cls => (
                      <Box 
                        key={cls.id} 
                        p={3} 
                        bg={selectedClass?.id === cls.id ? (colorMode === 'light' ? 'brand.blue' : 'brand.blue') : (colorMode === 'light' ? 'brand.gray.100' : 'brand.gray.700')} 
                        borderRadius="md" 
                        cursor="pointer" 
                        onClick={() => handleSelectClass(cls)}
                        _hover={{ opacity: 0.8 }}
                      >
                        <Text>{cls.className}</Text>
                      </Box>
                    ))
                  ) : (
                    <Text>Aucune classe assignée.</Text>
                  )
                )}
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      {/* Sidebar permanente sur écrans larges */}
      <Box 
        width={{ base: '0', md: '250px' }} 
        display={{ base: 'none', md: 'block' }} 
        bg={colorMode === 'light' ? 'brand.blue' : 'brand.blue'} 
        color="brand.white" 
        p={6} 
        boxShadow="md"
      >
        <Heading as="h3" size="md" mb={6}>Gestion des Classes</Heading>
        <VStack spacing={4} align="stretch">
          <Divider my={6} borderColor="brand.white" />
          <Heading as="h4" size="sm">Classes Assignées</Heading>
          {isLoading ? (
            <Spinner color="brand.white" />
          ) : (
            classes.length > 0 ? (
              classes.map(cls => (
                <Box 
                  key={cls.id} 
                  p={3} 
                  bg={selectedClass?.id === cls.id ? 'brand.orange' : 'brand.blue'} 
                  borderRadius="md" 
                  cursor="pointer" 
                  onClick={() => handleSelectClass(cls)}
                  _hover={{ opacity: 0.8 }}
                >
                  <Text>{cls.className}</Text>
                </Box>
              ))
            ) : (
              <Text>Aucune classe assignée.</Text>
            )
          )}
        </VStack>
      </Box>

      {/* Contenu Principal */}
      <Box 
        flex="1" 
        p={6} 
        ml={{ base: '0', md: '250px' }} 
        bg={colorMode === 'light' ? 'gray.100' : 'gray.800'} 
        color={colorMode === 'light' ? 'brand.blue' : 'brand.white'}
      >
        {isLoading && (
          <Flex justify="center" align="center" mb={4}>
            <Spinner size="lg" color="brand.blue" />
          </Flex>
        )}
        {selectedClass ? (
          <Box>
            <Heading as="h2" size="lg" mb={4} color="brand.blue">{selectedClass.className}</Heading>

            {/* Section pour Afficher les Élèves et Documents */}
            <Box mt={6}>
              <Flex justify="space-between" align="center" mb={4}>
                <Heading as="h3" size="md" color="brand.blue">Élèves et Documents</Heading>
                <Box>
                  <Button 
                    leftIcon={<FaPlus />} 
                    colorScheme="teal" 
                    onClick={openAddRequiredDocModal}
                    mr={2}
                    variant="solid" // Utilisation du variant personnalisé
                  >
                    Ajouter Document Requis
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={handleDownloadPdf}
                    variant="solid" // Utilisation du variant personnalisé
                  >
                    Télécharger en PDF
                  </Button>
                </Box>
              </Flex>
              {eleves.length > 0 ? (
                <div id="table-to-pdf">
                  <Table variant="striped" colorScheme={colorMode === 'light' ? 'gray' : 'whiteAlpha'}>
                    <Thead>
                      <Tr>
                        <Th color="brand.blue">Nom de l'élève</Th>
                        {requiredDocuments.map((doc) => (
                          <Th key={doc.id} color="brand.blue">{doc.name}</Th>
                        ))}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {eleves.map((eleve) => {
                        const studentDocs = allStudentDocuments.filter(
                          (sd) => sd.studentId === eleve.id
                        );
                        return (
                          <Tr key={eleve.id}>
                            <Td color="brand.blue">{`${eleve.lastName} ${eleve.firstName}`}</Td>
                            {requiredDocuments.map((doc) => {
                              const hasUploaded = studentDocs.some(
                                (sd) => sd.documentId === doc.id
                              );
                              return (
                                <Td key={doc.id} color={hasUploaded ? 'brand.green' : 'brand.red'}>
                                  {hasUploaded ? '✓' : '✗'}
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </div>
              ) : (
                <Text color="brand.red">Aucun élève assigné à cette classe.</Text>
              )}
            </Box>
          </Box>
        ) : (
          <Flex justify="center" align="center" height="100%">
            <Text fontSize="xl" color="brand.gray.500">Veuillez sélectionner une classe pour voir les détails.</Text>
          </Flex>
        )}
      </Box>

      {/* Modal pour Ajouter un Document Requis */}
      <Modal
        isOpen={isAddRequiredDocModalOpen}
        onClose={closeAddRequiredDocModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent bg={colorMode === 'light' ? 'brand.white' : 'gray.700'} color={colorMode === 'light' ? 'brand.blue' : 'brand.white'}>
          <ModalHeader>Ajouter un Document Requis</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="requiredDocName" isRequired>
              <FormLabel>Nom du Document</FormLabel>
              <Input
                type="text"
                value={newRequiredDocName}
                onChange={handleNewRequiredDocNameChange}
                placeholder="Ex : Certificat Médical"
                bg={colorMode === 'light' ? 'brand.white' : 'gray.600'}
                color={colorMode === 'light' ? 'brand.blue' : 'brand.white'}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button 
              variant="ghost" 
              mr={3} 
              onClick={closeAddRequiredDocModal} 
              color="brand.orange"
            >
              Annuler
            </Button>
            <Button 
              colorScheme="teal" 
              onClick={handleAddRequiredDocument} 
              isLoading={isAddingRequiredDoc}
              variant="solid" // Utilisation du variant personnalisé
            >
              Ajouter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Flex>
  );
};

export default ViewAssignedClasses;
