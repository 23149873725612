// src/CentreLogin.js
import React, { useState } from 'react';
import { Button, Input, Box, Heading, Text, Flex, Icon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { db } from './firebase'; // Assurez-vous que Firebase est bien configuré
import { FaLock, FaEnvelope, FaSchool } from 'react-icons/fa'; // Ajout de FaSchool

const CentreLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!email || !password) {
      setErrorMessage('Tous les champs sont obligatoires.');
      return;
    }

    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.role === 'centre') {
          navigate('/centre-dashboard');
        } else {
          setErrorMessage('Accès refusé. Ce compte n\'est pas un compte centre.');
        }
      } else {
        setErrorMessage('Utilisateur non trouvé.');
      }
    } catch (error) {
      setErrorMessage('Erreur lors de la connexion : ' + error.message);
    }
  };

  return (
    <Flex direction="column" align="center" justify="center" height="100vh" 
          bgImage="url('/cours.jpg')" 
          bgSize="cover" 
          bgPosition="center">
      <Box p={8} bg="white" borderRadius="lg" shadow="lg" width="400px">
        <Heading as="h2" mb={6} textAlign="center" color="gray.700">
          <Icon as={FaSchool} w={6} h={6} color="purple.500" /> Connexion Centre
        </Heading>

        {errorMessage && (
          <Text color="red.500" mb={4} textAlign="center">
            {errorMessage}
          </Text>
        )}

        <Input
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          mb={4}
          focusBorderColor="purple.500"
          leftElement={<Icon as={FaEnvelope} color="gray.400" />}
        />
        <Input
          placeholder="Mot de passe"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          mb={4}
          focusBorderColor="purple.500"
          leftElement={<Icon as={FaLock} color="gray.400" />}
        />
        <Button onClick={handleLogin} colorScheme="purple" size="lg" width="100%">
          Se connecter
        </Button>
      </Box>
    </Flex>
  );
};

export default CentreLogin;
