// src/centre/EleveFile.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Text,
  VStack,
  Flex,
  Button,
  Stack,
  Input,
  HStack,
  Avatar,
  IconButton,
  Spinner,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Image,
  Select,
} from '@chakra-ui/react';
import { ViewIcon, DeleteIcon, AddIcon } from '@chakra-ui/icons';
import { getAuth } from 'firebase/auth';
import {
  doc,
  getDoc,
  query,
  where,
  collection,
  getDocs,
  updateDoc,
  addDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { FiUsers, FiSearch } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const EleveFile = () => {
  // États existants...
  const [eleves, setEleves] = useState([]);
  const [filteredEleves, setFilteredEleves] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [centreName, setCentreName] = useState('');
  const [studentDispenses, setStudentDispenses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allStudentDocuments, setAllStudentDocuments] = useState([]);
  const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [competences, setCompetences] = useState([]);

  const auth = getAuth();
  const toast = useToast();
  const navigate = useNavigate();

  // Utiliser les couleurs du thème
  const bg = useColorModeValue('brand.white', 'brand.blue');
  const tableBg = useColorModeValue('brand.white', 'brand.blue');
  const headerBg = 'brand.blue'; // Utilisation directe de la couleur du thème
  const headerColor = 'brand.white';
  const badgeSuccess = 'brand.orange';
  const badgeWarning = 'red.500'; // Maintien de 'red' pour les avertissements
  const tagColor = useColorModeValue('gray.200', 'gray.600');

  const formatDateSafe = (timestamp) => {
    if (!timestamp || !timestamp.toDate) return 'N/A';
    const date = timestamp.toDate();
    return `${date.getDate().toString().padStart(2, '0')}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;
  };

  // Fonction pour sanitiser les noms de fichiers et de dossiers
  const sanitizeFileName = (str) => {
    return str.replace(/[^a-z0-9_\-\.]/gi, '_').toLowerCase();
  };

  const fetchCentreData = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCentreName(userData.centreName || 'Centre de Formation');

          const classesQuery = query(
            collection(db, 'classes'),
            where('centreId', '==', userData.centreId)
          );
          const classesSnapshot = await getDocs(classesQuery);
          const fetchedClasses = classesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setClasses(fetchedClasses);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du centre :', error);
        toast({
          title: 'Erreur',
          description: "Impossible de récupérer les informations du centre.",
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [auth, toast]);

  const fetchElevesByClass = useCallback(
    async (classeId) => {
      setIsLoading(true);
      try {
        const elevesQuery = query(
          collection(db, 'eleves'),
          where('classeId', '==', classeId)
        );
        const elevesSnapshot = await getDocs(elevesQuery);
        const fetchedEleves = elevesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Trier les élèves par ordre alphabétique (selon le nom)
        fetchedEleves.sort((a, b) => a.lastName.localeCompare(b.lastName));

        setEleves(fetchedEleves);
        setFilteredEleves(fetchedEleves);

        // Récupérer tous les documents des élèves de la classe
        const studentIds = fetchedEleves.map((eleve) => eleve.id);

        // En raison de la limitation de Firestore, nous devons faire plusieurs requêtes si nécessaire
        const studentDocs = [];
        const batchSize = 10;
        for (let i = 0; i < studentIds.length; i += batchSize) {
          const batchIds = studentIds.slice(i, i + batchSize);
          const studentDocsQuery = query(
            collection(db, 'studentDocuments'),
            where('studentId', 'in', batchIds)
          );
          const studentDocsSnapshot = await getDocs(studentDocsQuery);
          studentDocs.push(
            ...studentDocsSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
          );
        }

        setAllStudentDocuments(studentDocs);

        setIsLoading(false);
      } catch (error) {
        console.error('Erreur lors de la récupération des élèves :', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les élèves.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    },
    [toast]
  );

  const fetchRequiredDocuments = useCallback(
    async (classeId) => {
      try {
        const docsQuery = query(
          collection(db, 'requiredDocuments'),
          where('classeId', '==', classeId)
        );
        const docsSnapshot = await getDocs(docsQuery);
        const fetchedDocuments = docsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRequiredDocuments(fetchedDocuments);
      } catch (error) {
        console.error('Erreur lors de la récupération des documents requis :', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les documents requis.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [toast]
  );

  // Fonction pour récupérer les compétences de la classe de l'élève
  const fetchCompetencesForClass = useCallback(async (classeId) => {
    try {
      // Récupérer la classe
      const classDocRef = doc(db, 'classes', classeId);
      const classDoc = await getDoc(classDocRef);
      if (classDoc.exists()) {
        const classData = classDoc.data();
        const formationId = classData.formationId;
        if (formationId) {
          // Récupérer la formation
          const formationDocRef = doc(db, 'formations', formationId);
          const formationDoc = await getDoc(formationDocRef);
          if (formationDoc.exists()) {
            const formationData = formationDoc.data();
            const competencies = formationData.competences || [];
            setCompetences(competencies);
          } else {
            console.error('Formation introuvable');
            setCompetences([]);
          }
        } else {
          console.error("Aucun formationId dans la classe");
          setCompetences([]);
        }
      } else {
        console.error('Classe introuvable');
        setCompetences([]);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des compétences :', error);
      setCompetences([]);
    }
  }, []);

  const handleSelectClass = (classe) => {
    setSelectedClass(classe);
    setEleves([]);
    setFilteredEleves([]);
    setAllStudentDocuments([]);
    setRequiredDocuments([]);
    setCompetences([]);
    fetchElevesByClass(classe.id);
    fetchRequiredDocuments(classe.id);
    fetchCompetencesForClass(classe.id);
  };

  // Nouvelle fonction pour naviguer vers la page ViewEleveFile
  const handleViewEleve = (eleve) => {
    navigate(`/view-eleve-file/${eleve.id}`);
  };

  // Nouvelle fonction pour supprimer un élève directement depuis EleveFile
  const handleDeleteEleve = async (eleve) => {
    if (
      window.confirm(
        `Êtes-vous sûr de vouloir supprimer l'élève ${eleve.firstName} ${eleve.lastName}?`
      )
    ) {
      try {
        await deleteDoc(doc(db, 'eleves', eleve.id));
        toast({
          title: 'Succès',
          description: 'Élève supprimé avec succès.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        // Rafraîchir la liste des élèves
        if (selectedClass) {
          fetchElevesByClass(selectedClass.id);
        }
      } catch (error) {
        console.error("Erreur lors de la suppression de l'élève :", error);
        toast({
          title: 'Erreur',
          description: "Impossible de supprimer l'élève.",
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleSearch = (e) => {
    const queryText = e.target.value.toLowerCase();
    setSearchQuery(queryText);
    const filtered = eleves.filter((eleve) =>
      `${eleve.lastName} ${eleve.firstName}`.toLowerCase().includes(queryText)
    );
    setFilteredEleves(filtered);
  };

  useEffect(() => {
    fetchCentreData();
  }, [fetchCentreData]);

  // États et fonctions pour le modal d'ajout d'élève
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [newEleveData, setNewEleveData] = useState({
    civilite: '',
    firstName: '',
    lastName: '',
    email: '',
    dateOfBirth: '',
    placeOfBirth: '',
    adresse: '',
    complementAdresse: '',
    codePostal: '',
    ville: '',
  });
  const [newElevePhoto, setNewElevePhoto] = useState(null);
  const [isAdding, setIsAdding] = useState(false);

  const openAddModal = () => setIsAddModalOpen(true);
  const closeAddModal = () => {
    setIsAddModalOpen(false);
    setNewEleveData({
      civilite: '',
      firstName: '',
      lastName: '',
      email: '',
      dateOfBirth: '',
      placeOfBirth: '',
      adresse: '',
      complementAdresse: '',
      codePostal: '',
      ville: '',
    });
    setNewElevePhoto(null);
  };

  const handleAddFormChange = (e) => {
    const { name, value } = e.target;
    setNewEleveData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhotoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setNewElevePhoto(e.target.files[0]);
    }
  };

  const handleAddEleve = async () => {
    const {
      civilite,
      firstName,
      lastName,
      email,
      dateOfBirth,
      placeOfBirth,
      adresse,
      complementAdresse,
      codePostal,
      ville,
    } = newEleveData;

    // Validation des champs requis
    if (
      !civilite ||
      !firstName ||
      !lastName ||
      !email ||
      !dateOfBirth ||
      !placeOfBirth ||
      !adresse ||
      !codePostal ||
      !ville
    ) {
      toast({
        title: 'Erreur',
        description: 'Veuillez remplir tous les champs obligatoires.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsAdding(true);

    try {
      // Préparer les données de l'élève
      const eleveData = {
        civilite,
        firstName,
        lastName,
        email,
        dateOfBirth: new Date(dateOfBirth),
        placeOfBirth,
        adresse,
        complementAdresse: complementAdresse || '',
        codePostal,
        ville,
        classeId: selectedClass.id,
        createdAt: new Date(),
      };

      // Ajouter l'élève à Firestore
      const eleveRef = await addDoc(collection(db, 'eleves'), eleveData);

      // Si une photo est téléchargée, la stocker dans Firebase Storage
      if (newElevePhoto) {
        const photoExtension = newElevePhoto.name.split('.').pop();
        const photoFileName = `photo.${photoExtension}`;
        const folderName = `${eleveRef.id}_${sanitizeFileName(
          lastName
        )}_${sanitizeFileName(firstName)}`;
        const photoStorageRef = ref(
          storage,
          `photos/${folderName}/${photoFileName}`
        );
        await uploadBytes(photoStorageRef, newElevePhoto);
        const photoURL = await getDownloadURL(photoStorageRef);

        // Mettre à jour l'élève avec l'URL de la photo
        await updateDoc(eleveRef, { photoURL });
      }

      toast({
        title: 'Succès',
        description: 'Élève ajouté avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      // Rafraîchir la liste des élèves
      if (selectedClass) {
        fetchElevesByClass(selectedClass.id);
      }

      // Fermer le modal
      closeAddModal();
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'élève :", error);
      toast({
        title: 'Erreur',
        description: "Impossible d'ajouter l'élève.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsAdding(false);
    }
  };

  return (
    <Flex minHeight="100vh" bg={bg}>
      {/* Barre latérale */}
      <Box
        width={['100%', '250px']}
        bg="brand.blue"
        color="brand.white"
        p={6}
        overflowY="auto"
      >
        <Heading as="h3" size="md" mb={6} textAlign="center">
          Classes
        </Heading>
        <VStack spacing={4} align="stretch">
          {classes.length > 0 ? (
            classes.map((cls) => (
              <Button
                key={cls.id}
                variant={selectedClass?.id === cls.id ? 'solid' : 'ghost'}
                colorScheme="brand" // Utilisation de la couleur personnalisée
                justifyContent="flex-start"
                leftIcon={<FiUsers />}
                onClick={() => handleSelectClass(cls)}
                whiteSpace="normal"
                wordBreak="break-word"
                textAlign="left"
                width="100%"
                bg={selectedClass?.id === cls.id ? 'brand.blue' : 'transparent'}
                _hover={{ bg: 'brand.blue' }}
              >
                {cls.className}
              </Button>
            ))
          ) : (
            <Text>Aucune classe assignée.</Text>
          )}
        </VStack>
      </Box>

      {/* Contenu principal */}
      <Box flex="1" p={6}>
        {selectedClass ? (
          <>
            <Heading
              as="h2"
              size="lg"
              mb={2}
              color={headerColor}
              bg={headerBg}
              p={3}
              borderRadius="md"
            >
              {selectedClass.className}
            </Heading>
            <Text mb={6} fontSize="md" color="brand.blue">
              Centre : {centreName}
            </Text>

            <Flex mb={4} align="center" justify="space-between" flexWrap="wrap">
              <HStack spacing={2}>
                <Input
                  placeholder="Rechercher un élève..."
                  value={searchQuery}
                  onChange={handleSearch}
                  maxW="300px"
                  variant="filled"
                  bg="gray.100"
                />
                <IconButton
                  icon={<FiSearch />}
                  aria-label="Rechercher"
                  onClick={handleSearch}
                  bg="brand.orange"
                  color="brand.white"
                  _hover={{ bg: 'brand.blue' }}
                />
              </HStack>
              <HStack spacing={2}>
                <Button
                  colorScheme="brand" // Utilisation de la couleur personnalisée
                  leftIcon={<AddIcon />}
                  onClick={openAddModal}
                  mt={[4, 0]}
                >
                  Ajouter un Élève
                </Button>
              </HStack>
            </Flex>

            {isLoading ? (
              <Flex justifyContent="center" alignItems="center" height="50vh">
                <Spinner size="xl" color="brand.orange" />
              </Flex>
            ) : filteredEleves.length > 0 ? (
              <Table
                variant="striped"
                colorScheme="brand" // Utilisation de la couleur personnalisée
                bg={tableBg}
                borderRadius="md"
                boxShadow="md"
              >
                <Thead>
                  <Tr bg={headerBg}>
                    <Th color={headerColor}>Photo</Th>
                    <Th color={headerColor}>Civilité</Th>
                    <Th color={headerColor}>Nom</Th>
                    <Th color={headerColor}>Email</Th>
                    <Th color={headerColor}>Date de Naissance</Th>
                    <Th color={headerColor}>Lieu de Naissance</Th>
                    <Th color={headerColor}>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredEleves.map((eleve) => (
                    <Tr key={eleve.id}>
                      <Td>
                        <Avatar
                          name={`${eleve.lastName} ${eleve.firstName}`}
                          src={eleve?.photoURL || ''}
                          size="sm"
                        />
                      </Td>
                      <Td>{eleve.civilite}</Td>
                      <Td fontWeight="bold">{`${eleve.lastName} ${eleve.firstName}`}</Td>
                      <Td>{eleve.email}</Td>
                      <Td>
                        {eleve.dateOfBirth
                          ? formatDateSafe(eleve.dateOfBirth)
                          : 'N/A'}
                      </Td>
                      <Td>{eleve.placeOfBirth || 'N/A'}</Td>
                      <Td>
                        <HStack spacing={2}>
                          <Button
                            leftIcon={<ViewIcon />}
                            colorScheme="brand" // Utilisation de la couleur personnalisée
                            size="sm"
                            onClick={() => handleViewEleve(eleve)}
                          >
                            Voir
                          </Button>
                          <IconButton
                            icon={<DeleteIcon />}
                            colorScheme="red" // Maintien de 'red' pour les actions destructrices
                            size="sm"
                            onClick={() => handleDeleteEleve(eleve)}
                            aria-label="Supprimer l'élève"
                          />
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <Flex justifyContent="center" alignItems="center" height="50vh">
                <Text fontSize="lg" color="gray.500">
                  Aucun élève trouvé.
                </Text>
              </Flex>
            )}
          </>
        ) : (
          <Flex justifyContent="center" alignItems="center" height="100%">
            <Text fontSize="xl" color="gray.500">
              Veuillez sélectionner une classe pour afficher les élèves.
            </Text>
          </Flex>
        )}
      </Box>

      {/* Modal pour ajouter un élève */}
      <Modal isOpen={isAddModalOpen} onClose={closeAddModal} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un Élève</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <FormControl id="civilite" isRequired>
                <FormLabel>Civilité</FormLabel>
                <Select
                  name="civilite"
                  value={newEleveData.civilite}
                  onChange={handleAddFormChange}
                  placeholder="Sélectionnez une civilité"
                >
                  <option value="M.">M.</option>
                  <option value="Mme">Mme</option>
                  <option value="Mlle">Mlle</option>
                </Select>
              </FormControl>

              <FormControl id="firstName" isRequired>
                <FormLabel>Prénom</FormLabel>
                <Input
                  type="text"
                  name="firstName"
                  value={newEleveData.firstName}
                  onChange={handleAddFormChange}
                  placeholder="Prénom de l'élève"
                />
              </FormControl>

              <FormControl id="lastName" isRequired>
                <FormLabel>Nom</FormLabel>
                <Input
                  type="text"
                  name="lastName"
                  value={newEleveData.lastName}
                  onChange={handleAddFormChange}
                  placeholder="Nom de l'élève"
                />
              </FormControl>

              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={newEleveData.email}
                  onChange={handleAddFormChange}
                  placeholder="Email de l'élève"
                />
              </FormControl>

              <FormControl id="dateOfBirth" isRequired>
                <FormLabel>Date de Naissance</FormLabel>
                <Input
                  type="date"
                  name="dateOfBirth"
                  value={newEleveData.dateOfBirth}
                  onChange={handleAddFormChange}
                />
              </FormControl>

              <FormControl id="placeOfBirth" isRequired>
                <FormLabel>Lieu de Naissance</FormLabel>
                <Input
                  type="text"
                  name="placeOfBirth"
                  value={newEleveData.placeOfBirth}
                  onChange={handleAddFormChange}
                  placeholder="Lieu de naissance de l'élève"
                />
              </FormControl>

              <FormControl id="adresse" isRequired>
                <FormLabel>Adresse</FormLabel>
                <Input
                  type="text"
                  name="adresse"
                  value={newEleveData.adresse}
                  onChange={handleAddFormChange}
                  placeholder="Adresse de l'élève"
                />
              </FormControl>

              <FormControl id="complementAdresse">
                <FormLabel>Complément d'Adresse</FormLabel>
                <Input
                  type="text"
                  name="complementAdresse"
                  value={newEleveData.complementAdresse}
                  onChange={handleAddFormChange}
                  placeholder="Complément d'adresse de l'élève"
                />
              </FormControl>

              <FormControl id="codePostal" isRequired>
                <FormLabel>Code Postal</FormLabel>
                <Input
                  type="text"
                  name="codePostal"
                  value={newEleveData.codePostal}
                  onChange={handleAddFormChange}
                  placeholder="Code postal de l'élève"
                />
              </FormControl>

              <FormControl id="ville" isRequired>
                <FormLabel>Ville</FormLabel>
                <Input
                  type="text"
                  name="ville"
                  value={newEleveData.ville}
                  onChange={handleAddFormChange}
                  placeholder="Ville de l'élève"
                />
              </FormControl>

              <FormControl id="photo">
                <FormLabel>Photo de l'Élève</FormLabel>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoChange}
                />
                {newElevePhoto && (
                  <Image
                    src={URL.createObjectURL(newElevePhoto)}
                    alt="Nouvelle photo de l'élève"
                    boxSize="100px"
                    objectFit="cover"
                    mt={2}
                    borderRadius="full"
                  />
                )}
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="brand" // Utilisation de la couleur personnalisée
              mr={3}
              onClick={handleAddEleve}
              isLoading={isAdding}
            >
              Ajouter
            </Button>
            <Button variant="ghost" onClick={closeAddModal}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default EleveFile;
