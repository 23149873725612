// src/centre/CentreDashboard.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Flex,
  Icon,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useColorMode,
  useColorModeValue,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  IconButton,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  FiBookOpen,
  FiFileText,
  FiUser,
  FiUsers,
  FiBriefcase,
  FiLogOut,
  FiClock,
  FiMenu,
  FiLayers,
  FiClipboard,
  FiInfo,
  FiArchive,
  FiAlertTriangle,
} from 'react-icons/fi';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const CentreDashboard = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [centreName, setCentreName] = useState('');
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCentreName(userData.centreName || 'Centre de Formation');
        }
      }
    };

    fetchUserData();
  }, [auth]);

  const handleLogout = () => {
    auth.signOut();
    navigate('/');
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <Box
      minHeight="100vh"
      bgImage="url('/bureau.jpg')"
      bgSize="cover"
      bgPosition="center"
      color={useColorModeValue('black', 'white')}
    >
      {/* Navbar */}
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        padding={4}
        bg={useColorModeValue('brand.white', 'gray.900')}
        boxShadow="sm"
      >
        <Heading size="md" color="brand.blue">
          {centreName}
        </Heading>
        <Flex align="center">
          <Button variant="ghost" onClick={toggleColorMode} mr={2}>
            {colorMode === 'light' ? '🌙 Mode Sombre' : '☀️ Mode Clair'}
          </Button>
          <Menu>
            <MenuButton as={Button} variant="ghost" rightIcon={<FiMenu />}>
              Menu
            </MenuButton>
            <MenuList>
              <MenuItem icon={<FiBookOpen />} onClick={() => handleNavigate('/view-assigned-classes')}>
                Voir les Classes Assignées
              </MenuItem>
              <MenuItem icon={<FiFileText />} onClick={() => handleNavigate('/manage-documents')}>
                Gérer les Documents
              </MenuItem>
              <MenuItem icon={<FiUser />} onClick={() => handleNavigate('/eleve-file')}>
                Voir les Étudiants
              </MenuItem>
              <MenuItem icon={<FiClock />} onClick={() => handleNavigate('/manage-hours')}>
                Gestion des Heures
              </MenuItem>
              <MenuItem icon={<FiLayers />} onClick={() => handleNavigate('/catalog-centre')}>
                Catalogue des Formations
              </MenuItem>
              <MenuItem icon={<FiClipboard />} onClick={() => handleNavigate('/manage-courses')}>
                Gérer les Cours
              </MenuItem>
              <MenuItem icon={<FiInfo />} onClick={() => handleNavigate('/informations')}>
                Informations
              </MenuItem>
              <MenuItem icon={<FiArchive />} onClick={() => handleNavigate('/dispenses')}>
                Dispenses
              </MenuItem>
              <MenuItem icon={<FiAlertTriangle />} onClick={() => handleNavigate('/centre/avertissements')}>
                Avertissement
              </MenuItem>
              <MenuItem icon={<FiUsers />} onClick={() => handleNavigate('/centre/companies')}>
                Entreprises
              </MenuItem>
              <MenuItem icon={<FiBriefcase />} onClick={() => handleNavigate('/centre/internship')}>
                Stages
              </MenuItem>
              <MenuItem icon={<FiLogOut />} onClick={handleLogout}>
                Se Déconnecter
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      {/* Main Content with Tabs */}
      <Flex direction="column" align="center" justify="center" padding={8} mt={4}>
        <Heading as="h1" size="xl" mb={6} textAlign="center" color="brand.blue">
          Tableau de bord
        </Heading>

        <Tabs variant="enclosed" width="100%">
          <TabList>
            <Tab>Gestion des Classes</Tab>
            <Tab>Gestion Administrative</Tab>
            <Tab>Formations</Tab>
          </TabList>

          <TabPanels>
            {/* Gestion des Classes */}
            <TabPanel>
              <Flex justify="center" mb={4}>
                <IconButton
                  aria-label="Classes Assignées"
                  icon={<FiBookOpen />}
                  size="lg"
                  variant="solid"
                  bg="brand.orange"
                  color="brand.white"
                  onClick={() => handleNavigate('/view-assigned-classes')}
                  mr={2}
                  _hover={{ bg: 'brand.blue' }}
                />
              </Flex>
              <DashboardCard
                icon={FiBookOpen}
                color="brand.blue"
                title="Classes Assignées"
                buttonText="Voir"
                buttonAction={() => handleNavigate('/view-assigned-classes')}
                colorScheme="orange"
              />
            </TabPanel>

            {/* Gestion Administrative */}
            <TabPanel>
              <Flex justify="center" mb={4} wrap="wrap" gap={2}>
                <IconButton
                  aria-label="Gestion des Documents"
                  icon={<FiFileText />}
                  size="lg"
                  variant="solid"
                  bg="brand.teal"
                  color="brand.white"
                  onClick={() => handleNavigate('/manage-documents')}
                  _hover={{ bg: 'brand.orange' }}
                />
                <IconButton
                  aria-label="Étudiants"
                  icon={<FiUser />}
                  size="lg"
                  variant="solid"
                  bg="brand.purple"
                  color="brand.white"
                  onClick={() => handleNavigate('/eleve-file')}
                  _hover={{ bg: 'brand.blue' }}
                />
                <IconButton
                  aria-label="Gestion des Heures"
                  icon={<FiClock />}
                  size="lg"
                  variant="solid"
                  bg="brand.orange"
                  color="brand.white"
                  onClick={() => handleNavigate('/manage-hours')}
                  _hover={{ bg: 'brand.blue' }}
                />
                <IconButton
                  aria-label="Informations"
                  icon={<FiInfo />}
                  size="lg"
                  variant="solid"
                  bg="brand.blue"
                  color="brand.white"
                  onClick={() => handleNavigate('/informations')}
                  _hover={{ bg: 'brand.orange' }}
                />
                <IconButton
                  aria-label="Dispenses"
                  icon={<FiArchive />}
                  size="lg"
                  variant="solid"
                  bg="brand.red"
                  color="brand.white"
                  onClick={() => handleNavigate('/dispenses')}
                  _hover={{ bg: 'brand.orange' }}
                />
                <IconButton
                  aria-label="Avertissement"
                  icon={<FiAlertTriangle />}
                  size="lg"
                  variant="solid"
                  bg="brand.yellow"
                  color="brand.white"
                  onClick={() => handleNavigate('/centre/avertissements')}
                  _hover={{ bg: 'brand.orange' }}
                />
                <IconButton
                  aria-label="Entreprises"
                  icon={<FiUsers />}
                  size="lg"
                  variant="solid"
                  bg="brand.green"
                  color="brand.white"
                  onClick={() => handleNavigate('/centre/companies')}
                  _hover={{ bg: 'brand.orange' }}
                />
                <IconButton
                  aria-label="Stages"
                  icon={<FiBriefcase />}
                  size="lg"
                  variant="solid"
                  bg="brand.cyan"
                  color="brand.white"
                  onClick={() => handleNavigate('/centre/internship')}
                  _hover={{ bg: 'brand.orange' }}
                />
              </Flex>
              <Flex direction={['column', 'row']} wrap="wrap" justify="center" maxW="1200px" gap={6}>
                <DashboardCard
                  icon={FiFileText}
                  color="brand.teal"
                  title="Gestion des Documents"
                  buttonText="Gérer"
                  buttonAction={() => handleNavigate('/manage-documents')}
                  colorScheme="teal"
                />
                <DashboardCard
                  icon={FiUser}
                  color="brand.purple"
                  title="Étudiants"
                  buttonText="Voir"
                  buttonAction={() => handleNavigate('/eleve-file')}
                  colorScheme="purple"
                />
                <DashboardCard
                  icon={FiClock}
                  color="brand.orange"
                  title="Gestion des Heures"
                  buttonText="Gérer"
                  buttonAction={() => handleNavigate('/manage-hours')}
                  colorScheme="orange"
                />
                <DashboardCard
                  icon={FiInfo}
                  color="brand.blue"
                  title="Informations"
                  buttonText="Voir"
                  buttonAction={() => handleNavigate('/informations')}
                  colorScheme="blue"
                />
                <DashboardCard
                  icon={FiArchive}
                  color="brand.red"
                  title="Dispenses"
                  buttonText="Voir"
                  buttonAction={() => handleNavigate('/dispenses')}
                  colorScheme="red"
                />
                <DashboardCard
                  icon={FiAlertTriangle}
                  color="brand.yellow"
                  title="Avertissement"
                  buttonText="Voir"
                  buttonAction={() => handleNavigate('/centre/avertissements')}
                  colorScheme="yellow"
                />
                <DashboardCard
                  icon={FiUsers}
                  color="brand.green"
                  title="Entreprises"
                  buttonText="Gérer"
                  buttonAction={() => handleNavigate('/centre/companies')}
                  colorScheme="green"
                />
                <DashboardCard
                  icon={FiBriefcase}
                  color="brand.cyan"
                  title="Stages"
                  buttonText="Gérer"
                  buttonAction={() => handleNavigate('/centre/internship')}
                  colorScheme="cyan"
                />
              </Flex>
            </TabPanel>

            {/* Formations */}
            <TabPanel>
              <Flex justify="center" mb={4} gap={2}>
                <IconButton
                  aria-label="Catalogue des Formations"
                  icon={<FiLayers />}
                  size="lg"
                  variant="solid"
                  bg="brand.green"
                  color="brand.white"
                  onClick={() => handleNavigate('/catalog-centre')}
                  _hover={{ bg: 'brand.orange' }}
                />
                <IconButton
                  aria-label="Gérer les Cours"
                  icon={<FiClipboard />}
                  size="lg"
                  variant="solid"
                  bg="brand.cyan"
                  color="brand.white"
                  onClick={() => handleNavigate('/manage-courses')}
                  _hover={{ bg: 'brand.orange' }}
                />
              </Flex>
              <Flex direction={['column', 'row']} wrap="wrap" justify="center" maxW="1200px" gap={6}>
                <DashboardCard
                  icon={FiLayers}
                  color="brand.green"
                  title="Catalogue des Formations"
                  buttonText="Voir"
                  buttonAction={() => handleNavigate('/catalog-centre')}
                  colorScheme="green"
                />
                <DashboardCard
                  icon={FiClipboard}
                  color="brand.cyan"
                  title="Gérer les Cours"
                  buttonText="Gérer"
                  buttonAction={() => handleNavigate('/manage-courses')}
                  colorScheme="cyan"
                />
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Box>
  );
};

// Composant réutilisable pour les cartes du tableau de bord avec taille fixe
const DashboardCard = ({ icon, color, title, text, buttonText, buttonAction, colorScheme }) => (
  <Box
    bg={useColorModeValue('brand.white', 'gray.700')}
    borderRadius="lg"
    shadow="md"
    p={6}
    textAlign="center"
    width="300px"
    height="200px"
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
  >
    <Icon as={icon} w={12} h={12} color={color} mb={4} />
    <Heading as="h3" size="md" mb={2} color="brand.blue">
      {title}
    </Heading>
    {text ? (
      <Text color="gray.500">{text}</Text>
    ) : (
      <Button
        colorScheme={colorScheme}
        variant="outline"
        onClick={buttonAction}
        _hover={{ bg: `${colorScheme}.500`, color: 'brand.white' }}
      >
        {buttonText}
      </Button>
    )}
  </Box>
);

export default CentreDashboard;
