// src/organisme/ManageCandidates.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Heading,
  Input,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  FormControl,
  FormLabel,
  Flex,
  Select,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tag,
  VStack,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon, ViewIcon, DownloadIcon } from '@chakra-ui/icons';
import { doc, updateDoc, collection, getDocs, addDoc, deleteDoc } from 'firebase/firestore';
import { db, storage, auth } from '../firebase'; // Importer auth et storage
import { uploadBytes, ref, getDownloadURL } from 'firebase/storage';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import JSZip from 'jszip'; // Importer JSZip

const ManageCandidates = ({ refreshCandidates }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [candidates, setCandidates] = useState([]);
  const [centres, setCentres] = useState([]); // État pour stocker les centres
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    voeux: [], // Array of center IDs
    status: 'pending', // possible values: 'pending', 'contractualized', 'student'
    documents: [], // Array of document URLs
    createdAt: null,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [file, setFile] = useState(null);
  const [batchFiles, setBatchFiles] = useState([]);

  // Fonction pour récupérer les centres depuis Firestore
  const fetchCentres = useCallback(async () => {
    try {
      const centresCol = collection(db, 'centres');
      const centresSnapshot = await getDocs(centresCol);
      const centresList = centresSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCentres(centresList);
    } catch (error) {
      console.error("Erreur lors de la récupération des centres :", error);
      toast({
        title: 'Erreur',
        description: "Impossible de récupérer les centres.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  // Fonction pour récupérer les candidats depuis Firestore
  const fetchCandidates = useCallback(async () => {
    try {
      const candidatesCol = collection(db, 'candidates');
      const candidatesSnapshot = await getDocs(candidatesCol);
      const candidatesList = candidatesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCandidates(candidatesList);
    } catch (error) {
      console.error("Erreur lors de la récupération des candidats :", error);
      toast({
        title: 'Erreur',
        description: "Impossible de récupérer les candidats.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchCentres();
    fetchCandidates();
  }, [fetchCentres, fetchCandidates]);

  // Fonction pour ouvrir le modal de détail
  const handleViewDetails = (candidate) => {
    setSelectedCandidate(candidate);
    onOpen();
  };

  // Fonction pour ouvrir le modal d'édition
  const handleEditCandidate = (candidate) => {
    setIsEditing(true);
    setFormData({
      name: candidate.name,
      email: candidate.email,
      voeux: candidate.voeux || [],
      status: candidate.status || 'pending',
      documents: candidate.documents || [],
      createdAt: candidate.createdAt ? new Date(candidate.createdAt.seconds * 1000) : null,
    });
    setSelectedCandidate(candidate);
    onOpen();
  };

  // Fonction pour gérer la suppression d'un candidat
  const handleDeleteCandidate = async (candidateId) => {
    try {
      await deleteDoc(doc(db, 'candidates', candidateId));
      toast({
        title: 'Succès',
        description: 'Candidat supprimé avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchCandidates();
    } catch (error) {
      console.error("Erreur lors de la suppression du candidat :", error);
      toast({
        title: 'Erreur',
        description: "Impossible de supprimer le candidat.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Fonction pour gérer le changement dans le formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  // Fonction pour ajouter un vœu
  const handleAddVoeu = () => {
    setFormData(prev => ({ ...prev, voeux: [...prev.voeux, ''] }));
  };

  // Fonction pour gérer le changement dans les vœux
  const handleVoeuChange = (index, value) => {
    const updatedVoeux = [...formData.voeux];
    updatedVoeux[index] = value;
    setFormData(prev => ({ ...prev, voeux: updatedVoeux }));
  };

  // Fonction pour supprimer un vœu
  const handleDeleteVoeu = (index) => {
    const updatedVoeux = formData.voeux.filter((_, i) => i !== index);
    setFormData(prev => ({ ...prev, voeux: updatedVoeux }));
  };

  // Fonction pour gérer l'ajout de documents
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Fonction pour gérer l'ajout multiple de documents
  const handleBatchFileChange = (e) => {
    setBatchFiles([...e.target.files]);
  };

  // Fonction pour télécharger un document individuel
  const handleDownloadDocument = async (fileUrl) => {
    try {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.target = '_blank';
      link.download = 'document';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erreur lors du téléchargement du document :", error);
      toast({
        title: 'Erreur',
        description: "Impossible de télécharger le document.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Fonction pour télécharger le dossier complet
  const handleDownloadDossier = async (candidate) => {
    try {
      const zip = new JSZip();
      const folder = zip.folder(`${candidate.name}_dossier`);

      // Ajouter les documents individuels au zip
      for (const docUrl of candidate.documents) {
        const response = await fetch(docUrl);
        const blob = await response.blob();
        const fileName = docUrl.split('/').pop().split('?')[0];
        folder.file(fileName, blob);
      }

      // Générer le zip
      const content = await zip.generateAsync({ type: 'blob' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      link.download = `${candidate.name}_dossier.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erreur lors du téléchargement du dossier :", error);
      toast({
        title: 'Erreur',
        description: "Impossible de télécharger le dossier complet.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Fonction pour ajouter un candidat en tant qu'élève
  const handleConvertToStudent = async (candidateId) => {
    try {
      const candidateRef = doc(db, 'candidates', candidateId);
      await updateDoc(candidateRef, {
        status: 'student',
      });
      toast({
        title: 'Succès',
        description: 'Candidat converti en élève avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchCandidates();
    } catch (error) {
      console.error("Erreur lors de la conversion en élève :", error);
      toast({
        title: 'Erreur',
        description: "Impossible de convertir le candidat en élève.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Fonction pour soumettre le formulaire d'ajout/modification
  const handleSubmit = async () => {
    try {
      let documentUrls = [...formData.documents];

      // Télécharger un fichier unique
      if (file) {
        const storageRef = ref(storage, `documents/${file.name}_${Date.now()}`);
        const metadata = {
          customMetadata: {
            centreId: auth.currentUser ? auth.currentUser.uid : '', // Associer centreId via l'utilisateur connecté
          },
        };
        await uploadBytes(storageRef, file, metadata);
        const fileUrl = await getDownloadURL(storageRef);
        documentUrls.push(fileUrl);
      }

      // Télécharger plusieurs fichiers
      for (const batchFile of batchFiles) {
        const storageRef = ref(storage, `documents/${batchFile.name}_${Date.now()}`);
        const metadata = {
          customMetadata: {
            centreId: auth.currentUser ? auth.currentUser.uid : '', // Associer centreId via l'utilisateur connecté
          },
        };
        await uploadBytes(storageRef, batchFile, metadata);
        const fileUrl = await getDownloadURL(storageRef);
        documentUrls.push(fileUrl);
      }

      const candidateData = {
        name: formData.name,
        email: formData.email,
        centreId: auth.currentUser ? auth.currentUser.uid : '', // Associer centreId via l'utilisateur connecté
        voeux: formData.voeux,
        status: formData.status,
        documents: documentUrls,
        createdAt: formData.createdAt || new Date(),
      };

      if (isEditing && selectedCandidate) {
        const candidateRef = doc(db, 'candidates', selectedCandidate.id);
        await updateDoc(candidateRef, candidateData);
        toast({
          title: 'Succès',
          description: 'Candidat mis à jour avec succès.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        await addDoc(collection(db, 'candidates'), candidateData);
        toast({
          title: 'Succès',
          description: 'Candidat ajouté avec succès.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }

      // Réinitialiser le formulaire
      setFormData({
        name: '',
        email: '',
        voeux: [],
        status: 'pending',
        documents: [],
        createdAt: null,
      });
      setFile(null);
      setBatchFiles([]);
      setIsEditing(false);
      setSelectedCandidate(null);
      onClose();
      fetchCandidates();
    } catch (error) {
      console.error("Erreur lors de l'ajout/mise à jour du candidat :", error);
      toast({
        title: 'Erreur',
        description: "Impossible d'ajouter ou de mettre à jour le candidat.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Fonction pour récupérer le centreName à partir du centreId
  const getCentreName = (centreId) => {
    const centre = centres.find(c => c.id === centreId);
    return centre ? centre.centreName : 'Centre Inconnu';
  };

  return (
    <Box p={4}>
      <Heading as="h2" size="lg" mb={4}>
        Gestion des Candidats
      </Heading>

      {/* Bouton d'ajout de candidat */}
      <Button
        colorScheme="blue"
        mb={4}
        onClick={() => {
          setIsEditing(false);
          setFormData({
            name: '',
            email: '',
            voeux: [],
            status: 'pending',
            documents: [],
            createdAt: null,
          });
          setFile(null);
          setBatchFiles([]);
          onOpen();
        }}
      >
        Ajouter un Candidat
      </Button>

      {/* Tableau des candidats */}
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Nom</Th>
            <Th>Email</Th>
            <Th>Centre</Th>
            <Th>Vœux</Th>
            <Th>Status</Th>
            <Th>Documents</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {candidates.map(candidate => (
            <Tr key={candidate.id}>
              <Td>{candidate.name}</Td>
              <Td>{candidate.email}</Td>
              <Td>{getCentreName(candidate.centreId)}</Td>
              <Td>
                {candidate.voeux && candidate.voeux.length > 0 ? (
                  candidate.voeux.map((voeu, index) => (
                    <Tag key={index} mr={1} mb={1}>
                      {getCentreName(voeu)}
                    </Tag>
                  ))
                ) : (
                  '-'
                )}
              </Td>
              <Td>
                <Tag
                  colorScheme={
                    candidate.status === 'pending'
                      ? 'yellow'
                      : candidate.status === 'contractualized'
                      ? 'blue'
                      : candidate.status === 'student'
                      ? 'green'
                      : 'gray'
                  }
                >
                  {candidate.status.charAt(0).toUpperCase() + candidate.status.slice(1)}
                </Tag>
              </Td>
              <Td>
                {candidate.documents && candidate.documents.length > 0 ? (
                  <IconButton
                    aria-label="Télécharger le dossier"
                    icon={<DownloadIcon />}
                    size="sm"
                    onClick={() => handleDownloadDossier(candidate)}
                  />
                ) : (
                  '-'
                )}
              </Td>
              <Td>
                <IconButton
                  aria-label="Voir les détails"
                  icon={<ViewIcon />}
                  size="sm"
                  mr={2}
                  onClick={() => handleViewDetails(candidate)}
                />
                <IconButton
                  aria-label="Modifier le candidat"
                  icon={<EditIcon />}
                  size="sm"
                  mr={2}
                  onClick={() => handleEditCandidate(candidate)}
                />
                <IconButton
                  aria-label="Supprimer le candidat"
                  icon={<DeleteIcon />}
                  size="sm"
                  onClick={() => handleDeleteCandidate(candidate.id)}
                />
                {candidate.status !== 'student' && (
                  <Button
                    size="sm"
                    colorScheme="green"
                    ml={2}
                    onClick={() => handleConvertToStudent(candidate.id)}
                  >
                    Convertir en Élève
                  </Button>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Modal pour les détails et l'édition */}
      <Modal isOpen={isOpen} onClose={() => { onClose(); setSelectedCandidate(null); setIsEditing(false); }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEditing ? 'Modifier le Candidat' : 'Détails du Candidat'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!isEditing && selectedCandidate && (
              <VStack align="start" spacing={4}>
                <Text><strong>Nom:</strong> {selectedCandidate.name}</Text>
                <Text><strong>Email:</strong> {selectedCandidate.email}</Text>
                <Text><strong>Centre:</strong> {getCentreName(selectedCandidate.centreId)}</Text>
                <Text><strong>Vœux:</strong> {selectedCandidate.voeux.map(voeu => getCentreName(voeu)).join(', ') || '-'}</Text>
                <Text><strong>Status:</strong> {selectedCandidate.status}</Text>
                <Text><strong>Documents:</strong></Text>
                {selectedCandidate.documents && selectedCandidate.documents.length > 0 ? (
                  selectedCandidate.documents.map((docUrl, index) => (
                    <Button
                      key={index}
                      size="sm"
                      leftIcon={<DownloadIcon />}
                      onClick={() => handleDownloadDocument(docUrl)}
                    >
                      Télécharger Document {index + 1}
                    </Button>
                  ))
                ) : (
                  <Text>-</Text>
                )}
              </VStack>
            )}

            {/* Formulaire d'ajout/modification */}
            {(isEditing || !selectedCandidate) && (
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Nom</FormLabel>
                  <Input
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </FormControl>
                {/* Si vous souhaitez également permettre de sélectionner le centre principal, décommentez cette section */}
                {/* <FormControl isRequired>
                  <FormLabel>Centre</FormLabel>
                  <Select
                    name="centreId"
                    value={formData.centreId}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Sélectionnez un centre
                    </option>
                    {centres.map(centre => (
                      <option key={centre.id} value={centre.id}>
                        {centre.centreName}
                      </option>
                    ))}
                  </Select>
                </FormControl> */}
                <FormControl>
                  <FormLabel>Vœux</FormLabel>
                  {formData.voeux.map((voeu, index) => (
                    <Flex key={index} mb={2}>
                      <Select
                        value={voeu}
                        onChange={(e) => handleVoeuChange(index, e.target.value)}
                        placeholder="Sélectionnez un centre"
                      >
                        {centres.map(centre => (
                          <option key={centre.id} value={centre.id}>
                            {centre.centreName}
                          </option>
                        ))}
                      </Select>
                      <IconButton
                        aria-label="Supprimer le vœu"
                        icon={<DeleteIcon />}
                        size="sm"
                        ml={2}
                        onClick={() => handleDeleteVoeu(index)}
                      />
                    </Flex>
                  ))}
                  <Button size="sm" onClick={handleAddVoeu}>
                    Ajouter un Vœu
                  </Button>
                </FormControl>
                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <Select name="status" value={formData.status} onChange={handleChange}>
                    <option value="pending">En Attente</option>
                    <option value="contractualized">Contractualisé</option>
                    <option value="student">Élève</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Ajouter un Document</FormLabel>
                  <Input type="file" onChange={handleFileChange} />
                </FormControl>
                <FormControl>
                  <FormLabel>Ajouter Plusieurs Documents</FormLabel>
                  <Input type="file" multiple onChange={handleBatchFileChange} />
                </FormControl>
              </VStack>
            )}
          </ModalBody>

          <ModalFooter>
            {isEditing || !selectedCandidate ? (
              <>
                <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                  {isEditing ? 'Mettre à Jour' : 'Ajouter'}
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => {
                    onClose();
                    setIsEditing(false);
                    setFormData({
                      name: '',
                      email: '',
                      voeux: [],
                      status: 'pending',
                      documents: [],
                      createdAt: null,
                    });
                    setFile(null);
                    setBatchFiles([]);
                  }}
                >
                  Annuler
                </Button>
              </>
            ) : (
              <Button variant="ghost" onClick={() => { onClose(); setSelectedCandidate(null); }}>
                Fermer
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ManageCandidates;
