// src/centre/courses.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Flex,
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { FiEdit, FiTrash2, FiUpload } from 'react-icons/fi';
import { collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';

const ManageCourses = () => {
  const toast = useToast();
  const [teachers, setTeachers] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [courses, setCourses] = useState([]);

  // États pour les formulaires d'ajout
  const [newTeacher, setNewTeacher] = useState('');
  const [newSubject, setNewSubject] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    // Récupérer les enseignants
    const teachersSnapshot = await getDocs(collection(db, 'teachers'));
    const teachersList = teachersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setTeachers(teachersList);

    // Récupérer les matières
    const subjectsSnapshot = await getDocs(collection(db, 'subjects'));
    const subjectsList = subjectsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setSubjects(subjectsList);

    // Récupérer les cours
    const coursesSnapshot = await getDocs(collection(db, 'courses'));
    const coursesList = coursesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCourses(coursesList);
  };

  const handleAddTeacher = async () => {
    if (newTeacher.trim() === '') {
      toast({
        title: 'Erreur',
        description: 'Le nom de l\'enseignant ne peut pas être vide.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      const docRef = await addDoc(collection(db, 'teachers'), { name: newTeacher });
      setTeachers([...teachers, { id: docRef.id, name: newTeacher }]);
      setNewTeacher('');
      toast({
        title: 'Succès',
        description: 'Enseignant ajouté avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de l\'ajout de l\'enseignant:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible d\'ajouter l\'enseignant.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddSubject = async () => {
    if (newSubject.trim() === '') {
      toast({
        title: 'Erreur',
        description: 'Le nom de la matière ne peut pas être vide.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      const docRef = await addDoc(collection(db, 'subjects'), { name: newSubject });
      setSubjects([...subjects, { id: docRef.id, name: newSubject }]);
      setNewSubject('');
      toast({
        title: 'Succès',
        description: 'Matière ajoutée avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la matière:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible d\'ajouter la matière.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteTeacher = async (id) => {
    try {
      await deleteDoc(doc(db, 'teachers', id));
      setTeachers(teachers.filter(teacher => teacher.id !== id));
      toast({
        title: 'Succès',
        description: 'Enseignant supprimé avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'enseignant:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer l\'enseignant.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteSubject = async (id) => {
    try {
      await deleteDoc(doc(db, 'subjects', id));
      setSubjects(subjects.filter(subject => subject.id !== id));
      toast({
        title: 'Succès',
        description: 'Matière supprimée avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression de la matière:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer la matière.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleImport = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension === 'csv') {
        Papa.parse(file, {
          header: true,
          complete: (results) => {
            processImportedData(results.data);
          },
          error: (error) => {
            console.error('Erreur lors de l\'importation du fichier CSV:', error);
            toast({
              title: 'Erreur',
              description: 'Impossible d\'importer le fichier CSV.',
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          },
        });
      } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          processImportedData(jsonData);
        };
        reader.onerror = (error) => {
          console.error('Erreur lors de l\'importation du fichier Excel:', error);
          toast({
            title: 'Erreur',
            description: 'Impossible d\'importer le fichier Excel.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        };
        reader.readAsArrayBuffer(file);
      } else {
        toast({
          title: 'Erreur',
          description: 'Format de fichier non supporté. Veuillez utiliser CSV ou Excel.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const processImportedData = async (data) => {
    // Supposons que les colonnes du fichier sont : "teacher", "subject", "date", "time", etc.
    try {
      const batch = db.batch();
      data.forEach((course) => {
        // Vous pouvez ajouter des validations ici
        const courseRef = collection(db, 'courses');
        batch.set(doc(courseRef), {
          teacher: course.teacher,
          subject: course.subject,
          date: course.date,
          time: course.time,
          // Ajoutez d'autres champs selon vos besoins
        });
      });
      await batch.commit();
      fetchData();
      toast({
        title: 'Succès',
        description: 'Cours importés avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors du traitement des données importées:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de traiter les données importées.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={8}>
      <Heading mb={6}>Gestion des Cours</Heading>
      
      {/* Section Gestion des Enseignants */}
      <Box mb={8}>
        <Heading size="md" mb={4}>Enseignants</Heading>
        <Flex mb={4}>
          <Input
            placeholder="Nom de l'enseignant"
            value={newTeacher}
            onChange={(e) => setNewTeacher(e.target.value)}
            mr={2}
          />
          <Button colorScheme="blue" onClick={handleAddTeacher}>
            Ajouter
          </Button>
        </Flex>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Nom</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {teachers.map((teacher) => (
              <Tr key={teacher.id}>
                <Td>{teacher.name}</Td>
                <Td>
                  {/* Vous pouvez ajouter des fonctionnalités d'édition ici */}
                  <IconButton
                    aria-label="Supprimer"
                    icon={<FiTrash2 />}
                    colorScheme="red"
                    variant="ghost"
                    onClick={() => handleDeleteTeacher(teacher.id)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* Section Gestion des Matières */}
      <Box mb={8}>
        <Heading size="md" mb={4}>Matières</Heading>
        <Flex mb={4}>
          <Input
            placeholder="Nom de la matière"
            value={newSubject}
            onChange={(e) => setNewSubject(e.target.value)}
            mr={2}
          />
          <Button colorScheme="green" onClick={handleAddSubject}>
            Ajouter
          </Button>
        </Flex>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Nom</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {subjects.map((subject) => (
              <Tr key={subject.id}>
                <Td>{subject.name}</Td>
                <Td>
                  {/* Vous pouvez ajouter des fonctionnalités d'édition ici */}
                  <IconButton
                    aria-label="Supprimer"
                    icon={<FiTrash2 />}
                    colorScheme="red"
                    variant="ghost"
                    onClick={() => handleDeleteSubject(subject.id)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* Section Importation des Cours */}
      <Box mb={8}>
        <Heading size="md" mb={4}>Importer les Cours</Heading>
        <Flex align="center">
          <Button
            colorScheme="purple"
            leftIcon={<FiUpload />}
            as="label"
            htmlFor="import-file"
          >
            Importer un Fichier
          </Button>
          <Input
            type="file"
            id="import-file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleImport}
            display="none"
          />
        </Flex>
      </Box>

      {/* Section Liste des Cours */}
      <Box>
        <Heading size="md" mb={4}>Liste des Cours</Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Enseignant</Th>
              <Th>Matière</Th>
              <Th>Date</Th>
              <Th>Heure</Th>
              {/* Ajoutez d'autres colonnes selon vos besoins */}
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {courses.map((course) => (
              <Tr key={course.id}>
                <Td>{course.teacher}</Td>
                <Td>{course.subject}</Td>
                <Td>{course.date}</Td>
                <Td>{course.time}</Td>
                <Td>
                  {/* Vous pouvez ajouter des fonctionnalités d'édition ou de suppression */}
                  {/* Exemple de bouton de suppression */}
                  <IconButton
                    aria-label="Supprimer"
                    icon={<FiTrash2 />}
                    colorScheme="red"
                    variant="ghost"
                    // Implémentez la fonction de suppression si nécessaire
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default ManageCourses;
