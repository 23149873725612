// src/organisme/Formations.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Heading,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  FormControl,
  FormLabel,
  Flex,
  Select,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
  Avatar,
  useColorModeValue,
  Divider,
  Text,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { collection, getDocs, deleteDoc, doc, addDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Import direct de 'db' depuis firebase.js

const Formations = () => {
  const toast = useToast();
  const [formations, setFormations] = useState([]);
  const [centres, setCentres] = useState([]);
  const [selectedFormation, setSelectedFormation] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState('');
  const [newSkill, setNewSkill] = useState(''); // État pour ajouter une compétence
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    centreId: '',
    volumeAnnuel: '',
    codeRNCP: '',
    secteurActivite: '',
    prerequis: '',
    voieAccesCertification: '',
    competences: [], // Nouveau champ pour stocker les compétences/UC
  });

  // Couleurs basées sur le mode
  const bg = useColorModeValue('gray.50', 'gray.800');
  const tableBg = useColorModeValue('white', 'gray.700');

  // Fonction pour récupérer les formations depuis Firestore
  const fetchFormations = useCallback(async () => {
    try {
      const formationsCol = collection(db, 'formations');
      const formationsSnapshot = await getDocs(formationsCol);
      const formationsList = formationsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFormations(formationsList);
    } catch (error) {
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer les formations.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  // Fonction pour récupérer les centres depuis Firestore
  const fetchCentres = useCallback(async () => {
    try {
      const centresCol = collection(db, 'centres');
      const centresSnapshot = await getDocs(centresCol);
      const centresList = centresSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCentres(centresList);
    } catch (error) {
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer les centres.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchFormations();
    fetchCentres();
  }, [fetchFormations, fetchCentres]);

  // Fonction pour obtenir le nom du centre à partir de son ID
  const getCentreName = (centreId) => {
    const centre = centres.find((c) => c.id === centreId);
    return centre ? centre.centreName : 'Centre Inconnu';
  };

  // Fonction pour filtrer les formations en fonction du terme de recherche
  const filteredFormations = formations.filter((formation) =>
    formation.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    formation.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    getCentreName(formation.centreId).toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Fonction pour afficher les détails d'une formation
  const viewFormationDetails = (formation) => {
    setSelectedFormation(formation);
  };

  // Fonction pour revenir à la liste des formations
  const goBackToList = () => {
    setSelectedFormation(null);
  };

  // Fonction pour ouvrir le modal de création/édition
  const openModal = (formation = null) => {
    setSelectedFormation(formation);
    if (formation) {
      setFormData({
        title: formation.title || '',
        description: formation.description || '',
        centreId: formation.centreId || '',
        volumeAnnuel: formation.volumeAnnuel || '',
        codeRNCP: formation.codeRNCP || '',
        secteurActivite: formation.secteurActivite || '',
        prerequis: formation.prerequis || '',
        voieAccesCertification: formation.voieAccesCertification || '',
        competences: formation.competences || [], // Les compétences ou UC existantes
      });
    } else {
      setFormData({
        title: '',
        description: '',
        centreId: '',
        volumeAnnuel: '',
        codeRNCP: '',
        secteurActivite: '',
        prerequis: '',
        voieAccesCertification: '',
        competences: [],
      });
    }
    onOpen(); // Ouvre le modal
  };

  // Fonction pour gérer la soumission du formulaire (ajout ou mise à jour)
  const handleSubmit = async () => {
    try {
      if (selectedFormation) {
        // Mise à jour d'une formation existante
        const formationRef = doc(db, 'formations', selectedFormation.id);
        await updateDoc(formationRef, {
          ...formData,
          volumeAnnuel: Number(formData.volumeAnnuel), // Conversion en nombre
        });
        toast({
          title: 'Succès',
          description: 'Formation mise à jour avec succès.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        // Ajout d'une nouvelle formation
        await addDoc(collection(db, 'formations'), {
          ...formData,
          volumeAnnuel: Number(formData.volumeAnnuel), // Conversion en nombre
          createdAt: new Date(),
        });
        toast({
          title: 'Succès',
          description: 'Formation ajoutée avec succès.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
      onClose();
      setSelectedFormation(null);
      fetchFormations();
    } catch (error) {
      console.error('Erreur lors de l\'ajout/mise à jour de la formation :', error);
      toast({
        title: 'Erreur',
        description: "Impossible d'ajouter ou de mettre à jour la formation.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Fonction pour supprimer une formation
  const handleDeleteFormation = async (formationId) => {
    try {
      await deleteDoc(doc(db, 'formations', formationId));
      toast({
        title: 'Succès',
        description: 'Formation supprimée avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchFormations();
    } catch (error) {
      console.error('Erreur lors de la suppression de la formation :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer la formation.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Fonction pour ajouter une compétence à la liste
  const handleAddSkill = () => {
    if (newSkill.trim() !== '') {
      setFormData((prevState) => ({
        ...prevState,
        competences: [...prevState.competences, newSkill.trim()],
      }));
      setNewSkill(''); // Réinitialiser l'entrée
    }
  };

  // Fonction pour supprimer une compétence
  const handleRemoveSkill = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      competences: prevState.competences.filter((_, i) => i !== index),
    }));
  };

  return (
    <Box p={4} bg={bg} minHeight="100vh">
      <Heading as="h2" size="lg" mb={4} color="teal.600">
        Gestion des Formations
      </Heading>

      {/* Barre de recherche et Bouton d'ajout */}
      <Flex mb={4} align="center" justify="space-between">
        <Input
          placeholder="Rechercher une formation par titre, description ou centre"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          width="60%"
          bg={useColorModeValue('white', 'gray.600')}
        />
        <Button colorScheme="teal" onClick={() => openModal()}>
          Ajouter Formation
        </Button>
      </Flex>

      {/* Tableau des formations */}
      <Table variant="simple" bg={tableBg} borderRadius="md" boxShadow="md">
        <Thead>
          <Tr>
            <Th>Titre</Th>
            <Th>Description</Th>
            <Th>Centre de Formation</Th>
            <Th>Volume Annuel</Th>
            <Th>Code RNCP</Th>
            <Th>Secteur d'Activité</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredFormations.map((formation) => (
            <Tr key={formation.id}>
              <Td>{formation.title}</Td>
              <Td>{formation.description}</Td>
              <Td>{getCentreName(formation.centreId)}</Td>
              <Td>{formation.volumeAnnuel}</Td>
              <Td>{formation.codeRNCP}</Td>
              <Td>{formation.secteurActivite}</Td>
              <Td>
                <IconButton
                  aria-label="Modifier"
                  icon={<EditIcon />}
                  size="sm"
                  mr={2}
                  onClick={() => openModal(formation)}
                />
                <IconButton
                  aria-label="Supprimer"
                  icon={<DeleteIcon />}
                  size="sm"
                  colorScheme="red"
                  onClick={() => handleDeleteFormation(formation.id)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Modal pour ajouter/modifier une formation */}
      <Modal isOpen={isOpen} onClose={() => { onClose(); setSelectedFormation(null); }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedFormation ? 'Modifier Formation' : 'Ajouter Formation'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl id="title" isRequired>
                <FormLabel>Titre</FormLabel>
                <Input
                  type="text"
                  placeholder="Titre de la formation"
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  bg={useColorModeValue('white', 'gray.600')}
                />
              </FormControl>
              <FormControl id="description" isRequired>
                <FormLabel>Description</FormLabel>
                <Input
                  type="text"
                  placeholder="Description de la formation"
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  bg={useColorModeValue('white', 'gray.600')}
                />
              </FormControl>
              <FormControl id="centreId" isRequired>
                <FormLabel>Centre de Formation</FormLabel>
                <Select
                  placeholder="Sélectionnez un centre"
                  value={formData.centreId}
                  onChange={(e) => setFormData({ ...formData, centreId: e.target.value })}
                  bg={useColorModeValue('white', 'gray.600')}
                >
                  {centres.map((centre) => (
                    <option key={centre.id} value={centre.id}>
                      {centre.centreName}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl id="volumeAnnuel" isRequired>
                <FormLabel>Volume Annuel de Formation</FormLabel>
                <Input
                  type="number"
                  placeholder="Entrez le volume annuel"
                  value={formData.volumeAnnuel}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setFormData({ ...formData, volumeAnnuel: value });
                    }
                  }}
                  min="0"
                  bg={useColorModeValue('white', 'gray.600')}
                />
              </FormControl>

              {/* Ajout des nouveaux champs */}
              <FormControl id="codeRNCP" isRequired>
                <FormLabel>Code RNCP</FormLabel>
                <Input
                  type="text"
                  placeholder="Code RNCP"
                  value={formData.codeRNCP}
                  onChange={(e) => setFormData({ ...formData, codeRNCP: e.target.value })}
                  bg={useColorModeValue('white', 'gray.600')}
                />
              </FormControl>
              <FormControl id="secteurActivite" isRequired>
                <FormLabel>Secteur d'Activité</FormLabel>
                <Input
                  type="text"
                  placeholder="Secteur d'activité"
                  value={formData.secteurActivite}
                  onChange={(e) => setFormData({ ...formData, secteurActivite: e.target.value })}
                  bg={useColorModeValue('white', 'gray.600')}
                />
              </FormControl>
              <FormControl id="prerequis" isRequired>
                <FormLabel>Prérequis</FormLabel>
                <Input
                  type="text"
                  placeholder="Prérequis"
                  value={formData.prerequis}
                  onChange={(e) => setFormData({ ...formData, prerequis: e.target.value })}
                  bg={useColorModeValue('white', 'gray.600')}
                />
              </FormControl>
              <FormControl id="voieAccesCertification" isRequired>
                <FormLabel>Voie d'Accès à la Certification</FormLabel>
                <Input
                  type="text"
                  placeholder="Voie d'accès à la certification"
                  value={formData.voieAccesCertification}
                  onChange={(e) => setFormData({ ...formData, voieAccesCertification: e.target.value })}
                  bg={useColorModeValue('white', 'gray.600')}
                />
              </FormControl>

              {/* Champ Compétences */}
              <FormControl id="competences" isRequired>
                <FormLabel>Compétences/UC</FormLabel>
                <HStack spacing={2}>
                  <Input
                    placeholder="Ajouter une compétence"
                    value={newSkill}
                    onChange={(e) => setNewSkill(e.target.value)}
                    bg={useColorModeValue('white', 'gray.600')}
                  />
                  <Button colorScheme="teal" onClick={handleAddSkill}>
                    Ajouter
                  </Button>
                </HStack>
                <VStack align="start" mt={3}>
                  {formData.competences.map((skill, index) => (
                    <Tag
                      size="lg"
                      key={index}
                      borderRadius="full"
                      variant="solid"
                      colorScheme="teal"
                    >
                      <TagLabel>{skill}</TagLabel>
                      <TagCloseButton onClick={() => handleRemoveSkill(index)} />
                    </Tag>
                  ))}
                </VStack>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              {selectedFormation ? 'Mettre à Jour' : 'Ajouter'}
            </Button>
            <Button variant="ghost" onClick={() => { onClose(); setSelectedFormation(null); }}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Formations;
