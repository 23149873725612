// src/centre/Companies.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Heading,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Input,
  Button,
  IconButton,
  Select,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Stack,
  Text,
  HStack,
  VStack,
  useColorModeValue,
  ModalCloseButton,
  Avatar,
  Badge,
  Divider,
  Tag,
} from '@chakra-ui/react';
import {
  AddIcon,
  EditIcon,
  DeleteIcon,
  SearchIcon,
} from '@chakra-ui/icons';
import { getAuth } from 'firebase/auth';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
  addDoc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from '../firebase';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIconUrl from 'leaflet/dist/images/marker-icon.png';
import markerShadowUrl from 'leaflet/dist/images/marker-shadow.png';

// Fix pour le problème d'icône par défaut avec Leaflet dans React
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIconUrl,
  shadowUrl: markerShadowUrl,
});

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [internships, setInternships] = useState([]);
  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editCompanyData, setEditCompanyData] = useState(null);
  const [newCompanyData, setNewCompanyData] = useState({
    name: '',
    address: '',
    status: 'active',
    latitude: '',
    longitude: '',
  });
  const [companyToDelete, setCompanyToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const auth = getAuth();
  const toast = useToast();
  const bg = useColorModeValue('brand.white', 'gray.900');
  const tableBg = useColorModeValue('brand.white', 'gray.800');
  const cardBg = useColorModeValue('brand.white', 'gray.800');
  const headerColor = useColorModeValue('brand.blue', 'brand.white');
  const headerBg = useColorModeValue('gray.100', 'gray.700');

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const user = auth.currentUser;
    if (user) {
      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const centreId = userData.centreId;

          // Récupérer les entreprises
          const companiesQuery = query(
            collection(db, 'companies'),
            where('centreId', '==', centreId)
          );
          const companiesSnapshot = await getDocs(companiesQuery);
          const fetchedCompanies = companiesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCompanies(fetchedCompanies);
          setFilteredCompanies(fetchedCompanies);

          // Récupérer les stages
          const internshipsQuery = query(
            collection(db, 'internships'),
            where('centreId', '==', centreId)
          );
          const internshipsSnapshot = await getDocs(internshipsQuery);
          const fetchedInternships = internshipsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setInternships(fetchedInternships);

          // Récupérer les classes
          const classesQuery = query(
            collection(db, 'classes'),
            where('centreId', '==', centreId)
          );
          const classesSnapshot = await getDocs(classesQuery);
          const fetchedClasses = classesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setClasses(fetchedClasses);

          // Récupérer les étudiants dans ces classes
          let fetchedStudents = [];
          const batchSize = 10;
          const classIds = fetchedClasses.map((classe) => classe.id);
          for (let i = 0; i < classIds.length; i += batchSize) {
            const batchClassIds = classIds.slice(i, i + batchSize);
            const studentsQuery = query(
              collection(db, 'eleves'),
              where('classeId', 'in', batchClassIds)
            );
            const studentsSnapshot = await getDocs(studentsQuery);
            fetchedStudents.push(
              ...studentsSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
            );
          }
          setStudents(fetchedStudents);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les données.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
    setIsLoading(false);
  }, [auth, toast]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearch = (e) => {
    const queryText = e.target.value.toLowerCase();
    setSearchQuery(queryText);
    filterCompanies(queryText, statusFilter);
  };

  const handleStatusFilter = (e) => {
    const status = e.target.value;
    setStatusFilter(status);
    filterCompanies(searchQuery, status);
  };

  const filterCompanies = (queryText, status) => {
    let filtered = companies;
    if (status !== 'all') {
      filtered = filtered.filter((company) => company.status === status);
    }
    if (queryText) {
      filtered = filtered.filter((company) =>
        company.name.toLowerCase().includes(queryText)
      );
    }
    setFilteredCompanies(filtered);
  };

  const formatDateSafe = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = timestamp.toDate
      ? timestamp.toDate()
      : new Date(timestamp);
    return `${date.getDate().toString().padStart(2, '0')}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;
  };

  const getInternshipStatus = (internship) => {
    const now = new Date();
    const startDate = internship.startDate.toDate
      ? internship.startDate.toDate()
      : new Date(internship.startDate);
    const endDate = internship.endDate.toDate
      ? internship.endDate.toDate()
      : new Date(internship.endDate);

    if (now >= startDate && now <= endDate) {
      return { status: 'En cours', color: 'brand.green' };
    } else if (now < startDate) {
      return { status: 'À venir', color: 'brand.yellow' };
    } else if (now > endDate) {
      return { status: 'Terminé', color: 'brand.red' };
    } else {
      return { status: 'Inconnu', color: 'gray.500' };
    }
  };

  // Fonctions pour créer une entreprise
  const openCreateModal = () => {
    setNewCompanyData({
      name: '',
      address: '',
      status: 'active',
      latitude: '',
      longitude: '',
    });
    setIsCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleCreateFormChange = (e) => {
    const { name, value } = e.target;
    setNewCompanyData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCreateCompany = async () => {
    if (
      !newCompanyData.name ||
      !newCompanyData.address ||
      newCompanyData.latitude === '' ||
      newCompanyData.longitude === ''
    ) {
      toast({
        title: 'Erreur',
        description: 'Veuillez remplir tous les champs obligatoires.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsProcessing(true);

    try {
      const user = auth.currentUser;
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const centreId = userDoc.exists() ? userDoc.data().centreId : null;

      await addDoc(collection(db, 'companies'), {
        name: newCompanyData.name,
        address: newCompanyData.address,
        status: newCompanyData.status,
        latitude: parseFloat(newCompanyData.latitude),
        longitude: parseFloat(newCompanyData.longitude),
        centreId: centreId,
        createdAt: new Date(),
      });

      toast({
        title: 'Succès',
        description: 'Entreprise ajoutée avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      fetchData();
      closeCreateModal();
    } catch (error) {
      console.error("Erreur lors de la création de l'entreprise :", error);
      toast({
        title: 'Erreur',
        description: "Impossible de créer l'entreprise.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    setIsProcessing(false);
  };

  // Fonctions pour éditer une entreprise
  const openEditModal = (company) => {
    setEditCompanyData(company);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditCompanyData(null);
    setIsEditModalOpen(false);
  };

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditCompanyData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditCompany = async () => {
    if (
      !editCompanyData.name ||
      !editCompanyData.address ||
      editCompanyData.latitude === '' ||
      editCompanyData.longitude === ''
    ) {
      toast({
        title: 'Erreur',
        description: 'Veuillez remplir tous les champs obligatoires.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsProcessing(true);

    try {
      const companyRef = doc(db, 'companies', editCompanyData.id);
      await updateDoc(companyRef, {
        name: editCompanyData.name,
        address: editCompanyData.address,
        status: editCompanyData.status,
        latitude: parseFloat(editCompanyData.latitude),
        longitude: parseFloat(editCompanyData.longitude),
      });

      toast({
        title: 'Succès',
        description: "Entreprise mise à jour avec succès.",
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      fetchData();
      closeEditModal();
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'entreprise :", error);
      toast({
        title: 'Erreur',
        description: "Impossible de mettre à jour l'entreprise.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    setIsProcessing(false);
  };

  // Fonctions pour supprimer une entreprise
  const openDeleteModal = (company) => {
    setCompanyToDelete(company);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setCompanyToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const handleDeleteCompany = async () => {
    if (!companyToDelete) return;

    setIsProcessing(true);

    try {
      await deleteDoc(doc(db, 'companies', companyToDelete.id));

      toast({
        title: 'Succès',
        description: "Entreprise supprimée avec succès.",
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      fetchData();
      closeDeleteModal();
    } catch (error) {
      console.error("Erreur lors de la suppression de l'entreprise :", error);
      toast({
        title: 'Erreur',
        description: "Impossible de supprimer l'entreprise.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }

    setIsProcessing(false);
  };

  return (
    <Flex direction="column" p={[4, 6]} bg={bg} minHeight="100vh">
      <Heading as="h2" size="lg" mb={6} color={headerColor}>
        Gestion des Entreprises
      </Heading>

      <Flex
        mb={6}
        align="center"
        wrap="wrap"
        justify="space-between"
        bg={headerBg}
        p={4}
        borderRadius="md"
      >
        <HStack spacing={2} mb={[4, 0]}>
          <Input
            placeholder="Rechercher une entreprise..."
            value={searchQuery}
            onChange={handleSearch}
            maxW="300px"
            variant="filled"
            bg="brand.white"
            color="brand.blue"
          />
          <IconButton
            icon={<SearchIcon />}
            aria-label="Rechercher"
            onClick={handleSearch}
            bg="brand.blue"
            color="brand.white"
            _hover={{ bg: 'brand.orange' }}
          />
          <Select
            placeholder="Filtrer par statut"
            value={statusFilter}
            onChange={handleStatusFilter}
            maxW="200px"
            variant="filled"
            bg="brand.white"
            color="brand.blue"
          >
            <option value="all">Toutes</option>
            <option value="active">Actives</option>
            <option value="inactive">Inactives</option>
            <option value="pending">En attente</option>
          </Select>
        </HStack>
        <Button
          leftIcon={<AddIcon />}
          onClick={openCreateModal}
          mb={[2, 0]}
          variant="solid" // Utilisation du variant personnalisé
        >
          Ajouter une entreprise
        </Button>
      </Flex>

      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" height="50vh">
          <Spinner size="xl" color="brand.blue" />
        </Flex>
      ) : (
        <>
          <Box mb={8} height="400px" borderRadius="md" overflow="hidden" boxShadow="md">
            <MapContainer
              center={[48.8566, 2.3522]}
              zoom={6}
              style={{ height: '100%', width: '100%' }}
            >
              <TileLayer
                attribution='&copy; OpenStreetMap contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {filteredCompanies.map((company) => {
                if (company.latitude && company.longitude) {
                  const companyInternships = internships.filter(
                    (internship) => internship.companyId === company.id
                  );

                  const internshipDetails = companyInternships.map((internship) => {
                    const student = students.find(
                      (s) => s.id === internship.studentId
                    );
                    const { status, color } = getInternshipStatus(internship);
                    return (
                      <Box key={internship.id} mt={4} textAlign="left">
                        {student && (
                          <>
                            <HStack>
                              <Avatar
                                size="sm"
                                name={`${student.firstName} ${student.lastName}`}
                                src={student.photoURL || ''}
                              />
                              <Text fontWeight="bold">
                                {student.firstName} {student.lastName}
                              </Text>
                            </HStack>
                            <Text mt={1}>
                              <strong>Statut : </strong>
                              <Text as="span" color={color}>
                                {status}
                              </Text>
                            </Text>
                            <Text>
                              <strong>Date de début : </strong>
                              {formatDateSafe(internship.startDate)}
                            </Text>
                            <Text>
                              <strong>Date de fin : </strong>
                              {formatDateSafe(internship.endDate)}
                            </Text>
                          </>
                        )}
                      </Box>
                    );
                  });

                  return (
                    <Marker
                      key={company.id}
                      position={[company.latitude, company.longitude]}
                    >
                      <Popup>
                        <Box textAlign="center">
                          <Heading as="h4" size="md" color="brand.blue">
                            {company.name}
                          </Heading>
                          <Text color="brand.blue">{company.address}</Text>
                          {internshipDetails.length > 0 ? (
                            <>
                              <Divider my={2} />
                              <Text fontWeight="bold" color="brand.blue">Stages associés :</Text>
                              {internshipDetails}
                            </>
                          ) : (
                            <Text mt={2} color="gray.500">
                              Aucun stage en cours
                            </Text>
                          )}
                        </Box>
                      </Popup>
                    </Marker>
                  );
                } else {
                  return null;
                }
              })}
            </MapContainer>
          </Box>

          <Box
            bg={cardBg}
            borderRadius="md"
            p={6}
            boxShadow="md"
            overflowX="auto"
          >
            <Table variant="simple" colorScheme="brand">
              <Thead bg={headerBg}>
                <Tr>
                  <Th color="brand.blue">Nom</Th>
                  <Th color="brand.blue">Adresse</Th>
                  <Th color="brand.blue">Statut</Th>
                  <Th color="brand.blue">Stages</Th>
                  <Th color="brand.blue">Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredCompanies.map((company) => {
                  const companyInternships = internships.filter(
                    (internship) => internship.companyId === company.id
                  );
                  return (
                    <Tr key={company.id}>
                      <Td fontWeight="bold" color="brand.blue">{company.name}</Td>
                      <Td color="brand.blue">{company.address}</Td>
                      <Td>
                        <Badge
                          colorScheme={
                            company.status === 'active'
                              ? 'green'
                              : company.status === 'inactive'
                              ? 'red'
                              : 'yellow'
                          }
                        >
                          {company.status.charAt(0).toUpperCase() +
                            company.status.slice(1)}
                        </Badge>
                      </Td>
                      <Td>
                        {companyInternships.length > 0 ? (
                          <VStack align="start" spacing={2}>
                            {companyInternships.map((internship) => {
                              const student = students.find(
                                (s) => s.id === internship.studentId
                              );
                              const { status, color } = getInternshipStatus(internship);
                              return (
                                <Box key={internship.id} p={2} bg="gray.50" borderRadius="md" w="100%">
                                  {student && (
                                    <>
                                      <HStack spacing={2}>
                                        <Avatar
                                          size="sm"
                                          name={`${student.firstName} ${student.lastName}`}
                                          src={student.photoURL || ''}
                                        />
                                        <Text fontWeight="bold" color="brand.blue">
                                          {student.firstName} {student.lastName}
                                        </Text>
                                      </HStack>
                                      <Text fontSize="sm" color="brand.blue">
                                        {formatDateSafe(internship.startDate)} -{' '}
                                        {formatDateSafe(internship.endDate)}
                                      </Text>
                                      <Badge colorScheme={
                                        status === 'En cours'
                                          ? 'green'
                                          : status === 'À venir'
                                          ? 'yellow'
                                          : 'red'
                                      }>
                                        {status}
                                      </Badge>
                                    </>
                                  )}
                                </Box>
                              );
                            })}
                          </VStack>
                        ) : (
                          <Text color="gray.500">Aucun stage</Text>
                        )}
                      </Td>
                      <Td>
                        <HStack spacing={2}>
                          <IconButton
                            icon={<EditIcon />}
                            onClick={() => openEditModal(company)}
                            aria-label="Modifier l'entreprise"
                            variant="solid"
                            bg="brand.orange"
                            color="brand.white"
                            size="sm"
                            _hover={{ bg: 'brand.blue' }}
                          />
                          <IconButton
                            icon={<DeleteIcon />}
                            onClick={() => openDeleteModal(company)}
                            aria-label="Supprimer l'entreprise"
                            variant="solid"
                            bg="brand.red"
                            color="brand.white"
                            size="sm"
                            _hover={{ bg: 'brand.orange' }}
                          />
                        </HStack>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        </>
      )}

      {/* Modal pour créer une entreprise */}
      <Modal isOpen={isCreateModalOpen} onClose={closeCreateModal} isCentered>
        <ModalOverlay />
        <ModalContent bg="brand.white" color="brand.blue">
          <ModalHeader>Ajouter une Entreprise</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <FormControl id="name" isRequired>
                <FormLabel>Nom de l'entreprise</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={newCompanyData.name}
                  onChange={handleCreateFormChange}
                  placeholder="Nom de l'entreprise"
                  bg="brand.white"
                  color="brand.blue"
                />
              </FormControl>
              <FormControl id="address" isRequired>
                <FormLabel>Adresse</FormLabel>
                <Input
                  type="text"
                  name="address"
                  value={newCompanyData.address}
                  onChange={handleCreateFormChange}
                  placeholder="Adresse de l'entreprise"
                  bg="brand.white"
                  color="brand.blue"
                />
              </FormControl>
              <FormControl id="status" isRequired>
                <FormLabel>Statut</FormLabel>
                <Select
                  name="status"
                  value={newCompanyData.status}
                  onChange={handleCreateFormChange}
                  bg="brand.white"
                  color="brand.blue"
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="pending">En attente</option>
                </Select>
              </FormControl>
              <FormControl id="latitude" isRequired>
                <FormLabel>Latitude</FormLabel>
                <Input
                  type="number"
                  name="latitude"
                  value={newCompanyData.latitude}
                  onChange={handleCreateFormChange}
                  placeholder="Latitude de l'entreprise"
                  bg="brand.white"
                  color="brand.blue"
                />
              </FormControl>
              <FormControl id="longitude" isRequired>
                <FormLabel>Longitude</FormLabel>
                <Input
                  type="number"
                  name="longitude"
                  value={newCompanyData.longitude}
                  onChange={handleCreateFormChange}
                  placeholder="Longitude de l'entreprise"
                  bg="brand.white"
                  color="brand.blue"
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="solid" // Utilisation du variant personnalisé
              onClick={handleCreateCompany}
              isLoading={isProcessing}
              bg="brand.orange"
              color="brand.white"
              mr={3}
              _hover={{ bg: 'brand.blue' }}
            >
              Ajouter
            </Button>
            <Button
              variant="ghost"
              onClick={closeCreateModal}
              color="brand.orange"
            >
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal pour éditer une entreprise */}
      <Modal isOpen={isEditModalOpen} onClose={closeEditModal} isCentered>
        <ModalOverlay />
        <ModalContent bg="brand.white" color="brand.blue">
          <ModalHeader>Modifier l'Entreprise</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <FormControl id="name" isRequired>
                <FormLabel>Nom de l'entreprise</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={editCompanyData?.name || ''}
                  onChange={handleEditFormChange}
                  placeholder="Nom de l'entreprise"
                  bg="brand.white"
                  color="brand.blue"
                />
              </FormControl>
              <FormControl id="address" isRequired>
                <FormLabel>Adresse</FormLabel>
                <Input
                  type="text"
                  name="address"
                  value={editCompanyData?.address || ''}
                  onChange={handleEditFormChange}
                  placeholder="Adresse de l'entreprise"
                  bg="brand.white"
                  color="brand.blue"
                />
              </FormControl>
              <FormControl id="status" isRequired>
                <FormLabel>Statut</FormLabel>
                <Select
                  name="status"
                  value={editCompanyData?.status || 'active'}
                  onChange={handleEditFormChange}
                  bg="brand.white"
                  color="brand.blue"
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="pending">En attente</option>
                </Select>
              </FormControl>
              <FormControl id="latitude" isRequired>
                <FormLabel>Latitude</FormLabel>
                <Input
                  type="number"
                  name="latitude"
                  value={editCompanyData?.latitude || ''}
                  onChange={handleEditFormChange}
                  placeholder="Latitude de l'entreprise"
                  bg="brand.white"
                  color="brand.blue"
                />
              </FormControl>
              <FormControl id="longitude" isRequired>
                <FormLabel>Longitude</FormLabel>
                <Input
                  type="number"
                  name="longitude"
                  value={editCompanyData?.longitude || ''}
                  onChange={handleEditFormChange}
                  placeholder="Longitude de l'entreprise"
                  bg="brand.white"
                  color="brand.blue"
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="solid" // Utilisation du variant personnalisé
              onClick={handleEditCompany}
              isLoading={isProcessing}
              bg="brand.orange"
              color="brand.white"
              mr={3}
              _hover={{ bg: 'brand.blue' }}
            >
              Enregistrer
            </Button>
            <Button
              variant="ghost"
              onClick={closeEditModal}
              color="brand.orange"
            >
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal de confirmation de suppression */}
      <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal} isCentered>
        <ModalOverlay />
        <ModalContent bg="brand.white" color="brand.blue">
          <ModalHeader>Supprimer l'Entreprise</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Êtes-vous sûr de vouloir supprimer l'entreprise{' '}
              <strong>{companyToDelete?.name}</strong>? Cette action est
              irréversible.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="solid" // Utilisation du variant personnalisé
              onClick={handleDeleteCompany}
              isLoading={isProcessing}
              bg="brand.red"
              color="brand.white"
              mr={3}
              _hover={{ bg: 'brand.orange' }}
            >
              Supprimer
            </Button>
            <Button
              variant="ghost"
              onClick={closeDeleteModal}
              color="brand.orange"
            >
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Companies;
