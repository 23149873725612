// src/centre/ManageCourses.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Select,
  useToast,
  IconButton,
  Tooltip,
  Spinner,
  Input,
  FormControl,
  FormLabel,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  getDocs,
  collection,
  addDoc,
  deleteDoc,
  doc,
  writeBatch,
  updateDoc,
  Timestamp,
} from 'firebase/firestore';
import { db } from '../firebase';
import { FaPlus, FaTrash, FaDownload, FaSun, FaMoon, FaEdit } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import { isValid, format } from 'date-fns';

// Composant Modal Réutilisable
const CustomModal = ({ isOpen, onClose, title, children, footer }) => (
  <Modal isOpen={isOpen} onClose={onClose} size="xl">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>{title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>{children}</ModalBody>
      <ModalFooter>{footer}</ModalFooter>
    </ModalContent>
  </Modal>
);

const ManageCourses = () => {
  const [data, setData] = useState({
    classes: [],
    groups: [],
    teachers: [],
    subjects: [],
    courses: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const tableBg = useColorModeValue('white', 'gray.700');
  const formBg = useColorModeValue('white', 'gray.700');

  // États pour les modaux
  const [modals, setModals] = useState({
    course: false,
    editCourse: false,
    subject: false,
    teacher: false,
  });

  // États pour les formulaires
  const [form, setForm] = useState({
    newCourse: {
      courseName: '',
      classId: '',
      groupId: '',
      teacherId: '',
      subjectId: '',
      startDateTime: '',
      endDateTime: '',
    },
    editCourse: null,
    newSubject: '',
    newTeacher: '',
  });

  // Fonction pour formater les dates
  const formatDateForInput = (date) => {
    let validDate = date instanceof Timestamp
      ? date.toDate()
      : new Date(date);
    return isValid(validDate) ? format(validDate, "yyyy-MM-dd'T'HH:mm") : '';
  };

  // Fonction utilitaire pour récupérer une collection
  const fetchCollection = async (name) => {
    const snapshot = await getDocs(collection(db, name));
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  };

  // Récupérer toutes les données nécessaires
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [classes, groups, teachers, subjects, courses] = await Promise.all([
          fetchCollection('classes'),
          fetchCollection('groups'),
          fetchCollection('teachers'),
          fetchCollection('subjects'),
          getDocs(collection(db, 'courses')),
        ]);

        const processedCourses = courses.docs.map(doc => {
          const data = doc.data();
          const start = data.startDateTime instanceof Timestamp ? data.startDateTime.toDate() : new Date(data.startDateTime);
          const end = data.endDateTime instanceof Timestamp ? data.endDateTime.toDate() : new Date(data.endDateTime);
          return isValid(start) && isValid(end)
            ? { id: doc.id, ...data, startDateTime: start, endDateTime: end }
            : null;
        }).filter(course => course !== null);

        setData({ classes, groups, teachers, subjects, courses: processedCourses });
      } catch (error) {
        console.error('Erreur lors de la récupération des données : ', error);
        toast({
          title: 'Erreur',
          description: 'Impossible de récupérer les données.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [toast]);

  // Fonction générique pour télécharger un modèle Excel
  const downloadTemplate = (headers, filename, sheetName) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headers]);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, filename);
  };

  const templates = [
    {
      headers: ['Nom du cours', 'Classe', 'Groupe', 'Enseignant', 'Matière', 'Date et heure de début', 'Date et heure de fin'],
      filename: 'Template_Cours.xlsx',
      sheetName: 'Cours',
    },
    {
      headers: ['Nom de l\'Enseignant'],
      filename: 'Template_Enseignants.xlsx',
      sheetName: 'Enseignants',
    },
    {
      headers: ['Nom de la Matière'],
      filename: 'Template_Matières.xlsx',
      sheetName: 'Matières',
    },
  ];

  // Fonction générique pour importer des données depuis un fichier Excel
  const handleFileUpload = (type) => async (event) => {
    const file = event.target.files[0];
    if (!file) {
      toast({
        title: 'Erreur',
        description: 'Aucun fichier sélectionné.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const workbook = XLSX.read(e.target.result, { type: 'binary' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const dataRows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        if (type === 'courses') await importCourses(dataRows);
        else if (type === 'teachers') await importEntities(dataRows, 'teachers', 'Enseignant');
        else if (type === 'subjects') await importEntities(dataRows, 'subjects', 'Matière');
      } catch (error) {
        console.error('Erreur lors de l\'importation : ', error);
        toast({
          title: 'Erreur',
          description: 'Impossible d\'importer le fichier Excel.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        event.target.value = null;
      }
    };
    reader.readAsBinaryString(file);
  };

  // Fonction pour importer les cours
  const importCourses = async (rows) => {
    const headers = [
      'Nom du cours',
      'Classe',
      'Groupe',
      'Enseignant',
      'Matière',
      'Date et heure de début',
      'Date et heure de fin',
    ];
    if (!rows.slice(0, headers.length).every((header, idx) => header === headers[idx])) {
      toast({
        title: 'Erreur de format',
        description: 'Le fichier Excel ne correspond pas au format requis pour les cours.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const newCourses = [];
    for (let i = 1; i < rows.length; i++) {
      const [
        courseName,
        className,
        groupName,
        teacherName,
        subjectName,
        startDateTime,
        endDateTime,
      ] = rows[i];

      if (!courseName || !className || !teacherName || !subjectName || !startDateTime || !endDateTime) {
        toast({
          title: 'Erreur de données',
          description: `Ligne ${i + 1} : Certaines données sont manquantes.`,
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
        continue;
      }

      const classObj = data.classes.find(cls => cls.className === className);
      const teacherObj = data.teachers.find(tch => tch.name === teacherName);
      const subjectObj = data.subjects.find(subj => subj.name === subjectName);
      let groupId = null;

      if (groupName) {
        const groupObj = data.groups.find(grp => grp.groupName === groupName && grp.classId === classObj?.id);
        if (!groupObj) {
          toast({
            title: 'Erreur de groupe',
            description: `Ligne ${i + 1} : Groupe "${groupName}" non trouvé pour la classe "${className}".`,
            status: 'warning',
            duration: 5000,
            isClosable: true,
          });
          continue;
        }
        groupId = groupObj.id;
      }

      if (!classObj || !teacherObj || !subjectObj) {
        toast({
          title: 'Erreur de référence',
          description: `Ligne ${i + 1} : Références invalides.`,
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
        continue;
      }

      const start = new Date(startDateTime);
      const end = new Date(endDateTime);
      if (!isValid(start) || !isValid(end) || end <= start) {
        toast({
          title: 'Erreur de date',
          description: `Ligne ${i + 1} : Format de date invalide ou fin avant début.`,
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
        continue;
      }

      newCourses.push({
        courseName,
        classId: classObj.id,
        groupId,
        teacherId: teacherObj.id,
        subjectId: subjectObj.id,
        startDateTime: start,
        endDateTime: end,
      });
    }

    if (newCourses.length === 0) {
      toast({
        title: 'Aucune donnée à importer',
        description: 'Aucune ligne valide trouvée dans le fichier Excel.',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      const batch = writeBatch(db);
      newCourses.forEach(course => {
        const courseRef = doc(collection(db, 'courses'));
        batch.set(courseRef, course);
      });
      await batch.commit();
      toast({
        title: 'Succès',
        description: `${newCourses.length} cours importés avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      // Rafraîchir les cours
      const updatedCourses = await getDocs(collection(db, 'courses'));
      const processedCourses = updatedCourses.docs.map(doc => {
        const data = doc.data();
        const start = data.startDateTime instanceof Timestamp ? data.startDateTime.toDate() : new Date(data.startDateTime);
        const end = data.endDateTime instanceof Timestamp ? data.endDateTime.toDate() : new Date(data.endDateTime);
        return isValid(start) && isValid(end) ? { id: doc.id, ...data, startDateTime: start, endDateTime: end } : null;
      }).filter(course => course !== null);
      setData(prev => ({ ...prev, courses: processedCourses }));
    } catch (error) {
      console.error('Erreur lors de l\'importation des cours : ', error);
      toast({
        title: 'Erreur',
        description: 'Impossible d\'importer les cours.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction générique pour importer enseignants et matières
  const importEntities = async (rows, collectionName, entityName) => {
    const headers = [`Nom de ${entityName}`];
    if (!rows.slice(0, headers.length).every((header, idx) => header === headers[idx])) {
      toast({
        title: 'Erreur de format',
        description: `Le fichier Excel ne correspond pas au format requis pour les ${collectionName}.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const newEntities = rows.slice(1).filter(row => row[0]).map(row => ({ name: row[0] }));

    if (newEntities.length === 0) {
      toast({
        title: 'Aucune donnée à importer',
        description: `Aucune ligne valide trouvée pour les ${collectionName}.`,
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      const batch = writeBatch(db);
      newEntities.forEach(entity => {
        const entityRef = doc(collection(db, collectionName));
        batch.set(entityRef, entity);
      });
      await batch.commit();
      toast({
        title: 'Succès',
        description: `${newEntities.length} ${entityName}s importés avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      // Rafraîchir les données
      const updatedEntities = await fetchCollection(collectionName);
      setData(prev => ({ ...prev, [collectionName]: updatedEntities }));
    } catch (error) {
      console.error(`Erreur lors de l'importation des ${collectionName} : `, error);
      toast({
        title: 'Erreur',
        description: `Impossible d\'importer les ${collectionName}.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction pour gérer les changements de formulaire
  const handleInputChange = (section, field) => (e) => {
    const value = e.target.value;
    setForm(prev => ({
      ...prev,
      [section]: { ...prev[section], [field]: value },
    }));
  };

  // Fonction pour ajouter un cours manuellement
  const addManualCourse = async () => {
    const { courseName, classId, groupId, teacherId, subjectId, startDateTime, endDateTime } = form.newCourse;
    if (!courseName || !classId || !teacherId || !subjectId || !startDateTime || !endDateTime) {
      toast({
        title: 'Erreur de validation',
        description: 'Veuillez remplir tous les champs du formulaire.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const start = new Date(startDateTime);
    const end = new Date(endDateTime);
    if (!isValid(start) || !isValid(end) || end <= start) {
      toast({
        title: 'Erreur de date',
        description: 'Les dates doivent être valides et la fin après le début.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      const courseRef = await addDoc(collection(db, 'courses'), {
        courseName,
        classId,
        groupId: groupId || null,
        teacherId,
        subjectId,
        startDateTime: start,
        endDateTime: end,
      });
      setData(prev => ({
        ...prev,
        courses: [...prev.courses, { id: courseRef.id, ...form.newCourse, startDateTime: start, endDateTime: end }],
      }));
      toast({
        title: 'Succès',
        description: 'Cours ajouté avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setForm(prev => ({
        ...prev,
        newCourse: {
          courseName: '',
          classId: '',
          groupId: '',
          teacherId: '',
          subjectId: '',
          startDateTime: '',
          endDateTime: '',
        },
      }));
      setModals(prev => ({ ...prev, course: false }));
    } catch (error) {
      console.error('Erreur lors de l\'ajout du cours : ', error);
      toast({
        title: 'Erreur',
        description: 'Impossible d\'ajouter le cours.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction pour mettre à jour un cours
  const updateCourse = async () => {
    const { id, courseName, classId, groupId, teacherId, subjectId, startDateTime, endDateTime } = form.editCourse;
    if (!courseName || !classId || !teacherId || !subjectId || !startDateTime || !endDateTime) {
      toast({
        title: 'Erreur de validation',
        description: 'Veuillez remplir tous les champs du formulaire.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const start = new Date(startDateTime);
    const end = new Date(endDateTime);
    if (!isValid(start) || !isValid(end) || end <= start) {
      toast({
        title: 'Erreur de date',
        description: 'Les dates doivent être valides et la fin après le début.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      const courseRef = doc(db, 'courses', id);
      await updateDoc(courseRef, {
        courseName,
        classId,
        groupId: groupId || null,
        teacherId,
        subjectId,
        startDateTime: start,
        endDateTime: end,
      });
      setData(prev => ({
        ...prev,
        courses: prev.courses.map(course =>
          course.id === id ? { ...form.editCourse, startDateTime: start, endDateTime: end } : course
        ),
      }));
      toast({
        title: 'Succès',
        description: 'Cours mis à jour avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setModals(prev => ({ ...prev, editCourse: false }));
      setForm(prev => ({ ...prev, editCourse: null }));
    } catch (error) {
      console.error('Erreur lors de la mise à jour du cours : ', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de mettre à jour le cours.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction pour supprimer un cours
  const deleteCourse = async (courseId) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce cours ?')) return;
    setIsLoading(true);
    try {
      await deleteDoc(doc(db, 'courses', courseId));
      setData(prev => ({
        ...prev,
        courses: prev.courses.filter(course => course.id !== courseId),
      }));
      toast({
        title: 'Succès',
        description: 'Cours supprimé avec succès.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression du cours : ', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer le cours.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction pour gérer les changements dans le formulaire d'édition
  const handleEditChange = (field) => (e) => {
    const value = e.target.value;
    setForm(prev => ({
      ...prev,
      editCourse: { ...prev.editCourse, [field]: value },
    }));
  };

  // Fonctions pour ajouter une matière ou un enseignant
  const addEntity = async (type) => {
    const name = type === 'subject' ? form.newSubject : form.newTeacher;
    if (!name.trim()) {
      toast({
        title: 'Erreur de validation',
        description: `Le nom de la ${type} ne peut pas être vide.`,
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      await addDoc(collection(db, type === 'subject' ? 'subjects' : 'teachers'), { name });
      toast({
        title: 'Succès',
        description: `${type === 'subject' ? 'Matière' : 'Enseignant'} ajouté avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      // Rafraîchir les données
      const updated = await fetchCollection(type === 'subject' ? 'subjects' : 'teachers');
      setData(prev => ({ ...prev, [type === 'subject' ? 'subjects' : 'teachers']: updated }));
      // Réinitialiser le formulaire
      setForm(prev => ({ ...prev, [type === 'subject' ? 'newSubject' : 'newTeacher']: '' }));
      setModals(prev => ({ ...prev, [type === 'subject' ? 'subject' : 'teacher']: false }));
    } catch (error) {
      console.error(`Erreur lors de l'ajout de la ${type} : `, error);
      toast({
        title: 'Erreur',
        description: `Impossible d\'ajouter la ${type}.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex direction="column" p={8} bg={bgColor} minHeight="100vh">
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Heading color="teal.600" textAlign="center">
          Gestion des Cours, Matières et Enseignants
        </Heading>
        <IconButton
          aria-label="Toggle Dark Mode"
          icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
          onClick={toggleColorMode}
        />
      </Flex>

      <Tabs variant="soft-rounded" colorScheme="teal" isFitted>
        <TabList mb="1em">
          <Tab>Cours</Tab>
          <Tab>Matières</Tab>
          <Tab>Enseignants</Tab>
        </TabList>
        <TabPanels>
          {/* Onglet Cours */}
          <TabPanel>
            <Flex mb={6} justifyContent="space-between" flexWrap="wrap" gap={4}>
              {templates.map(template => (
                <Button
                  key={template.filename}
                  leftIcon={<FaDownload />}
                  colorScheme="blue"
                  onClick={() => downloadTemplate(template.headers, template.filename, template.sheetName)}
                >
                  Télécharger le Modèle Excel {template.sheetName}
                </Button>
              ))}
              <Input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload('courses')}
                hidden
                id="file-upload-courses"
              />
              <label htmlFor="file-upload-courses">
                <Button as="span" leftIcon={<FaPlus />} colorScheme="green">
                  Importer des Cours
                </Button>
              </label>
              <Button leftIcon={<FaPlus />} colorScheme="teal" onClick={() => setModals(prev => ({ ...prev, course: true }))}>
                Ajouter un Cours
              </Button>
            </Flex>

            {isLoading ? (
              <Flex justifyContent="center" alignItems="center" height="200px">
                <Spinner size="xl" color="teal.500" />
              </Flex>
            ) : (
              <TableContainer bg={tableBg} borderRadius="md" boxShadow="md">
                <Table variant="striped" colorScheme="teal">
                  <Thead>
                    <Tr>
                      <Th>Nom du Cours</Th>
                      <Th>Classe</Th>
                      <Th>Groupe</Th>
                      <Th>Enseignant</Th>
                      <Th>Matière</Th>
                      <Th>Début</Th>
                      <Th>Fin</Th>
                      <Th>Durée</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.courses.map(course => {
                      const classObj = data.classes.find(cls => cls.id === course.classId);
                      const groupObj = data.groups.find(grp => grp.id === course.groupId);
                      const teacherObj = data.teachers.find(tch => tch.id === course.teacherId);
                      const subjectObj = data.subjects.find(subj => subj.id === course.subjectId);
                      const durationMs = course.endDateTime - course.startDateTime;
                      const duration = `${Math.floor(durationMs / (1000 * 60 * 60))}h ${Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60))}m`;

                      return (
                        <Tr key={course.id}>
                          <Td>{course.courseName}</Td>
                          <Td>{classObj?.className || 'Non défini'}</Td>
                          <Td>{groupObj?.groupName || 'Toute la classe'}</Td>
                          <Td>{teacherObj?.name || 'Non défini'}</Td>
                          <Td>{subjectObj?.name || 'Non défini'}</Td>
                          <Td>{course.startDateTime.toLocaleString('fr-FR')}</Td>
                          <Td>{course.endDateTime.toLocaleString('fr-FR')}</Td>
                          <Td>{duration}</Td>
                          <Td>
                            <Tooltip label="Modifier le cours">
                              <IconButton
                                aria-label="Modifier le cours"
                                icon={<FaEdit />}
                                colorScheme="blue"
                                mr={2}
                                onClick={() => setForm(prev => ({ ...prev, editCourse: course }))}
                              />
                            </Tooltip>
                            <Tooltip label="Supprimer le cours">
                              <IconButton
                                aria-label="Supprimer le cours"
                                icon={<FaTrash />}
                                colorScheme="red"
                                onClick={() => deleteCourse(course.id)}
                              />
                            </Tooltip>
                          </Td>
                        </Tr>
                      );
                    })}
                    {data.courses.length === 0 && (
                      <Tr>
                        <Td colSpan="9" textAlign="center">
                          Aucune donnée disponible. Importez des cours ou ajoutez-les manuellement.
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </TabPanel>

          {/* Onglet Matières */}
          <TabPanel>
            <Flex mb={6} justifyContent="flex-end">
              <Button
                leftIcon={<FaPlus />}
                colorScheme="teal"
                onClick={() => setModals(prev => ({ ...prev, subject: true }))}
              >
                Ajouter une Matière
              </Button>
            </Flex>

            <Box p={4} bg={tableBg} borderRadius="md" boxShadow="md">
              <Heading size="md" mb={4} color="teal.500">
                Liste des Matières
              </Heading>
              {isLoading ? (
                <Flex justifyContent="center" alignItems="center" height="100px">
                  <Spinner size="lg" color="teal.500" />
                </Flex>
              ) : (
                <TableContainer>
                  <Table variant="striped" colorScheme="teal">
                    <Thead>
                      <Tr>
                        <Th>Nom de la Matière</Th>
                        <Th>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data.subjects.map(subj => (
                        <Tr key={subj.id}>
                          <Td>{subj.name}</Td>
                          <Td>
                            <IconButton
                              aria-label="Supprimer la matière"
                              icon={<FaTrash />}
                              colorScheme="red"
                              size="sm"
                              onClick={async () => {
                                if (!window.confirm('Êtes-vous sûr de vouloir supprimer cette matière ?')) return;
                                try {
                                  await deleteDoc(doc(db, 'subjects', subj.id));
                                  setData(prev => ({ ...prev, subjects: prev.subjects.filter(s => s.id !== subj.id) }));
                                  toast({
                                    title: 'Succès',
                                    description: 'Matière supprimée avec succès.',
                                    status: 'success',
                                    duration: 3000,
                                    isClosable: true,
                                  });
                                } catch (error) {
                                  console.error('Erreur lors de la suppression de la matière : ', error);
                                  toast({
                                    title: 'Erreur',
                                    description: 'Impossible de supprimer la matière.',
                                    status: 'error',
                                    duration: 5000,
                                    isClosable: true,
                                  });
                                }
                              }}
                            />
                          </Td>
                        </Tr>
                      ))}
                      {data.subjects.length === 0 && (
                        <Tr>
                          <Td colSpan="2" textAlign="center">
                            Aucune matière disponible.
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </TabPanel>

          {/* Onglet Enseignants */}
          <TabPanel>
            <Flex mb={6} justifyContent="flex-end">
              <Button
                leftIcon={<FaPlus />}
                colorScheme="teal"
                onClick={() => setModals(prev => ({ ...prev, teacher: true }))}
              >
                Ajouter un Enseignant
              </Button>
            </Flex>

            <Box p={4} bg={tableBg} borderRadius="md" boxShadow="md">
              <Heading size="md" mb={4} color="teal.500">
                Liste des Enseignants
              </Heading>
              {isLoading ? (
                <Flex justifyContent="center" alignItems="center" height="100px">
                  <Spinner size="lg" color="teal.500" />
                </Flex>
              ) : (
                <TableContainer>
                  <Table variant="striped" colorScheme="teal">
                    <Thead>
                      <Tr>
                        <Th>Nom de l'Enseignant</Th>
                        <Th>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data.teachers.map(tch => (
                        <Tr key={tch.id}>
                          <Td>{tch.name}</Td>
                          <Td>
                            <IconButton
                              aria-label="Supprimer l'enseignant"
                              icon={<FaTrash />}
                              colorScheme="red"
                              size="sm"
                              onClick={async () => {
                                if (!window.confirm('Êtes-vous sûr de vouloir supprimer cet enseignant ?')) return;
                                try {
                                  await deleteDoc(doc(db, 'teachers', tch.id));
                                  setData(prev => ({ ...prev, teachers: prev.teachers.filter(t => t.id !== tch.id) }));
                                  toast({
                                    title: 'Succès',
                                    description: 'Enseignant supprimé avec succès.',
                                    status: 'success',
                                    duration: 3000,
                                    isClosable: true,
                                  });
                                } catch (error) {
                                  console.error('Erreur lors de la suppression de l\'enseignant : ', error);
                                  toast({
                                    title: 'Erreur',
                                    description: 'Impossible de supprimer l\'enseignant.',
                                    status: 'error',
                                    duration: 5000,
                                    isClosable: true,
                                  });
                                }
                              }}
                            />
                          </Td>
                        </Tr>
                      ))}
                      {data.teachers.length === 0 && (
                        <Tr>
                          <Td colSpan="2" textAlign="center">
                            Aucun enseignant disponible.
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* Modals */}

      {/* Modal Ajouter un Cours */}
      <CustomModal
        isOpen={modals.course}
        onClose={() => setModals(prev => ({ ...prev, course: false }))}
        title="Ajouter un Cours"
        footer={
          <>
            <Button
              leftIcon={<FaPlus />}
              colorScheme="teal"
              mr={3}
              onClick={addManualCourse}
              isLoading={isLoading}
            >
              Ajouter le Cours
            </Button>
            <Button variant="ghost" onClick={() => setModals(prev => ({ ...prev, course: false }))}>
              Annuler
            </Button>
          </>
        }
      >
        <Stack spacing={4}>
          {['courseName', 'classId', 'groupId', 'teacherId', 'subjectId', 'startDateTime', 'endDateTime'].map(field => (
            <FormControl key={field} isRequired={['groupId'].indexOf(field) === -1}>
              <FormLabel>
                {field === 'courseName' && 'Nom du Cours'}
                {field === 'classId' && 'Classe'}
                {field === 'groupId' && 'Groupe'}
                {field === 'teacherId' && 'Enseignant'}
                {field === 'subjectId' && 'Matière'}
                {field === 'startDateTime' && 'Date et Heure de Début'}
                {field === 'endDateTime' && 'Date et Heure de Fin'}
              </FormLabel>
              {['classId', 'groupId', 'teacherId', 'subjectId'].includes(field) ? (
                <Select
                  name={field}
                  value={form.newCourse[field]}
                  onChange={handleInputChange('newCourse', field)}
                  placeholder={
                    field === 'groupId'
                      ? 'Sélectionnez un groupe (optionnel)'
                      : `Sélectionnez ${field === 'classId' ? 'une classe' : field === 'teacherId' ? 'un enseignant' : 'une matière'}`
                  }
                  disabled={field === 'groupId' && !form.newCourse.classId}
                >
                  {field === 'classId' && data.classes.map(cls => (
                    <option key={cls.id} value={cls.id}>{cls.className}</option>
                  ))}
                  {field === 'groupId' && data.groups
                    .filter(grp => grp.classId === form.newCourse.classId)
                    .map(grp => (
                      <option key={grp.id} value={grp.id}>{grp.groupName}</option>
                    ))
                  }
                  {field === 'teacherId' && data.teachers.map(tch => (
                    <option key={tch.id} value={tch.id}>{tch.name}</option>
                  ))}
                  {field === 'subjectId' && data.subjects.map(subj => (
                    <option key={subj.id} value={subj.id}>{subj.name}</option>
                  ))}
                </Select>
              ) : (
                <Input
                  type={field.includes('Date') ? 'datetime-local' : 'text'}
                  name={field}
                  value={form.newCourse[field]}
                  onChange={handleInputChange('newCourse', field)}
                  placeholder={field === 'courseName' ? 'Ex : Mathématiques Avancées' : undefined}
                />
              )}
            </FormControl>
          ))}
        </Stack>
      </CustomModal>

      {/* Modal Modifier un Cours */}
      {form.editCourse && (
        <CustomModal
          isOpen={modals.editCourse}
          onClose={() => setModals(prev => ({ ...prev, editCourse: false }))}
          title="Modifier le Cours"
          footer={
            <>
              <Button
                leftIcon={<FaEdit />}
                colorScheme="blue"
                mr={3}
                onClick={updateCourse}
                isLoading={isLoading}
              >
                Mettre à Jour
              </Button>
              <Button variant="ghost" onClick={() => setModals(prev => ({ ...prev, editCourse: false }))}>
                Annuler
              </Button>
            </>
          }
        >
          <Stack spacing={4}>
            {['courseName', 'classId', 'groupId', 'teacherId', 'subjectId', 'startDateTime', 'endDateTime'].map(field => (
              <FormControl key={field} isRequired={['groupId'].indexOf(field) === -1}>
                <FormLabel>
                  {field === 'courseName' && 'Nom du Cours'}
                  {field === 'classId' && 'Classe'}
                  {field === 'groupId' && 'Groupe'}
                  {field === 'teacherId' && 'Enseignant'}
                  {field === 'subjectId' && 'Matière'}
                  {field === 'startDateTime' && 'Date et Heure de Début'}
                  {field === 'endDateTime' && 'Date et Heure de Fin'}
                </FormLabel>
                {['classId', 'groupId', 'teacherId', 'subjectId'].includes(field) ? (
                  <Select
                    name={field}
                    value={form.editCourse[field]}
                    onChange={handleEditChange(field)}
                    placeholder={
                      field === 'groupId'
                        ? 'Sélectionnez un groupe (optionnel)'
                        : `Sélectionnez ${field === 'classId' ? 'une classe' : field === 'teacherId' ? 'un enseignant' : 'une matière'}`
                    }
                    disabled={field === 'groupId' && !form.editCourse.classId}
                  >
                    {field === 'classId' && data.classes.map(cls => (
                      <option key={cls.id} value={cls.id}>{cls.className}</option>
                    ))}
                    {field === 'groupId' && data.groups
                      .filter(grp => grp.classId === form.editCourse.classId)
                      .map(grp => (
                        <option key={grp.id} value={grp.id}>{grp.groupName}</option>
                      ))
                    }
                    {field === 'teacherId' && data.teachers.map(tch => (
                      <option key={tch.id} value={tch.id}>{tch.name}</option>
                    ))}
                    {field === 'subjectId' && data.subjects.map(subj => (
                      <option key={subj.id} value={subj.id}>{subj.name}</option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    type={field.includes('Date') ? 'datetime-local' : 'text'}
                    name={field}
                    value={field.includes('Date') ? formatDateForInput(form.editCourse[field]) : form.editCourse[field]}
                    onChange={handleEditChange(field)}
                    placeholder={field === 'courseName' ? 'Ex : Mathématiques Avancées' : undefined}
                  />
                )}
              </FormControl>
            ))}
          </Stack>
        </CustomModal>
      )}

      {/* Modal Ajouter une Matière */}
      <CustomModal
        isOpen={modals.subject}
        onClose={() => setModals(prev => ({ ...prev, subject: false }))}
        title="Ajouter une Matière"
        footer={
          <>
            <Button
              leftIcon={<FaPlus />}
              colorScheme="teal"
              mr={3}
              onClick={() => addEntity('subject')}
              isLoading={isLoading}
            >
              Ajouter
            </Button>
            <Button variant="ghost" onClick={() => setModals(prev => ({ ...prev, subject: false }))}>
              Annuler
            </Button>
          </>
        }
      >
        <FormControl id="newSubject" isRequired>
          <FormLabel>Nom de la Matière</FormLabel>
          <Input
            type="text"
            value={form.newSubject}
            onChange={(e) => setForm(prev => ({ ...prev, newSubject: e.target.value }))}
            placeholder="Ex : Physique"
          />
        </FormControl>
      </CustomModal>

      {/* Modal Ajouter un Enseignant */}
      <CustomModal
        isOpen={modals.teacher}
        onClose={() => setModals(prev => ({ ...prev, teacher: false }))}
        title="Ajouter un Enseignant"
        footer={
          <>
            <Button
              leftIcon={<FaPlus />}
              colorScheme="teal"
              mr={3}
              onClick={() => addEntity('teacher')}
              isLoading={isLoading}
            >
              Ajouter
            </Button>
            <Button variant="ghost" onClick={() => setModals(prev => ({ ...prev, teacher: false }))}>
              Annuler
            </Button>
          </>
        }
      >
        <FormControl id="newTeacher" isRequired>
          <FormLabel>Nom de l'Enseignant</FormLabel>
          <Input
            type="text"
            value={form.newTeacher}
            onChange={(e) => setForm(prev => ({ ...prev, newTeacher: e.target.value }))}
            placeholder="Ex : M. Dupont"
          />
        </FormControl>
      </CustomModal>
    </Flex>
  );
};

export default ManageCourses;
