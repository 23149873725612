// src/centre/InternOrder.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Grid,
  Image,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { db } from '../firebase';

const InternOrder = () => {
  const { centreId } = useParams(); // Récupérer le centreId depuis l'URL
  const [products, setProducts] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [inputEmail, setInputEmail] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthorized) {
      fetchProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);

  // Fonction pour récupérer les produits du centre
  const fetchProducts = async () => {
    try {
      const productsQuery = query(
        collection(db, 'products'),
        where('centreId', '==', centreId) // Filtrer par centreId
      );
      const querySnapshot = await getDocs(productsQuery);
      const productsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsList);
    } catch (error) {
      console.error('Erreur lors de la récupération des produits :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de charger les produits pour ce centre.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fonction pour vérifier si l'e-mail est autorisé
  const handleAuthorize = async () => {
    if (!inputEmail) {
      toast({
        title: 'Erreur',
        description: 'Veuillez entrer une adresse e-mail.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const centresQuery = query(
        collection(db, 'centres'),
        where('id', '==', centreId),
        where('authorizedEmails', 'array-contains', inputEmail)
      );
      const querySnapshot = await getDocs(centresQuery);
      if (!querySnapshot.empty) {
        setIsAuthorized(true);
        toast({
          title: 'Autorisé',
          description: 'Votre e-mail est autorisé à accéder au catalogue.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Non Autorisé',
          description: 'Votre e-mail n\'est pas autorisé à accéder au catalogue.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Erreur lors de la vérification de l\'e-mail :', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de vérifier l\'e-mail.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={8} bg="gray.50" minHeight="100vh">
      {!isAuthorized ? (
        <Box maxW="md" mx="auto" p={6} bg="white" borderRadius="lg" shadow="md">
          <Heading mb={6} textAlign="center">Accéder au Catalogue</Heading>
          <FormControl id="email" mb={4}>
            <FormLabel>Adresse E-mail</FormLabel>
            <Input
              type="email"
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
              placeholder="email@example.com"
            />
          </FormControl>
          <Button colorScheme="blue" width="full" onClick={handleAuthorize}>
            Accéder
          </Button>
        </Box>
      ) : (
        <Box>
          <Heading mb={6} textAlign="center">Catalogue des Produits</Heading>
          {products.length === 0 ? (
            <Text>Aucun produit disponible pour ce centre.</Text>
          ) : (
            <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={6}>
              {products.map((product) => (
                <Box
                  key={product.id}
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  p={4}
                  bg="white"
                  shadow="md"
                >
                  <Image src={product.photo} alt={product.name} boxSize="200px" objectFit="cover" mx="auto" />

                  <Box mt={4}>
                    <Heading size="md" mb={2}>
                      {product.name}
                    </Heading>
                    <Text mb={2}>{product.details}</Text>
                    {/* Si tu as un champ 'price', tu peux l'afficher ici */}
                    {/* <Text fontWeight="bold">Prix: {product.price} €</Text> */}
                  </Box>

                  <Button
                    colorScheme="blue"
                    mt={4}
                    onClick={() => navigate(`/order/${product.id}`)} // Assurez-vous de créer cette route
                    width="full"
                  >
                    Commander
                  </Button>
                </Box>
              ))}
            </Grid>
          )}
        </Box>
      )}
    </Box>
  );
};

export default InternOrder;
