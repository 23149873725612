import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Heading,
  Input,
  Flex,
  useToast,
  SimpleGrid,
  Badge,
  Text,
  VStack,
  HStack,
  Icon,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Avatar,
  useColorModeValue,
  Divider,
  Tooltip,
} from '@chakra-ui/react';
import { FiSearch, FiLayers, FiMapPin, FiClock } from 'react-icons/fi';
import { EditIcon, DownloadIcon } from '@chakra-ui/icons';
import { collection, getDocs, deleteDoc, doc, addDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Import direct de 'db' depuis firebase.js

const CatalogCentre = () => {
  const toast = useToast();
  const [formations, setFormations] = useState([]);
  const [centres, setCentres] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFormation, setSelectedFormation] = useState(null); // État pour la formation sélectionnée

  // Couleurs basées sur le mode (moved all hook calls to top)
  const bg = useColorModeValue('gray.50', 'gray.800');
  const tableBg = useColorModeValue('white', 'gray.700');
  const inputBg = useColorModeValue('white', 'gray.600'); // New top-level hook

  // Fonction pour récupérer les formations depuis Firestore
  const fetchFormations = useCallback(async () => {
    try {
      const formationsCol = collection(db, 'formations');
      const formationsSnapshot = await getDocs(formationsCol);
      const formationsList = formationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setFormations(formationsList);
    } catch (error) {
      toast({
        title: 'Erreur',
        description: "Impossible de récupérer les formations.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  // Fonction pour récupérer les centres depuis Firestore
  const fetchCentres = useCallback(async () => {
    try {
      const centresCol = collection(db, 'centres');
      const centresSnapshot = await getDocs(centresCol);
      const centresList = centresSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCentres(centresList);
    } catch (error) {
      toast({
        title: 'Erreur',
        description: "Impossible de récupérer les centres.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchFormations();
    fetchCentres();
  }, [fetchFormations, fetchCentres]);

  // Fonction pour obtenir le nom du centre à partir de son ID
  const getCentreName = (centreId) => {
    const centre = centres.find(c => c.id === centreId);
    return centre ? centre.centreName : 'Centre Inconnu';
  };

  // Fonction pour filtrer les formations en fonction du terme de recherche
  const filteredFormations = formations.filter(formation =>
    formation.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    formation.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    getCentreName(formation.centreId).toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Fonction pour afficher les détails d'une formation
  const viewFormationDetails = (formation) => {
    setSelectedFormation(formation);
  };

  // Fonction pour revenir à la liste des formations
  const goBackToList = () => {
    setSelectedFormation(null);
  };

  return (
    <Box p={6} maxW="1200px" mx="auto" bg={bg} minHeight="100vh">
      {selectedFormation ? (
        // Vue détaillée de la formation avec onglets
        <Box bg={tableBg} borderRadius="lg" shadow="md" p={6}>
          <VStack align="start" spacing={4} width="100%">
            <Heading as="h3" size="lg" color="teal.600">
              {selectedFormation.title}
            </Heading>

            {/* Badges pour le centre */}
            <Badge colorScheme="blue" variant="solid" borderRadius="full" px={3}>
              {getCentreName(selectedFormation.centreId)}
            </Badge>

            <Divider />

            {/* Onglets */}
            <Tabs variant="soft-rounded" colorScheme="teal" width="100%">
              <TabList>
                <Tab>Général</Tab>
                <Tab>Pédagogie</Tab>
                <Tab>Financement</Tab>
              </TabList>

              <TabPanels>
                {/* Onglet Général */}
                <TabPanel>
                  <VStack align="start" spacing={3}>
                    <Text fontSize="md" color="gray.600">
                      {selectedFormation.description}
                    </Text>

                    <HStack>
                      <Icon as={FiMapPin} w={5} h={5} color="blue.400" />
                      <Text fontSize="md" color="gray.500">
                        {getCentreName(selectedFormation.centreId)}
                      </Text>
                    </HStack>

                    <HStack>
                      <Icon as={FiClock} w={5} h={5} color="orange.400" />
                      <Text fontSize="md" color="gray.500">
                        {selectedFormation.volumeAnnuel} heures annuelles
                      </Text>
                    </HStack>

                    <Text fontSize="md" color="gray.500">
                      <strong>Code RNCP :</strong> {selectedFormation.codeRNCP || 'Non défini'}
                    </Text>
                    <Text fontSize="md" color="gray.500">
                      <strong>Secteur d'activité :</strong> {selectedFormation.secteurActivite || 'Non défini'}
                    </Text>
                  </VStack>
                </TabPanel>

                {/* Onglet Pédagogie */}
                <TabPanel>
                  <VStack align="start" spacing={3}>
                    <Text fontSize="md" color="gray.500">
                      <strong>Prérequis :</strong> {selectedFormation.prerequis || 'Aucun'}
                    </Text>
                    <Text fontSize="md" color="gray.500">
                      <strong>Voie d'accès à la certification :</strong> {selectedFormation.voieAccesCertification || 'Non défini'}
                    </Text>

                    {/* Liste des Compétences/UC */}
                    <VStack align="start" spacing={2} mt={4}>
                      <Text fontSize="md" color="gray.500">
                        <strong>Compétences/UC :</strong>
                      </Text>
                      {selectedFormation.competences && selectedFormation.competences.length > 0 ? (
                        selectedFormation.competences.map((competence, index) => (
                          <Badge key={index} colorScheme="teal" variant="solid" px={3} py={1}>
                            {competence}
                          </Badge>
                        ))
                      ) : (
                        <Text color="gray.500">Aucune compétence définie.</Text>
                      )}
                    </VStack>
                  </VStack>
                </TabPanel>

                {/* Onglet Financement */}
                <TabPanel>
                  <VStack align="start" spacing={3}>
                    <Text fontSize="md" color="gray.500">
                      <strong>Options de Financement :</strong>
                    </Text>
                    <SimpleGrid columns={[1, 2]} spacing={4}>
                      {selectedFormation.organismes && selectedFormation.organismes.length > 0 ? (
                        selectedFormation.organismes.map((organisme, index) => (
                          <Badge key={index} colorScheme="green" variant="subtle" borderRadius="full" px={3} py={1}>
                            <HStack spacing={2}>
                              {organisme.logo && (
                                <Avatar
                                  size="xs"
                                  name={organisme.name}
                                  src={organisme.logo}
                                />
                              )}
                              <Text fontSize="sm">{organisme.name}</Text>
                            </HStack>
                          </Badge>
                        ))
                      ) : (
                        <Text color="gray.500">Aucun organisme de financement disponible.</Text>
                      )}
                    </SimpleGrid>
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <Divider />

            {/* Bouton pour revenir à la liste des formations */}
            <Button colorScheme="teal" onClick={goBackToList} leftIcon={<EditIcon />}>
              Retour aux formations
            </Button>
          </VStack>
        </Box>
      ) : (
        // Vue des cartes des formations
        <>
          <Heading as="h2" size="xl" mb={8} textAlign="center" color="teal.600">
            Catalogue des Formations
          </Heading>

          {/* Barre de recherche améliorée */}
          <Flex mb={6} align="center" justify="center">
            <Flex bg="white" borderRadius="full" shadow="md" p={4} align="center" width="100%" maxW="600px">
              <Icon as={FiSearch} color="teal.500" w={6} h={6} mr={2} />
              <Input
                variant="unstyled"
                placeholder="Rechercher une formation par titre, description ou centre"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                _placeholder={{ color: 'gray.500' }}
                bg={inputBg} // Use the top-level variable here
              />
            </Flex>
          </Flex>

          {/* Grid des formations sous forme de cartes */}
          <SimpleGrid columns={[1, 2, 3]} spacing={8}>
            {filteredFormations.length > 0 ? (
              filteredFormations.map((formation) => (
                <Box
                  key={formation.id}
                  bg={tableBg}
                  borderRadius="lg"
                  shadow="md"
                  p={6}
                  transition="transform 0.2s ease-in-out"
                  _hover={{ transform: 'scale(1.05)', cursor: 'pointer' }}
                  onClick={() => viewFormationDetails(formation)} // Cliquer pour afficher les détails
                >
                  <VStack align="start" spacing={4}>
                    {/* Titre de la formation */}
                    <HStack>
                      <Icon as={FiLayers} w={6} h={6} color="teal.500" />
                      <Heading as="h3" size="md" color="gray.700">
                        {formation.title}
                      </Heading>
                    </HStack>

                    {/* Description */}
                    <Text color="gray.600" noOfLines={2}>
                      {formation.description}
                    </Text>

                    {/* Informations sur le centre */}
                    <HStack>
                      <Icon as={FiMapPin} w={5} h={5} color="blue.400" />
                      <Text color="gray.500">{getCentreName(formation.centreId)}</Text>
                    </HStack>

                    {/* Volume annuel */}
                    <HStack>
                      <Icon as={FiClock} w={5} h={5} color="orange.400" />
                      <Text color="gray.500">{formation.volumeAnnuel} heures annuelles</Text>
                    </HStack>

                    {/* Badges pour les organismes */}
                    {formation.organismes && formation.organismes.length > 0 && (
                      <HStack spacing={2}>
                        {formation.organismes.map((organisme, index) => (
                          <Badge key={index} colorScheme="green" variant="solid" borderRadius="full" px={3} py={1}>
                            <HStack spacing={2}>
                              {organisme.logo && (
                                <Avatar
                                  size="xs"
                                  name={organisme.name}
                                  src={organisme.logo}
                                />
                              )}
                              <Text fontSize="sm">{organisme.name}</Text>
                            </HStack>
                          </Badge>
                        ))}
                      </HStack>
                    )}
                  </VStack>
                </Box>
              ))
            ) : (
              <Text color="gray.500" fontSize="lg" textAlign="center">
                Aucune formation trouvée.
              </Text>
            )}
          </SimpleGrid>
        </>
      )}
    </Box>
  );
};

export default CatalogCentre;
